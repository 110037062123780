import { addDays } from 'date-fns';
import {
  SET_QUERY,
  SET_COMPLETE_SEARCH_QUERY,
  RESET,
  SET_QUERY_FILTERS_PRICE_RANGE,
} from './../constants/hotelSearchQuery';
import { Action, HotelSearchQueryReducer } from './../types';

export const HOTEL_PROPERTY_RATINGS_FILTERS = [
  {
    label: '1 Star',
    value: '1',
  },
  {
    label: '2 Star',
    value: '2',
  },
  {
    label: '3 Star',
    value: '3',
  },
  {
    label: '4 Star',
    value: '4',
  },
  {
    label: '5 Star',
    value: '5',
  },
];

export const HOTEL_BOOKING_POLICY_FILTERS = [
  {
    value: 'refundable',
    label: 'Refundable',
  },
  {
    value: 'non-refundable',
    label: 'Non-Refundable',
  },
];

const initialState: HotelSearchQueryReducer = {
  checkin: addDays(new Date(), 2),
  checkout: addDays(new Date(), 3),
  rooms_occupancy: [
    {
      adults: 1,
    },
  ],
  destination: null,
  filters: {
    priceRange: [0, 0],
    propertyRating: HOTEL_PROPERTY_RATINGS_FILTERS,
    bookingPolicy: HOTEL_BOOKING_POLICY_FILTERS,
  },
};
const hotelSearchQueryReducer = (
  state = initialState,
  action: Action
): HotelSearchQueryReducer => {
  switch (action.type) {
    case RESET:
      return initialState;
    case SET_QUERY:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case SET_QUERY_FILTERS_PRICE_RANGE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          priceRange: action.payload,
        },
      };
    }
    case SET_COMPLETE_SEARCH_QUERY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default hotelSearchQueryReducer;
