import React from 'react';
import { USER_ROLES } from '../../../constants';

const Umrah = React.lazy(() => import('../index'));

export const ToursUrls = {
  Detail: tourId => `/umrah/${tourId}`,
  FDGUmrahList: () => '/fdg/umrah',
  FDGUmrahDetail: (tourId: string) => `/fdg/umrah/${tourId}`,
  FDGFlightsList: () => '/fdg/flights',
  FDGFlightsDetail: (tourId: string) => `/fdg/flights/${tourId}`,
};

export const ToursPaths = {
  Index: '/umrah/',
  Detail: '/umrah/:tour_id',
  FDGToursList: '/fdg/:tour_type',
  FDGTourDetail: '/fdg/:tour_type/:tour_id',
};

export const ToursHeading = {
  [ToursPaths.Index]: 'Umrah / Ziarat',
  [ToursPaths.Detail]: 'Umrah / Ziarat',
  [ToursUrls.FDGUmrahList()]: 'Umrah / Ziarat',
  [ToursUrls.FDGFlightsList()]: 'Group Flights',
};

const ToursRoutes = [
  {
    path: ToursPaths.Index,
    component: Umrah,
    private: true,
    exact: true,
    role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
  },
  {
    path: ToursPaths.Detail,
    component: Umrah,
    private: true,
    exact: true,
    role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
  },
  {
    path: ToursUrls.FDGUmrahList(),
    component: Umrah,
    private: true,
    exact: true,
    role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
  },
  {
    path: ToursUrls.FDGFlightsList(),
    component: Umrah,
    private: true,
    exact: true,
    role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
  },
  {
    path: ToursPaths.FDGTourDetail,
    component: Umrah,
    private: true,
    exact: true,
    role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
  },
];

export default ToursRoutes;
