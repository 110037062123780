import {
  CREATE_PAYMENT,
  CREATE_PAYMENT_FAILED,
  CREATE_PAYMENT_SUCCESS,
  FETCH_PAYMENT,
  FETCH_PAYMENTS,
  FETCH_PAYMENTS_FAILED,
  FETCH_PAYMENTS_SUCCESS,
  FETCH_PAYMENT_FAILED,
  FETCH_PAYMENT_SUCCESS,
  RESET_PAYMENT,
} from '../constants/payments';
import { Action, PaymentsReducer } from '../types';

const initialState: PaymentsReducer = {
  payments: {
    payments: [],
    pagination_meta: {
      page_no: 0,
      page_size: 0,
      total_pages: 0,
      has_next: false,
      total_records: 0,
    },
  },
  payment: null,
  isFetchingPayments: false,
  isCreatingPayment: false,
  isFetchingPayment: false,
  isApproving: false,
};

const paymentsReducer = (
  state = initialState,
  action: Action
): PaymentsReducer => {
  switch (action.type) {
    case FETCH_PAYMENTS:
      return { ...state, isFetchingPayments: true };

    case FETCH_PAYMENTS_SUCCESS: {
      return {
        ...state,
        payments: action.payload,
        isFetchingPayments: false,
      };
    }

    case FETCH_PAYMENTS_FAILED: {
      return {
        ...state,
        isFetchingPayments: false,
      };
    }

    case CREATE_PAYMENT: {
      return {
        ...state,
        isCreatingPayment: true,
      };
    }

    case CREATE_PAYMENT_SUCCESS: {
      return {
        ...state,
        isCreatingPayment: false,
      };
    }

    case CREATE_PAYMENT_FAILED: {
      return {
        ...state,
        isCreatingPayment: false,
      };
    }

    case FETCH_PAYMENT: {
      return {
        ...state,
        isFetchingPayment: true,
      };
    }

    case FETCH_PAYMENT_SUCCESS: {
      const tempPaymentsList = [...state.payments.payments];
      const { payment } = action.payload;
      const paymentIndexFromPaymentsList = tempPaymentsList
        .map(item => item.payment_id)
        .indexOf(payment.payment_id);
      tempPaymentsList[paymentIndexFromPaymentsList] = {
        ...tempPaymentsList[paymentIndexFromPaymentsList],
        ...payment,
      };
      return {
        ...state,
        isFetchingPayment: false,
        payments: {
          pagination_meta: state.payments.pagination_meta,
          payments: tempPaymentsList,
        },
        payment: action.payload.payment,
      };
    }

    case FETCH_PAYMENT_FAILED: {
      return {
        ...state,
        isFetchingPayment: false,
      };
    }

    case RESET_PAYMENT: {
      return { ...state, payment: null };
    }

    default:
      return state;
  }
};

export default paymentsReducer;
