export enum FareCalculatorAmountType {
  Fixed,
  Percentage,
}
export const FareCalculatorFareHeading = {
  [FareCalculatorAmountType.Fixed]: 'Enter Fix Value of Amount',
  [FareCalculatorAmountType.Percentage]: 'Enter Percentage of Amount',
};
export const FareCalculatorFareUnit = {
  [FareCalculatorAmountType.Fixed]: 'PKR',
  [FareCalculatorAmountType.Percentage]: '%',
};
