import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import changeState from 'src/store';
import agentReducer from './reducers/agent';
import airlineReducer from './reducers/airline';
import sectorReducer from './reducers/sector';
import salesReducer from './reducers/sales';
import appsettingsReducer from './reducers/appsettings';
import otpReducer from './reducers/otp';
import paymentsReducer from './reducers/payments';
import organizationReducer from './reducers/organizations';
import hotelsReducer from './reducers/hotels';
import { reducer as toastrReducer } from 'react-redux-toastr';

import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import airportReducer from './reducers/airport';
import flightSearchQueryReducer from './reducers/flightSearchQueryReducer';
import flightReducer from './reducers/flight';
import bookingReducer from './reducers/booking';
import hotelSearchQueryReducer from './reducers/hotelSearchQuery';
import toursReducer from './reducers/tours';
import dashboardDataReducer from './reducers/dashboardData';
import fitReducer from './reducers/fitReducer';
import agentBalanceReducer from './reducers/agentBalance';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const rootReducer = combineReducers({
  changeState: changeState,
  agent: agentReducer,
  agentBalance: agentBalanceReducer,
  airline: airlineReducer,
  sectors: sectorReducer,
  sales: salesReducer,
  settings: appsettingsReducer,
  otp: otpReducer,
  payments: paymentsReducer,
  organizations: organizationReducer,
  airports: airportReducer,
  flightSearchQuery: flightSearchQueryReducer,
  flights: flightReducer,
  bookings: bookingReducer,
  hotels: hotelsReducer,
  toastr: toastrReducer,
  hotelSearchQuery: hotelSearchQueryReducer,
  tours: toursReducer,
  dashboardData: dashboardDataReducer,
  fit: fitReducer,
});

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);
export default store;
