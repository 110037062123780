import {
  RESET,
  FETCH_PROPERTIES,
  FETCH_PROPERTIES_FULFILLED,
  FETCH_PROPERTY_DETAILS,
  FETCH_PROPERTY_DETAILS_FULLFILLED,
  FETCH_PROPERTIES_FAILED,
  FETCH_PROPERTY_DETAILS_FAILED,
  RESET_FETCHED_PROPERTIES,
  UPDATE_HOTEL_FILTERS,
} from '../constants/hotels';
import { HotelsReducerProperty } from '../types';
import { PropertyDetail } from 'src/pages/HotelNew/types';
import { HotelFilters } from 'src/pages/HotelNew/utils/enums/HotelFilters';

export const resetHotels = () => {
  return {
    type: RESET,
  };
};

export const resetHotelsResult = () => {
  return {
    type: RESET_FETCHED_PROPERTIES,
  };
};

export const fetchProperties = payload => {
  return {
    type: FETCH_PROPERTIES,
    payload,
  };
};

export const fetchPropertiesFailed = payload => {
  return {
    type: FETCH_PROPERTIES_FAILED,
    payload,
  };
};

export const fetchPropertyDetails = (payload: any) => {
  return {
    type: FETCH_PROPERTY_DETAILS,
    payload,
  };
};

export const fetchPropertyDetailsFailed = () => {
  return {
    type: FETCH_PROPERTY_DETAILS_FAILED,
  };
};

export const fetchPropertyDetailsFullfilled = (roomDetails: PropertyDetail) => {
  return {
    type: FETCH_PROPERTY_DETAILS_FULLFILLED,
    payload: roomDetails,
  };
};

export const fetchPropertiesFullfilled = (
  propertyResponse: HotelsReducerProperty,
  loadMoreProperties: boolean,
  propertyFound: boolean
) => {
  return {
    type: FETCH_PROPERTIES_FULFILLED,
    payload: {
      propertyResponse: propertyResponse,
      loadMoreProperties,
      propertyFound,
    },
  };
};

export const updateHotelFilters = (payload: {
  key: HotelFilters;
  value: Array<number> | Array<string>;
}) => {
  return {
    type: UPDATE_HOTEL_FILTERS,
    payload,
  };
};
