import { HTTP_METHODS } from 'src/enums/http-methods';
import apiService from './api';

export const getAgentsService = () => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: '/agentuser/list',
  });
};

export const getNewsService = () => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: '/agentuser/news',
  });
};

export const getFeaturedNewsService = () => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: '/agentuser/news/featured',
  });
};

export const getAgentDetailsByCustomerCodeService = (customerCode: number) => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: `/agentuser/agent-details?customer_code=${customerCode}`,
  });
};

export const getAgentPricingDetailsByCustomerCodeService = (
  customerCode: number
) => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: `agentuser/pricing-list?customer_code=${customerCode}`,
  });
};

export const getCustomerBalanceService = async (id: string) => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: `/v2/financial-profile/${id}/?financial_data=show`,
  });
};
