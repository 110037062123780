import {
  FETCH_DASHBOARD_DATA,
  FETCH_DASHBOARD_DATA_FAILED,
  FETCH_DASHBOARD_DATA_SUCCESS,
  FETCH_BOOKING_EXPIRY,
  FETCH_BOOKING_EXPIRY_FAILED,
  FETCH_BOOKING_EXPIRY_SUCCESS,
  RESET,
} from '../constants/dashboardData';

export const resetDashboardData = () => {
  return {
    type: RESET,
  };
};
export const fetchDashboardData = payload => ({
  type: FETCH_DASHBOARD_DATA,
  payload,
});

export const fetchDashboardDataFullfilled = payload => ({
  type: FETCH_DASHBOARD_DATA_SUCCESS,
  payload,
});

export const fetchDashboardDataFailed = () => ({
  type: FETCH_DASHBOARD_DATA_FAILED,
});

export const fetchBookingExpiry = () => ({
  type: FETCH_BOOKING_EXPIRY,
});

export const fetchBookingExpiryFullfilled = payload => ({
  type: FETCH_BOOKING_EXPIRY_SUCCESS,
  payload,
});

export const fetchBookingExpiryFailed = () => ({
  type: FETCH_BOOKING_EXPIRY_FAILED,
});
