import {
  FETCH_AIRPORTS,
  FETCH_AIRPORTS_FAILED,
  FETCH_AIRPORTS_SUCCESS,
  RESET,
} from './../constants/airport';

export const resetAirports = () => {
  return {
    type: RESET,
  };
};

export const fetchAirports = (payload: string) => ({
  type: FETCH_AIRPORTS,
  payload,
});

export const fetchAirportsFullfilled = payload => ({
  type: FETCH_AIRPORTS_SUCCESS,
  payload,
});

export const fetchAirportsFailed = () => ({
  type: FETCH_AIRPORTS_FAILED,
});
