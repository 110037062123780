import { call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_TOUR, FETCH_TOURS } from '../constants/tours';
import {
  fetchTourFailure,
  fetchToursFailed,
  fetchToursFullfilled,
  fetchTourSuccess,
} from '../actions/tours';
import {
  getToursService,
  getTourByIdService,
} from 'src/services/toursServices';
import { Action } from '../types';

function* fetchTours(action: Action) {
  try {
    const response = yield call(getToursService, action.payload.tourType);
    if (response.ok && response.data?.success) {
      yield put(fetchToursFullfilled(response.data.data));
    } else {
      yield put(fetchToursFailed());
    }
  } catch (err: any) {
    yield put(fetchToursFailed());
  }
}

function* fetchTour(action: Action) {
  try {
    const response = yield call(getTourByIdService, action.payload);
    if (response.ok && response.data?.success) {
      yield put(fetchTourSuccess(response.data.data));
    } else {
      yield put(fetchTourFailure());
    }
  } catch (err) {
    yield put(fetchTourFailure());
  }
}

export function* fetchToursWatcher() {
  yield takeLatest(FETCH_TOURS, fetchTours);
}

export function* fetchTourWatcher() {
  yield takeLatest(FETCH_TOUR, fetchTour);
}
