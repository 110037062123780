import { ANALYTICS_PREFIX } from 'src/constants/analytics';

export const TOURS_PAGE = 'tours';

export const TOURS_ANALAYTICS = {
  PAGE_TOURS_LIST: ANALYTICS_PREFIX + 'tours_list',
  ACTION_TOURS_ITEM_BOOK_NOW: ANALYTICS_PREFIX + 'tours_list_item_book_now',
  PAGE_TOUR_DETAILS: ANALYTICS_PREFIX + 'tour_details',
  ACTION_TOUR_DETAILS_BOOK_TOUR: ANALYTICS_PREFIX + 'tour_details_book_tour',
  PAGE_TOUR_BOOKING_FORM: ANALYTICS_PREFIX + 'tour_booking_form',
  ACTION_TOUR_BOOKING_CONFIRM_INITIALIZED:
    ANALYTICS_PREFIX + 'tour_booking_confirm_initialized',
  ACTION_TOUR_BOOKING_CONFIRM_SUCCESS:
    ANALYTICS_PREFIX + 'tour_booking_confirm_success',
  ACTION_TOUR_BOOKING_CONFIRM_ERROR:
    ANALYTICS_PREFIX + 'tour_booking_confirm_error',
  ACTION_DUMMY_TOUR_BOOKING_CONFIRM_INITIALIZED:
    ANALYTICS_PREFIX + 'dummy_tour_booking_confirm_initialized',
  ACTION_DUMMY_TOUR_BOOKING_CONFIRM_SUCCESS:
    ANALYTICS_PREFIX + 'dummy_tour_booking_confirm_success',
  ACTION_DUMMY_TOUR_BOOKING_CONFIRM_ERROR:
    ANALYTICS_PREFIX + 'dummy_tour_booking_confirm_error',
};
