import {
  FlightSearchQueryFilters,
  FlightSearchQueryReducer,
  MultiCityLegType,
} from './../types';
import { Airport, TravelerCount } from 'src/types';
import {
  SET_QUERY,
  SET_COMPLETE_SEARCH_QUERY,
  RESET_FLIGHT_SEARCH_QUERY,
  UPDATE_FLIGHT_SEARCH_QUERY_FILTERS,
  RESET_MULTICITY_SEARCH_QUERY,
} from './../constants/flightSearchQuery';
import { FLIGHT_FILTERS_KEYS } from '../../enums/flight_filters_keys';

export const setQuery = (
  key: string,
  value:
    | string
    | Date
    | boolean
    | null
    | TravelerCount
    | Airport
    | FlightSearchQueryFilters
    | MultiCityLegType[]
) => {
  return {
    type: SET_QUERY,
    payload: {
      key,
      value,
    },
  };
};

export const setCompleteSearchQuery = (payload: FlightSearchQueryReducer) => {
  return {
    type: SET_COMPLETE_SEARCH_QUERY,
    payload,
  };
};

export const resetFlightSearchQuery = () => {
  return {
    type: RESET_FLIGHT_SEARCH_QUERY,
  };
};

export const updateFlightSearchQueryFilters = (payload: {
  key: FLIGHT_FILTERS_KEYS;
  value: Array<string> | Array<number>;
}) => {
  return {
    type: UPDATE_FLIGHT_SEARCH_QUERY_FILTERS,
    payload,
  };
};

export const resetMulticitySearchQuery = () => {
  return {
    type: RESET_MULTICITY_SEARCH_QUERY,
  };
};
