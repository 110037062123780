import { Action } from '../types';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FIT_GET_SEARCH_RESULT,
  FIT_SUBMIT_REQUEST,
} from '../constants/fitConstant';
import {
  getFitSearchQuerySuccessful,
  getFitSearchQueryFail,
  submitFitRequestFail,
  submitFitRequestSuccessful,
} from '../actions/fitAction';
import { getFitHotelsList, submitFitRequest } from '../../services/fitServices';
import { FITHotel } from '../../pages/FIT/utils/models';
import { logActionEvent } from '../../services/analytics';

function* fitGetSearchResult(action: Action) {
  try {
    logActionEvent('fit_search_initiated', action.payload);
    const { data: response } = yield call(getFitHotelsList, action.payload);
    if (response.success) {
      logActionEvent('fit_search_success', response.data);
      const hotels: Array<FITHotel> = response.data.hotels_detail.map(hotel => {
        return {
          hotelId: hotel.hotel_id,
          hotelName: hotel.hotel_name,
          hotelAddress: hotel.address,
          hotelDistance: hotel.hotel_meta.distance,
          hotelCurrency: hotel.hotel_currency,
          rooms: hotel.rooms_detail.map(room => {
            return {
              roomId: room.room_id,
              type: room.room_name,
              rate: room.total_price,

              totalNights: room.total_nights,
              perBedRate: room.room_rate.per_night_per_bed,
              perRoomRate: room.room_rate.per_night_per_room,
              roomsCount: room.room_rate.room_count,
              totalRatesOfTotalRooms: room.room_rate.final_price,
            };
          }),
        };
      });
      yield put(getFitSearchQuerySuccessful(hotels));
    } else {
      logActionEvent('fit_search_fail', response.message);
      yield put(getFitSearchQueryFail(response.message));
    }
  } catch (err) {
    logActionEvent('fit_search_error', err);
    yield put(getFitSearchQueryFail(err));
  }
}

function* fitSubmitRequest(action: Action) {
  try {
    logActionEvent('fit_request_initiated', action.payload);
    const { data: response } = yield call(submitFitRequest, action.payload);
    if (response.success) {
      logActionEvent('fit_request_success');
      yield put(submitFitRequestSuccessful());
    } else {
      logActionEvent('fit_request_fail', response.message);
      yield put(submitFitRequestFail(response.message));
    }
  } catch (err) {
    logActionEvent('fit_request_error', err);
    yield put(submitFitRequestFail(err));
  }
}

export function* fetchFitSearchResultWatcher() {
  yield takeLatest(FIT_GET_SEARCH_RESULT, fitGetSearchResult);
}

export function* fetchFitSubmitRequestWatcher() {
  yield takeLatest(FIT_SUBMIT_REQUEST, fitSubmitRequest);
}
