export const USER_ROLES = {
  agent: 1,
  operations: 2,
  finance: 3,
  sales: 4,
  management: 5,
  superAdmin: 6,
  agSuperUser: 7,
  sub_agent: 1000001,
  employee: 2000001,
};

export const USER_ROLES_NAME = {
  [USER_ROLES.agent]: 'AGENT',
  [USER_ROLES.operations]: 'OPERATIONS',
  [USER_ROLES.finance]: 'FINANCE',
  [USER_ROLES.sales]: 'SALES',
  [USER_ROLES.management]: 'MANAGEMENT',
  [USER_ROLES.superAdmin]: 'SUPER_ADMIN',
  [USER_ROLES.agSuperUser]: 'AG SUPER USER',
};

export const PAYMENT_STATUS = {
  PENDING: 'PENDING',
  ON_HOLD: 'ON_HOLD',
  APPROVED: 'APPROVED',
  CANCELLED: 'CANCELLED',
  SUCCESS: 'SUCCESS',
};

export const V1_PAYMENT_STATUS = {
  INITIATED: 'INITIATED',
  PENDING: 'PENDING',
  CANCELED: 'CANCELLED',
  SUCCESS: 'SUCCESS',
  DECLINED: 'DECLINED',
  EXPIRED: 'EXPIRED',
  ERROR: 'ERROR',
};

export const RECEIPT_STATUS = {
  PAYMENT_PENDING: 'PAYMENT PENDING',
  PENDING: 'PENDING',
  POSTED: 'POSTED',
  FAILED: 'FAILED',
};

export const PAYMENT_STATUS_TITLES = {
  PENDING: 'Pending',
  ON_HOLD: 'On Hold',
  APPROVED: 'Approved',
  CANCELLED: 'Cancelled',
};

export const TRANSACTION_TYPE = {
  TELEX: 'Bank Transfer',
  CHEQUE: 'Cheque',
  CASH: 'Cash',
  ONE_BILL: 'One Bill',
  CREDIT_CARD: 'Credit Card',
};

export const REFRESH_TOKEN = 'refresh-token';
export const ACCESS_TOKEN = 'access-token';
export const USER = 'user';
export const SETTINGS = 'settings';
export const PERMISSIONS = 'permissions';
export const ORGANIZATION = 'organization';
export const LAST_FETCHED_FEATURED_NEWS_TIME =
  'last-fetched-featured-news-time';

export const PK_PHONE_REGEX = /^[+ 0-9]{13}$/;
export const PASSWORD_STRENGTH_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const LIMIT_REQUEST_PRIORITIES = [
  {
    key: 'low',
    value: 'Low',
  },
  {
    key: 'normal',
    value: 'Normal',
  },
  {
    key: 'urgent',
    value: 'Urgent',
  },
];

export const FORMAT = 'yyyy-MMMM-dd';
export const CUSTOMER_ERROR_FORMAT = 'dd-MMM-yyyy hh:mm aa';

export const USER_ROLES_LIST = [
  {
    key: '1',
    value: 'AGENT',
  },
  {
    key: '2',
    value: 'OPERATIONS',
  },
  {
    key: '3',
    value: 'FINANCE',
  },
  {
    key: '4',
    value: 'SALES',
  },
];

export const CREDIT_LIMIT_REQ_STATUS = {
  new: 'new',
  inProgress: 'in_progress',
  partiallyApproved: 'partially_approved',
  approved: 'approved',
  cancelled: 'cancelled',
  expired: 'expired',
};

export const CREDIT_LIMIT_REQ_STATUS_VALUES = {
  [CREDIT_LIMIT_REQ_STATUS.new]: 'New',
  [CREDIT_LIMIT_REQ_STATUS.inProgress]: 'In Progress',
  [CREDIT_LIMIT_REQ_STATUS.partiallyApproved]: 'Pending For Approval',
  [CREDIT_LIMIT_REQ_STATUS.approved]: 'Approved',
  [CREDIT_LIMIT_REQ_STATUS.cancelled]: 'Cancelled',
  [CREDIT_LIMIT_REQ_STATUS.expired]: 'Expired',
};

export const REQUEST_ACTION_TYPE = {
  approved: '0',
  rejected: '1',
  partially_approved: '2',
};

export const REQUEST_ACTION_TYPE_NAME = {
  [REQUEST_ACTION_TYPE.approved]: 'Request Approved',
  [REQUEST_ACTION_TYPE.rejected]: 'Request Rejected',
  [REQUEST_ACTION_TYPE.partially_approved]: 'Request Partially Approved',
};

export const USER_ACTIONS = {
  expire: 'Expire',
  generate: 'Generate',
  update: 'Update',
  create: 'Create',
};

export const AGENT_BROWSER_TROUBLESHOOTING = [
  'Update your browser to the latest version.',
  'Refresh browser.',
  'Try Hard browser refresh by pressing CTRL + SHIFT + R.',
  ' Try Deleting local cookies by pressing CTRL + SHIFT + DELETE and then Refresh the browser.',
  'Try to access portal in private window by pressing CTRL + SHIFT + N.',
  'Contact with the Sales Team.',
];

export const OTP_TIME_LIMITS = {
  30: '30 Mins',
  60: '1 Hour',
  120: '2 Hours',
  240: '4 Hours',
  360: '6 Hours',
  720: '12 Hours',
  1440: '24 Hours',
};

export const AIRLINES = {
  PIA: 'pia',
  AIRBLUE: 'airblue',
  SERENE: 'serene',
  AIRSIAL: 'airsial',
};

export enum APPSETTINGS {
  SHOW_BANNERED_IMAGE_NEWS = 'SHOW_BANNERED_IMAGE_NEWS',
}

export const titles = [
  { label: 'Mr', value: 'MR', type: 'ADT' },
  { label: 'Mrs', value: 'MRS', type: 'ADT' },
  { label: 'Ms', value: 'MS', type: 'ADT' },
  { label: 'Master', value: 'MASTER', type: 'CNN' },
  { label: 'Ms', value: 'MS', type: 'CNN' },
];

export const hotel_titles = [
  { label: 'Mr', value: 'Mr', type: 'Adult' },
  { label: 'Mrs', value: 'Mrs', type: 'Adult' },
  { label: 'Ms', value: 'Ms', type: 'Adult' },
  { label: 'Master', value: 'MASTER', type: 'Child' },
  { label: 'Ms', value: 'Ms', type: 'Child' },
];

export const genders = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'Others', value: 'O' },
];

export const passengerTypes = {
  ADT: 'Adult',
  CNN: 'Child',
  INF: 'Infant',
};

export const passengerRelations = {
  LEAD: 'LEAD',
  SON: 'SON',
  BROTHER: 'BROTHER',
  SISTER: 'SISTER',
  DAUGHTER: 'DAUGHTER',
  MOTHER: 'MOTHER',
  FATHER: 'FATHER',
};

export const PAYMENT_METHODS: Array<{ name: string; value: string }> = [
  {
    name: 'IBFT - Bank Transfer - One Bill',
    value: 'ONE_BILL',
  },
  {
    name: 'Debit/Credit Card',
    value: 'CREDIT_CARD',
  },
];

export const SORT_BY_ITEMS = [
  { label: 'Date', value: 'date' },
  { label: 'Airline', value: 'airline' },
];

export const pay_guide_url =
  "https://firebasestorage.googleapis.com/v0/b/aeroglobe-network.appspot.com/o/Guide%20for%20INTERNET%20%26%20MOBILE%20BANKING.%20OTC%2C%20Wallets%20%26%20ATM's.pdf?alt=media&token=6ad19cce-670d-4ee0-9b2c-cb1a6a69b795";

export * from './analytics';
