import { AgentListType, AgentNewsListType } from 'src/types';
import {
  FETCH_AGENTS,
  RESET,
  FETCH_AGENTS_SUCCESS,
  FETCH_AGENTS_FAILED,
  FETCH_AGENTS_NEWS,
  FETCH_AGENTS_NEWS_SUCCESS,
  FETCH_AGENTS_NEWS_FAILED,
  FETCH_AGENT_DETAILS,
  FETCH_AGENT_DETAILS_FAILED,
  FETCH_AGENT_DETAILS_SUCCESS,
  FETCH_AGENT_PRICING_DETAILS,
  FETCH_AGENT_PRICING_DETAILS_FAILED,
  FETCH_AGENT_PRICING_DETAILS_SUCCESS,
  FETCH_AGENTS_FEATURED_NEWS,
  FETCH_AGENTS_FEATURED_NEWS_FAILED,
  FETCH_AGENTS_FEATURED_NEWS_SUCCESS,
  DISABLE_AGENTS_FEATURED_NEWS,
} from '../constants/agent';

export const resetAgent = () => {
  return {
    type: RESET,
  };
};

export const fetchAgents = () => ({ type: FETCH_AGENTS });

export const fetchAgentsFullfilled = (agentList: Array<AgentListType>) => ({
  type: FETCH_AGENTS_SUCCESS,
  payload: agentList,
});

export const fetchAgentsFailed = () => ({ type: FETCH_AGENTS_FAILED });

export const fetchAgentsNews = () => ({
  type: FETCH_AGENTS_NEWS,
});

export const fetchAgentsNewsFullfilled = (
  agentList: Array<AgentNewsListType>
) => ({
  type: FETCH_AGENTS_NEWS_SUCCESS,
  payload: agentList,
});

export const fetchAgentsNewsFailed = () => ({
  type: FETCH_AGENTS_NEWS_FAILED,
});

export const fetchAgentDetails = payload => ({
  type: FETCH_AGENT_DETAILS,
  payload: payload,
});

export const fetchAgentDetailsFullfilled = agentDetails => ({
  type: FETCH_AGENT_DETAILS_SUCCESS,
  payload: agentDetails,
});

export const fetchAgentDetailsFailed = () => ({
  type: FETCH_AGENT_DETAILS_FAILED,
});

export const fetchAgentPricingDetails = payload => ({
  type: FETCH_AGENT_PRICING_DETAILS,
  payload,
});

export const fetchAgentPricingDetailsFailed = () => ({
  type: FETCH_AGENT_PRICING_DETAILS_FAILED,
});

export const fetchAgentPricingDetailsFullfilled = agentPricingDetails => ({
  type: FETCH_AGENT_PRICING_DETAILS_SUCCESS,
  payload: agentPricingDetails,
});

export const fetchAgentsFeaturedNews = () => ({
  type: FETCH_AGENTS_FEATURED_NEWS,
});

export const fetchAgentsFeaturedNewsFullfilled = (
  agentList: Array<AgentNewsListType>
) => ({
  type: FETCH_AGENTS_FEATURED_NEWS_SUCCESS,
  payload: agentList,
});

export const fetchAgentsFeaturedNewsFailed = () => ({
  type: FETCH_AGENTS_FEATURED_NEWS_FAILED,
});

export const disableAgentsFeaturedNews = () => ({
  type: DISABLE_AGENTS_FEATURED_NEWS,
});
