import React from 'react';
import { AuthProvider } from 'src/contexts/authContext';
import { ToasterProvider } from 'src/contexts/toasterContext';
import { ThemeProvider } from 'styled-components';
import { CONNECTION_ERROR, NETWORK_ERROR, TIMEOUT_ERROR } from 'apisauce';
import { hotjar } from 'react-hotjar';
import { BrowserRouter as Router } from 'react-router-dom';

import AppRoutes from 'src/routes';
import theme from 'src/styles/theme';
import { Maintainance } from './Maintainance';
import { ClientSide } from './ClientSide';
import { isServerActive } from './services/statusServices';
import LoadingProvider from './contexts/loadingContext';
import mixpanel from 'mixpanel-browser';
import * as amplitude from '@amplitude/analytics-browser';
import WithAxios from './components/WithAxios';
import ToolbarProvider from './contexts/toolbarContext';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { OtpDialogProvider } from './contexts/otpDialogContext';
import { FareCalculatorProvider } from './contexts/fareCalculatorContext';
import ScrollToTop from './helpers/scrollToTop';

function App() {
  const [serverStatusresponse, setResponse] = React.useState<any>();

  React.useEffect(() => {
    checkServerStatus();
    const mixpanel_key = process.env.REACT_APP_MIXPANEL_KEY as
      | undefined
      | string;
    const amplitude_key = process.env.REACT_APP_AMPLITUDE_KEY as
      | undefined
      | string;
    const id = process.env.REACT_APP_HOTJAR_SITE_ID as undefined | number;
    const version = process.env.REACT_APP_HOTJAR_SNIPPET_VERSION as
      | undefined
      | number;
    if (mixpanel_key) {
      mixpanel.init(mixpanel_key);
    }
    if (amplitude_key) {
      amplitude.init(amplitude_key);
    }
    if (id && version) {
      hotjar.initialize(id, version);
    }
  }, []);

  const checkServerStatus = async () => {
    const response = await isServerActive();
    setResponse(response);
  };

  if (
    serverStatusresponse?.status === null &&
    (serverStatusresponse?.problem === TIMEOUT_ERROR ||
      serverStatusresponse?.problem === CONNECTION_ERROR)
  ) {
    return <Maintainance />;
  } else if (
    serverStatusresponse?.status === null &&
    serverStatusresponse?.problem === NETWORK_ERROR
  ) {
    return <ClientSide />;
  }

  return (
    <ToolbarProvider>
      <LoadingProvider>
        <AuthProvider>
          <ToasterProvider>
            <OtpDialogProvider>
              <FareCalculatorProvider>
                <WithAxios>
                  <ThemeProvider theme={theme}>
                    <Router>
                      <ScrollToTop />
                      <AppRoutes />
                    </Router>
                  </ThemeProvider>
                </WithAxios>
              </FareCalculatorProvider>
            </OtpDialogProvider>
          </ToasterProvider>
        </AuthProvider>
      </LoadingProvider>
    </ToolbarProvider>
  );
}

export default App;
