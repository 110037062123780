export const flightStopsFilters: Array<{ label: string; value: string }> = [
  {
    label: 'Non Stop',
    value: '1',
  },
  {
    label: '1 Stop',
    value: '2',
  },
  {
    label: '2+ Stops',
    value: '3',
  },
];
