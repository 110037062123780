import React from 'react';
import { CCol, CRow } from '@coreui/react';
import { getFormattedDateTime } from './services/utils';
import {
  AGENT_BROWSER_TROUBLESHOOTING,
  CUSTOMER_ERROR_FORMAT,
} from './constants';

export const ClientSide: React.FC = () => {
  return (
    <CRow>
      <CCol
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className="d-flex justify-content-center mt-5"
      >
        <h2 className="text-center">
          Ooops, Seems like there is something wrong on your side. Please try
          troubleshooting by following steps below.
        </h2>
      </CCol>
      <CCol lg="12" md="12" sm="12" xs="12" className="d-flex">
        <ol>
          {AGENT_BROWSER_TROUBLESHOOTING.map((item, index) => (
            <li key={index.toString()}>
              <h5>{item}</h5>
            </li>
          ))}
        </ol>
      </CCol>
      <CCol
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className="d-flex justify-content-center mt-2"
      >
        <h2 className="text-center">
          {getFormattedDateTime(new Date(), CUSTOMER_ERROR_FORMAT)}
        </h2>
      </CCol>
    </CRow>
  );
};
