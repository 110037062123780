import { HotelSearchSuggestion, RoomOccupancy } from 'src/pages/HotelNew/types';
import {
  FIT_ADD_ITINERARY,
  FIT_GET_SEARCH_RESULT,
  FIT_GET_SEARCH_RESULT_FAIL,
  FIT_GET_SEARCH_RESULT_SUCCESS,
  FIT_REMOVE_ITINERARY,
  FIT_TOGGLE_SUBMIT_REQUEST_DIALOG,
  FIT_RESET,
  FIT_SET_SEARCH_QUERY,
  FIT_SUBMIT_REQUEST,
  FIT_SUBMIT_REQUEST_FAIL,
  FIT_SUBMIT_REQUEST_SUCCESS,
} from '../constants/fitConstant';
import {
  FITHotel,
  FITItinerary,
  FITSubmitRequestDto,
} from '../../pages/FIT/utils/models';

export const resetFitReducer = () => ({
  type: FIT_RESET,
});

export const setFitSearchQuery = (
  key: string,
  value: string | Date | RoomOccupancy[] | HotelSearchSuggestion
) => {
  return {
    type: FIT_SET_SEARCH_QUERY,
    payload: {
      key,
      value,
    },
  };
};

export const toggleSubmitRequestDialog = (payload: { show: boolean }) => ({
  type: FIT_TOGGLE_SUBMIT_REQUEST_DIALOG,
  payload,
});

export const addFitItinerary = (payload: { itinerary: FITItinerary }) => ({
  type: FIT_ADD_ITINERARY,
  payload,
});

export const removeFitItinerary = (payload: { itinerary: FITItinerary }) => ({
  type: FIT_REMOVE_ITINERARY,
  payload,
});

export const getFitSearchQuery = payload => ({
  type: FIT_GET_SEARCH_RESULT,
  payload,
});

export const getFitSearchQuerySuccessful = (hotels: Array<FITHotel>) => ({
  type: FIT_GET_SEARCH_RESULT_SUCCESS,
  payload: {
    hotels,
  },
});

export const getFitSearchQueryFail = (error: string) => ({
  type: FIT_GET_SEARCH_RESULT_FAIL,
  payload: {
    error,
  },
});

export const submitFitRequest = (payload: {
  request: FITSubmitRequestDto;
}) => ({
  type: FIT_SUBMIT_REQUEST,
  payload,
});

export const submitFitRequestSuccessful = () => ({
  type: FIT_SUBMIT_REQUEST_SUCCESS,
});

export const submitFitRequestFail = (error: string) => ({
  type: FIT_SUBMIT_REQUEST_FAIL,
  payload: {
    error,
  },
});
