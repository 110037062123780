import { HTTP_METHODS } from 'src/enums/http-methods';
import { HotelEndPoints } from 'src/pages/HotelNew/constants';
import apiService from './api';

export const getPropertiesService = (payload: any) => {
  return apiService.execute({
    url: HotelEndPoints.GET_ALL_PROPERTIES_V2,
    method: HTTP_METHODS.POST,
    data: payload,
  });
};

export const getPropertyDetailsService = (payload: any) => {
  const url = `${HotelEndPoints.GET_PROPERTY_DETAIL}`;
  return apiService.execute({ url, method: HTTP_METHODS.POST, data: payload });
};

export const getRegionsService = (query: string) => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: HotelEndPoints.GET_ALL_REGIONS,
    params: { search: query },
  });
};

export const getAllLocationsSuggestion = (query: string) => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: HotelEndPoints.GET_ALL_LOCATIONS,
    params: { search_query: query },
  });
};
