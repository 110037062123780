import {
  FETCH_FLIGHTS,
  FETCH_FLIGHTS_SUCCESS,
  FETCH_FLIGHTS_FAILED,
  FILTER_FLIGHTS_IN_LEG_BY_FARE_OPTION_IDS,
  UPDATE_FILTERED_FLIGHTS_DATA,
  UPDATE_FLIGHTS_FILTERS, SET_SELECTED_FLIGHT,EMPTY_ACTIVE_FLIGHTS
} from './../constants/flight';
import {FetchFlightsPayload, FlightOption, SelectedFlight} from '../../types';
import { FlightFilters } from '../types';

export const fetchFlights = (payload: FetchFlightsPayload) => ({
  type: FETCH_FLIGHTS,
  payload,
});

export const fetchFlightsFullfilled = payload => ({
  type: FETCH_FLIGHTS_SUCCESS,
  payload,
});

export const fetchFlightsFailed = () => ({
  type: FETCH_FLIGHTS_FAILED,
});

export const filterFlightsInLeg = (
  nextFareIds: Array<string>,
  leg: number
) => ({
  type: FILTER_FLIGHTS_IN_LEG_BY_FARE_OPTION_IDS,
  payload: {
    nextFareIds,
    leg,
  },
});

export const updateFilteredData = (payload: Array<FlightOption>) => ({
  type: UPDATE_FILTERED_FLIGHTS_DATA,
  payload,
});

export const updateFlightFilters = (payload: FlightFilters) => {
  return {
    type: UPDATE_FLIGHTS_FILTERS,
    payload,
  };
};

export const setSelectedFlight=(payload:{value:SelectedFlight,key:string})=>{
  return {
    type:SET_SELECTED_FLIGHT,
    payload
  }
}


export const emptySelectedFlights = () => ({
  type: EMPTY_ACTIVE_FLIGHTS
});
