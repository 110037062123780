import { HTTP_METHODS } from 'src/enums/http-methods';
import apiService from './api';

export const getFlightsService = payload => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: 'v1/flights/search/',
    data: payload,
  });
};

export const getFlightsFareRules = payload => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: 'v1/flights/fare_rules/',
    data: payload,
  });
};
