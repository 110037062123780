import { takeLatest, call, put } from 'redux-saga/effects';
import {
  getAgentDetailsByCustomerCodeService,
  getAgentPricingDetailsByCustomerCodeService,
  getAgentsService,
  getFeaturedNewsService,
  getNewsService,
} from 'src/services/agentServices';
import {
  fetchAgentsNewsFailed,
  fetchAgentsNewsFullfilled,
  fetchAgentsFailed,
  fetchAgentsFullfilled,
  fetchAgentDetailsFullfilled,
  fetchAgentDetailsFailed,
  fetchAgentPricingDetailsFailed,
  fetchAgentPricingDetailsFullfilled,
  fetchAgentsFeaturedNewsFullfilled,
  fetchAgentsFeaturedNewsFailed,
} from '../actions/agent';
import {
  FETCH_AGENTS,
  FETCH_AGENTS_NEWS,
  FETCH_AGENT_DETAILS,
  FETCH_AGENT_PRICING_DETAILS,
  FETCH_AGENTS_FEATURED_NEWS,
} from '../constants/agent';

function* fetchAgents() {
  try {
    const response = yield call(getAgentsService);
    if (response.ok && response.data?.success) {
      yield put(fetchAgentsFullfilled(response?.data?.response?.data || []));
    } else {
      yield put(fetchAgentsFailed());
    }
  } catch (err) {
    yield put(fetchAgentsFailed());
  }
}

function* fetchAgentsNews() {
  try {
    const response = yield call(getNewsService);
    if (response.ok && response.data?.success) {
      yield put(fetchAgentsNewsFullfilled(response?.data?.response || []));
    } else {
      yield put(fetchAgentsNewsFailed());
    }
  } catch (err) {
    yield put(fetchAgentsNewsFailed());
  }
}

function* fetchAgentsFeaturedNews() {
  try {
    const response = yield call(getFeaturedNewsService);
    if (response.ok && response.data?.success) {
      yield put(fetchAgentsFeaturedNewsFullfilled(response?.data?.response));
    } else {
      yield put(fetchAgentsFeaturedNewsFailed());
    }
  } catch (err) {
    yield put(fetchAgentsFeaturedNewsFailed());
  }
}

function* fetchAgentDetails(action) {
  try {
    const response = yield call(
      getAgentDetailsByCustomerCodeService,
      action.payload
    );
    if (response.ok && response.data?.success) {
      yield put(fetchAgentDetailsFullfilled(response?.data?.response || []));
    } else {
      yield put(fetchAgentDetailsFailed());
    }
  } catch (err) {
    yield put(fetchAgentDetailsFailed());
  }
}

function* fetchAgentPricingDetails(action) {
  try {
    const response = yield call(
      getAgentPricingDetailsByCustomerCodeService,
      action.payload
    );
    if (response.ok && response.data?.success) {
      yield put(
        fetchAgentPricingDetailsFullfilled(response?.data?.response || [])
      );
    } else {
      yield put(fetchAgentPricingDetailsFailed());
    }
  } catch (err) {
    yield put(fetchAgentPricingDetailsFailed());
  }
}

export function* fetchAgentsWatcher() {
  yield takeLatest(FETCH_AGENTS, fetchAgents);
}

export function* fetchAgentNewsWatcher() {
  yield takeLatest(FETCH_AGENTS_NEWS, fetchAgentsNews);
}

export function* fetchAgentFeaturedNewsWatcher() {
  yield takeLatest(FETCH_AGENTS_FEATURED_NEWS, fetchAgentsFeaturedNews);
}

export function* fetchAgentDetailsWatcher() {
  yield takeLatest(FETCH_AGENT_DETAILS, fetchAgentDetails);
}

export function* fetchAgentPricingDetailsWatcher() {
  yield takeLatest(FETCH_AGENT_PRICING_DETAILS, fetchAgentPricingDetails);
}
