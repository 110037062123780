import { HTTP_METHODS } from 'src/enums/http-methods';
import apiService from './api';

export const expireOTPService = (payload: any) => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: `/account-otp/expire/${payload}`,
    data: {},
  });
};

export const createOTPService = payload => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: '/account-otp/create',
    data: payload,
  });
};

export const getOTPService = page => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: `/account-otp/list?page=${page}`,
  });
};
