import {
  calculateMinMaxPricesOfProperties,
  doFilterProperties,
} from 'src/pages/HotelNew/utils/utility';
import {
  RESET,
  FETCH_PROPERTIES,
  FETCH_PROPERTIES_FULFILLED,
  FETCH_PROPERTY_DETAILS,
  FETCH_PROPERTY_DETAILS_FULLFILLED,
  FETCH_PROPERTIES_FAILED,
  FETCH_PROPERTY_DETAILS_FAILED,
  RESET_FETCHED_PROPERTIES,
  UPDATE_HOTEL_FILTERS,
} from '../constants/hotels';
import { Action, HotelsReducer } from '../types';
import {
  HOTEL_BOOKING_POLICY_FILTERS,
  HOTEL_PROPERTY_RATINGS_FILTERS,
} from './hotelSearchQuery';

const initialState: HotelsReducer = {
  isFetchingProperties: false,
  propertyResponse: {
    hotels: [],
    filters: null,
    current: 1,
    has_previous: false,
    has_next: false,
    searched_property_not_found: null,
  },
  isFetchingPropertyDetails: false,
  propertyDetails: null,
  isFetchingPropertyRoomDetails: false,
  propertyRoomDetails: null,
  selectedFilters: {
    priceRange: [0, 0],
    propertyRating: HOTEL_PROPERTY_RATINGS_FILTERS.map(filter => filter.value),
    bookingPolicy: HOTEL_BOOKING_POLICY_FILTERS.map(filter => filter.value),
  },
  filteredProperties: [],
};

const hotelsReducer = (state = initialState, action: Action): HotelsReducer => {
  switch (action.type) {
    case RESET: {
      return {
        ...initialState,
      };
    }

    case FETCH_PROPERTIES: {
      return {
        ...state,
        isFetchingProperties: true,
        fetchingPropertiesError: null,
        propertyResponse: initialState.propertyResponse,
      };
    }

    case FETCH_PROPERTIES_FULFILLED: {
      const allProperties = action.payload.loadMoreProperties
        ? [
            ...state.propertyResponse.hotels,
            ...action.payload.propertyResponse.hotels,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i)
        : [...action.payload.propertyResponse.hotels];

      const newSelectedFilters = (() => {
        return {
          ...state.selectedFilters,
          priceRange: calculateMinMaxPricesOfProperties(allProperties),
        };
      })();

      return {
        ...state,
        isFetchingProperties: false,
        propertyResponse: {
          ...state.propertyResponse,
          ...action.payload.propertyResponse,
          hotels: allProperties,
          searched_property_not_found: action.payload.propertyFound,
        },
        selectedFilters: newSelectedFilters,
        filteredProperties: doFilterProperties(
          newSelectedFilters,
          allProperties
        ),
      };
    }

    case FETCH_PROPERTIES_FAILED: {
      return {
        ...state,
        isFetchingProperties: false,
        fetchingPropertiesError: action.payload.error,
      };
    }

    case RESET_FETCHED_PROPERTIES: {
      return {
        ...state,
        isFetchingProperties: false,
        fetchingPropertiesError: null,
        propertyResponse: initialState.propertyResponse,
      };
    }

    case FETCH_PROPERTY_DETAILS: {
      return {
        ...state,
        isFetchingPropertyDetails: true,
      };
    }

    case FETCH_PROPERTY_DETAILS_FAILED: {
      return {
        ...state,
        isFetchingPropertyDetails: false,
      };
    }

    case FETCH_PROPERTY_DETAILS_FULLFILLED: {
      return {
        ...state,
        propertyDetails: action.payload,
        isFetchingPropertyDetails: false,
      };
    }

    case UPDATE_HOTEL_FILTERS: {
      const allProperties = [...state.propertyResponse.hotels];
      const newSelectedFilters = {
        ...state.selectedFilters,
        [action.payload.key]: action.payload.value,
      };

      return {
        ...state,
        selectedFilters: newSelectedFilters,
        filteredProperties: doFilterProperties(
          newSelectedFilters,
          allProperties
        ),
      };
    }

    default:
      return state;
  }
};

export default hotelsReducer;
