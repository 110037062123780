import { WEEK_DAYS } from './../enums/week_day_mapping';
import { differenceInDays } from 'date-fns/esm';
import { PropertyImage, Room, SearchQuery } from './../pages/HotelNew/types';
import { AgentLimitRequest } from 'src/types';
import Analytics from 'src/services/analytics';
import { format as dateFnsFormat, parse } from 'date-fns';
import {
  CREDIT_LIMIT_REQ_STATUS,
  CREDIT_LIMIT_REQ_STATUS_VALUES,
  FORMAT,
  LIMIT_REQUEST_PRIORITIES,
  USER_ROLES_NAME,
} from 'src/constants';
import { createFilterOptions } from '@mui/material';
import moment from 'moment';
import qs from 'qs';
import { ELEMENT_TYPES } from '../enums/element_types';
import { BOOKING_STATUSES } from '../enums/booking_statuses';

export const getFormattedDateTime = (
  timeStamp: Date,
  format: string = FORMAT
) => {
  return dateFnsFormat(new Date(timeStamp), format);
};

export const getFormattedDateFromDateString = (
  dateString: string,
  format: string = FORMAT
): Date => {
  return parse(dateString, format, new Date());
};

export const getCreditLimitPriority = (
  priority: string
): string | undefined => {
  return LIMIT_REQUEST_PRIORITIES.find(req => req.key === priority)?.value;
};

export const addPageViewEvent = (
  pathname: string,
  role?: number | null
): void => {
  const userType = USER_ROLES_NAME[role || ''];
  Analytics.PageViewAnalytics(pathname, { userType });
};

export const getAgentCreditLimitStatus = (req: AgentLimitRequest) => {
  if (req.expiry_date && new Date(req.expiry_date).getTime() < Date.now()) {
    return CREDIT_LIMIT_REQ_STATUS_VALUES[CREDIT_LIMIT_REQ_STATUS.expired];
  }

  return CREDIT_LIMIT_REQ_STATUS_VALUES[req.status];
};

export const formatNumber = (num: number | string): string => {
  const valueAsNumber: number = +num;
  const valueAsString =
    valueAsNumber % 1 === 0
      ? valueAsNumber.toString()
      : valueAsNumber.toFixed(2);
  return valueAsString.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

export const flightsFilterOptions = createFilterOptions({
  stringify: (option: any) =>
    option.name + option.city + option.country + option.iata_code,
});

export const formatDate = (date: any) => moment(date).format('YYYY-MM-DD');
export const formatDateTime = (date: any) =>
  moment(date).format('YYYY-MM-DD HH:mm');

export const formatTime = (date: string) => moment(date).format('HH:mm');

export const convertTime = num => {
  var hours = Math.floor(num / 60);
  var minutes = num % 60;
  return { hours, minutes };
};

export const convertTimeToDuration = (from, to) => {
  const startTime = moment(from, 'YYYY-MM-DDTHH:mm:ss');
  const endTime = moment(to, 'YYYY-MM-DDTHH:mm:ss');
  const duration = moment.duration(endTime.diff(startTime));
  const years = Math.round(duration.asYears());
  const months = Math.round(duration.asMonths());
  const weeks = Math.round(duration.asWeeks());
  const days = Math.round(duration.asDays());
  const hours = Math.round(duration.asHours());
  const minutes = Math.round(duration.asMinutes() % 60);
  return { years, months, weeks, days, hours, minutes };
};

export const isExpired = (
  from: moment.MomentInput,
  to?: moment.MomentInput
): boolean => {
  return moment(from).isBefore(to || new Date(Date.now()));
};

export const getConfirmOrInitiateDate = (
  status: BOOKING_STATUSES,
  initiated_booking_expires_at: string,
  confirmed_booking_expires_at: null | string
): string => {
  if (status.toLowerCase() === BOOKING_STATUSES.CONFIRMED.toLowerCase())
    return confirmed_booking_expires_at || 'Invalid Date';
  return initiated_booking_expires_at;
};

export const renderInCustomFormat = (date: string | Date, format: string) =>
  moment(date).format(format);

export const formatDateInReadableForm = date =>
  moment(date).format('MMM DD, YYYY');

export const formatDateTimeInReadableForm = date =>
  moment(date).format('MMM DD, YYYY HH:mm A');
export const stringifyQuery = (payload: any) => qs.stringify(payload);

export const parseQuery = (queryString: any) =>
  qs.parse(queryString.substring(1, queryString.length));

export const getWeekDay = (date: string | Date) => {
  const weekDayNum = moment(date).day();

  return WEEK_DAYS[weekDayNum];
};

export const customBookingKey = (
  origin: string,
  destination: string,
  departureDate: Date
) => {
  return `${origin}-${destination}-${formatDate(departureDate)}`;
};

export const isPropertyFromPakistan = (countryName: string): boolean =>
  countryName === 'Pakistan';

export const isPropertyFullyBooked = (rooms: Array<Room>): boolean => {
  return rooms.every(room => room.stop_sell.length);
};

const getIfPlural = (count: number): string => {
  return count > 1 ? 's' : '';
};

export const getThumbnailImage = (images: Array<PropertyImage>): string => {
  return images.length ? images[0].url : '';
};

const getChildren = (total_childs: number): string => {
  return total_childs > 0
    ? `${
        total_childs > 1
          ? `, ${total_childs} children`
          : `, ${total_childs} child`
      }`
    : '';
};

const getAdults = (total_adults: number): string => {
  return `${total_adults} adult${getIfPlural(total_adults)}`;
};

export const getNights = (total_nights: number): string => {
  return `${total_nights} night${getIfPlural(total_nights)}`;
};

export const getNightsAndPersonCount = (
  hotelSearchQuery: SearchQuery | null
): string => {
  if (hotelSearchQuery) {
    const total_nights = differenceInDays(
      new Date(hotelSearchQuery.checkout),
      new Date(hotelSearchQuery.checkin)
    );
    const total_adults = hotelSearchQuery.rooms_occupancy[0].adults;
    const total_childs = hotelSearchQuery.rooms_occupancy[0].children
      ? hotelSearchQuery.rooms_occupancy[0].children.length
      : 0;
    return hotelSearchQuery
      ? `${getNights(total_nights)}, ${getAdults(total_adults)}${getChildren(
          total_childs
        )}`
      : '';
  }
  return '';
};

export const getTravelersCountForRoom = (
  adultCount: number,
  childCount: number,
  disableRooms?: boolean
): string => {
  let travelerCount = adultCount + childCount;

  let travelers = `Traveler${travelerCount > 1 ? 's' : ''} ${travelerCount}`;
  if (!disableRooms) {
    travelers += ', Room 1';
  }

  return travelers;
};

export const getTravelersCountForFlight = (
  adultCount: number,
  childCount: number,
  infantCount: number
): string => {
  let travelers = `${adultCount} Adult${adultCount > 1 ? 's' : ''}`;

  if (childCount > 0) {
    travelers += `, ${childCount} Child${childCount > 1 ? 'ren' : ''}`;
  }

  if (infantCount > 0) {
    travelers += `, ${infantCount} Infant${infantCount > 1 ? 's' : ''}`;
  }
  return travelers;
};

export const addDaysToDate = (
  date: Date | string | moment.Moment,
  days: number
) => moment(date).add(days, 'days').toDate();

export const getPreviousDate = (
  date: Date | string | moment.Moment,
  days: number
) => moment(date).subtract(days, 'days').toDate();

export const randomInt = (max, min) =>
  Math.round(Math.random() * (max - min)) + min;

export const genTestId = (type: ELEMENT_TYPES, name: string, prefix = '') => {
  return `${type}${prefix && prefix.length ? `-${prefix}` : ''}-${name}`;
};

export const genTestFlightName = (name: string) => {
  return name.replace(' ', '-').toLowerCase() + '-';
};
