import React from 'react';
import { CCol, CRow } from '@coreui/react';
import maintaince from 'src/static/images/maintainance.svg';
import { getFormattedDateTime } from './services/utils';
import { CUSTOMER_ERROR_FORMAT } from './constants';

export const Maintainance: React.FC = () => {
  return (
    <CRow>
      <CCol
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className="d-flex justify-content-center mt-5"
      >
        <h2 className="text-center">
          Aeroglobe Portal is currently under maintainance
        </h2>
      </CCol>
      <CCol
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className="d-flex justify-content-center mt-2"
      >
        <h2 className="text-center">We will be back shortly</h2>
      </CCol>
      <CCol
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className="d-flex justify-content-center mt-2"
      >
        <h2 className="text-center">
          {getFormattedDateTime(new Date(), CUSTOMER_ERROR_FORMAT)}
        </h2>
      </CCol>
      <CCol
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className="d-flex justify-content-center mt-2"
      >
        <img src={maintaince} alt="maintainance" width="150" />
      </CCol>
    </CRow>
  );
};
