export const FIT_RESET = 'FIT_RESET';
export const FIT_SET_SEARCH_QUERY = 'FIT_SET_SEARCH_QUERY';

export const FIT_TOGGLE_SUBMIT_REQUEST_DIALOG =
  'FIT_TOGGLE_SUBMIT_REQUEST_DIALOG';
export const FIT_ADD_ITINERARY = 'FIT_ADD_ITINERARY';
export const FIT_REMOVE_ITINERARY = 'FIT_REMOVE_ITINERARY';

export const FIT_GET_SEARCH_RESULT = 'FIT_GET_SEARCH_RESULT';
export const FIT_GET_SEARCH_RESULT_SUCCESS = 'FIT_GET_SEARCH_RESULT_SUCCESS';
export const FIT_GET_SEARCH_RESULT_FAIL = 'FIT_GET_SEARCH_RESULT_FAIL';

export const FIT_SUBMIT_REQUEST = 'FIT_SUBMIT_REQUEST';
export const FIT_SUBMIT_REQUEST_SUCCESS = 'FIT_SUBMIT_REQUEST_SUCCESS';
export const FIT_SUBMIT_REQUEST_FAIL = 'FIT_SUBMIT_REQUEST_FAIL';
