import { Action } from './../types';
import {
  FETCH_AIRPORTS,
  FETCH_AIRPORTS_FAILED,
  FETCH_AIRPORTS_SUCCESS,
  RESET,
} from '../constants/airport';
import { AirportReducer } from '../types';

const initialState: AirportReducer = {
  isAirportFetching: false,
  airports: [],
};

const airportReducer = (
  state = initialState,
  action: Action
): AirportReducer => {
  switch (action.type) {
    case RESET: {
      return {
        ...initialState,
      };
    }
    case FETCH_AIRPORTS:
      return {
        ...state,
        isAirportFetching: true,
      };

    case FETCH_AIRPORTS_SUCCESS:
      return {
        airports: action.payload,
        isAirportFetching: false,
      };

    case FETCH_AIRPORTS_FAILED:
      return {
        ...state,
        isAirportFetching: false,
      };

    default:
      return state;
  }
};

export default airportReducer;
