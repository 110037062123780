import {
  SET_QUERY,
  SET_COMPLETE_SEARCH_QUERY,
  RESET,
  RESET_FLIGHT_SEARCH_QUERY,
  UPDATE_FLIGHT_SEARCH_QUERY_FILTERS,
  RESET_MULTICITY_SEARCH_QUERY,
} from './../constants/flightSearchQuery';
import { CABIN_CLASS } from 'src/enums/cabin_class';
import { ROUTE_TYPE } from 'src/enums/route-type';
import { Action, FlightSearchQueryReducer } from './../types';
import moment from 'moment';

const multicityObjects = [
  {
    departure_date: new Date(moment().add(1, 'days').format()),
    origin: {
      name: '',
      airport: '',
      city: '',
      country: '',
      time_zone: '',
      iata_code: '',
    },
    destination: {
      name: '',
      airport: '',
      city: '',
      country: '',
      time_zone: '',
      iata_code: '',
    },
  },
  {
    departure_date: new Date(moment().add(3, 'days').format()),
    origin: {
      name: '',
      airport: '',
      city: '',
      country: '',
      time_zone: '',
      iata_code: '',
    },
    destination: {
      name: '',
      airport: '',
      city: '',
      country: '',
      time_zone: '',
      iata_code: '',
    },
  },
];

const initialState: FlightSearchQueryReducer = {
  route_type: ROUTE_TYPE.ONEWAY,
  origin: {
    name: '',
    airport: '',
    city: '',
    country: '',
    time_zone: '',
    iata_code: '',
  },
  destination: {
    name: '',
    airport: '',
    city: '',
    country: '',
    time_zone: '',
    iata_code: '',
  },
  departure_date: new Date(),
  return_date: new Date(),
  cabin_class: CABIN_CLASS.ECONOMY,
  traveler_count: {
    adult_count: 1,
    child_count: 0,
    infant_count: 0,
  },
  non_stop_flight: false,
  current_leg: 0,
  next_leg: 0,
  total_legs: 0,
  filters: {
    airlines: [],
    stops: [],
    priceRange: [],
    departureTime: [],
  },
  multiCityLegs: [...multicityObjects],
};

const flightSearchQueryReducer = (
  state = initialState,
  action: Action
): FlightSearchQueryReducer => {
  switch (action.type) {
    case RESET:
      return initialState;
    case RESET_FLIGHT_SEARCH_QUERY:
      return initialState;
    case SET_QUERY:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case SET_COMPLETE_SEARCH_QUERY:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_MULTICITY_SEARCH_QUERY:
      return {
        ...state,
        departure_date: new Date(),
        return_date: new Date(),
        multiCityLegs: [...multicityObjects],
      };
    case UPDATE_FLIGHT_SEARCH_QUERY_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default flightSearchQueryReducer;
