import {
  FETCH_TOURS,
  FETCH_TOURS_SUCCESS,
  FETCH_TOURS_FAILED,
  FETCH_TOUR,
  FETCH_TOUR_SUCCESS,
  FETCH_TOUR_FAILURE,
} from '../constants/tours';

export const fetchTours = (payload: { tourType: string }) => ({
  type: FETCH_TOURS,
  payload,
});

export const fetchToursFullfilled = data => ({
  type: FETCH_TOURS_SUCCESS,
  payload: { data },
});

export const fetchToursFailed = () => ({
  type: FETCH_TOURS_FAILED,
});

export const fetchTour = (payload: string) => ({
  type: FETCH_TOUR,
  payload,
});

export const fetchTourSuccess = data => ({
  type: FETCH_TOUR_SUCCESS,
  payload: { data },
});

export const fetchTourFailure = () => ({
  type: FETCH_TOUR_FAILURE,
});
