import React from 'react';
import Spinner from 'src/components/Spinner';
import { Box } from '@mui/system';

type LoadingContextProps = {
  isLoading: boolean;
  toggle: Function;
  start: Function;
  stop: Function;
};

const initialValue = {
  isLoading: false,
  toggle: (isLoading?: boolean) => {},
  start: () => {},
  stop: () => {},
};

const LoadingContext = React.createContext<LoadingContextProps>(initialValue);

const LoadingProvider = props => {
  const [isLoading, setIsLoading] = React.useState<boolean>(
    initialValue.isLoading
  );

  const toggle = loadingState => {
    if (typeof loadingState === 'boolean') {
      setIsLoading(loadingState);
    } else {
      setIsLoading(!isLoading);
    }
  };
  const start = () => setIsLoading(true);
  const stop = () => setIsLoading(false);

  return (
    <LoadingContext.Provider value={{ isLoading, toggle, start, stop }}>
      {props.children}
      {isLoading ? (
        <Box
          position={'fixed'}
          top={0}
          left={0}
          width={'100vw'}
          height={'100vh'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          bgcolor={'rgb(0 0 0 / 73%)'}
          zIndex={9999}
        >
          <Spinner />
        </Box>
      ) : null}
    </LoadingContext.Provider>
  );
};

const useLoading = () => {
  const context = React.useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoading() must be used within LoadingProvider');
  }

  return context;
};

export default LoadingProvider;

export { useLoading };
