import CryptoJS from 'crypto-js';
import { PK_PHONE_REGEX } from 'src/constants';
import { Organization } from 'src/types';
import moment from 'moment';

const REACT_APP_APP_SALT = process.env.REACT_APP_APP_SALT || '';

export const redirectToPage = (
  push: any,
  redirectTo: string,
  params = null
) => {
  push({ pathname: redirectTo, search: params });
};

// Put the object into storage
export const setInLocalStorage = (name: string, value: string) =>
  localStorage && localStorage.setItem(name, value);

// Remove the object into storage
export const removeFromLocalStorage = (name: string) =>
  localStorage && localStorage.removeItem(name);

// Get the object from storage
export const getFromLocalStorage = (name: string) =>
  localStorage && localStorage.getItem(name);

// String encryption
export const stringEncrypt = (string: string) =>
  CryptoJS.AES.encrypt(string, REACT_APP_APP_SALT).toString();

//validates phone number
export const validatePhoneNumber = (phoneNumber: string) =>
  PK_PHONE_REGEX.test(phoneNumber);

// String decryption
export const stringDecrypt = (ciphertext: any) => {
  let value = null;
  try {
    /* sanity check: if the decryption of ciphertext fails due to difference
        in APP_SALT used for decryption VS. APP_SALT used for encryption */
    value = CryptoJS.AES.decrypt(ciphertext, REACT_APP_APP_SALT, {
      mode: CryptoJS.mode.CBC,
    }).toString(CryptoJS.enc.Utf8);
  } catch (error) {}
  return value;
};

export const parseLocalStorageData = (key: string) => {
  const data = getFromLocalStorage(key);
  const decryptedData = data && stringDecrypt(data);
  return decryptedData && JSON.parse(decryptedData);
};

//Parse and returns serializer errors

export const getSerializerErrors = (errors: Object): Array<string> => {
  const errorList: Array<string> = [];
  Object.keys(errors).map(key => {
    return errors[key].map((e: string) => errorList.push(e));
  });

  return errorList;
};

export const filterPayments = (payments, status) => {
  if (status === 'ALL') return payments;
  return payments?.filter(
    payment => payment?.status?.toLowerCase() === status?.toLowerCase()
  );
};

export const searchPayments = (payments, term) => {
  const lowerCaseTerm = term?.toLowerCase();
  if (lowerCaseTerm === '') return payments;
  return payments?.filter(
    ({
      bank_account_detail,
      public_id,
      agent_traacs_code,
      bank_transaction_code,
      posting_id,
    }) =>
      bank_account_detail?.bank_name?.toLowerCase()?.includes(lowerCaseTerm) ||
      public_id?.toLowerCase()?.includes(lowerCaseTerm) ||
      posting_id?.toLowerCase()?.includes(lowerCaseTerm) ||
      agent_traacs_code?.toLowerCase()?.includes(lowerCaseTerm) ||
      bank_transaction_code?.toLowerCase()?.includes(lowerCaseTerm)
  );
};
// Capitalize the first word
export const capitalize = (str: string): string => {
  var splitStr = str.toLowerCase().split('_');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

// Search Organizations
export const searchOrganizations = (
  organizations: Organization[],
  term: string
): Organization[] => {
  const list = [...organizations];
  if (!term && !list?.length) return list;
  const lowerCaseTerm = term?.toLowerCase();
  return list
    ?.slice()
    ?.filter(
      organization =>
        organization['name']?.toLowerCase()?.includes(lowerCaseTerm) ||
        organization['email']?.toLowerCase()?.includes(lowerCaseTerm) ||
        organization['agent_traacs_code']
          ?.toLowerCase()
          ?.includes(lowerCaseTerm)
    );
};

export const formatWordIntoCapitalize = (str: string) =>
  str.toLowerCase().replace(/(?:^|\s|[-"'([{])+\S/g, c => c.toUpperCase());

export const currencyFormatter = (currency = 'PKR') => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  });
};

export const analyticsDateFormater = (date: any) =>
  moment(date).format('MMM Do YYYY');

export const getTimeStatus = (hours: number) => {
  if (hours > 5 && hours <= 9) {
    return 'Early morning';
  } else if (hours > 9 && hours <= 12) {
    return 'Late morning';
  } else if (hours > 12 && hours <= 15) {
    return 'Early afternoon';
  } else if (hours > 15 && hours <= 18) {
    return 'Late afternoon';
  } else if (hours > 19 && hours <= 20) {
    return 'Evening';
  } else if (hours > 20 && hours <= 24) {
    return 'Early night';
  } else if (hours > 24 && hours <= 5) {
    return 'Late night';
  } else {
    return '';
  }
};

export const removeNullFromObject = (obj: any) => {
  return JSON.parse(JSON.stringify(obj).replace(/\:null/gi, ':""'));
};

export const formatErrorText = (err = '') => {
  const error = err.split('|');
  return error.length > 1 ? error[1] : error[0];
};

export const formatIntlPrice = (func, price) => {
  return func?.format(Number(price)).replace(/\D00(?=\D*$)/, '');
};
