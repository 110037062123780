export const FETCH_AGENTS = 'FETCH_AGENTS';
export const FETCH_AGENTS_SUCCESS = 'FETCH_AGENTS_SUCCESS';
export const FETCH_AGENTS_FAILED = 'FETCH_AGENTS_FAILED';

export const FETCH_AGENTS_NEWS = 'FETCH_AGENTS_NEWS';
export const FETCH_AGENTS_NEWS_SUCCESS = 'FETCH_AGENTS_NEWS_SUCCESS';
export const FETCH_AGENTS_NEWS_FAILED = 'FETCH_AGENTS_NEWS_FAILED';

export const FETCH_AGENTS_FEATURED_NEWS = 'FETCH_AGENTS_FEATURED_NEWS';
export const FETCH_AGENTS_FEATURED_NEWS_SUCCESS =
  'FETCH_AGENTS_FEATURED_NEWS_SUCCESS';
export const FETCH_AGENTS_FEATURED_NEWS_FAILED =
  'FETCH_AGENTS_FEATURED_NEWS_FAILED';
export const DISABLE_AGENTS_FEATURED_NEWS = 'DISABLE_AGENTS_FEATURED_NEWS';

export const FETCH_AGENT_DETAILS = 'FETCH_AGENT_DETAILS';
export const FETCH_AGENT_DETAILS_SUCCESS = 'FETCH_AGENT_DETAILS_SUCCESS';
export const FETCH_AGENT_DETAILS_FAILED = 'FETCH_AGENT_DETAILS_FAILED';

export const FETCH_AGENT_PRICING_DETAILS = 'FETCH_AGENT_PRICING_DETAILS';
export const FETCH_AGENT_PRICING_DETAILS_SUCCESS =
  'FETCH_AGENT_PRICING_DETAILS_SUCCESS';
export const FETCH_AGENT_PRICING_DETAILS_FAILED =
  'FETCH_AGENT_PRICING_DETAILS_FAILED';
export const RESET = 'RESET';
