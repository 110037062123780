export const FETCH_FLIGHTS = 'FETCH_FLIGHTS';
export const FETCH_FLIGHTS_SUCCESS = 'FETCH_FLIGHTS_SUCCESS';
export const FETCH_FLIGHTS_FAILED = 'FETCH_FLIGHTS_FAILED';

export const FILTER_FLIGHTS_IN_LEG_BY_FARE_OPTION_IDS =
  'FILTER_FLIGHTS_IN_LEG_BY_FARE_OPTION_IDS';

export const UPDATE_FILTERED_FLIGHTS_DATA = 'UPDATE_FILTERED_FLIGHTS_DATA';
export const SET_SELECTED_FLIGHT = 'SET_SELECTED_FLIGHT'
export const EMPTY_ACTIVE_FLIGHTS='EMPTY_ACTIVE_FLIGHTS'
export const UPDATE_FLIGHTS_FILTERS = 'UPDATE_FLIGHTS_FILTERS';
