export const FETCH_PAYMENTS = 'FETCH_PAYMENTS';
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_FAILED = 'FETCH_PAYMENTS_FAILED';

export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAILED = 'CREATE_PAYMENT_FAILED';

export const FETCH_PAYMENT = 'FETCH_PAYMENT';
export const FETCH_PAYMENT_SUCCESS = 'FETCH_PAYMENT_SUCCESS';
export const FETCH_PAYMENT_FAILED = 'FETCH_PAYMENT_FAILED';

export const RESET_PAYMENT = 'RESET_PAYMENT';
