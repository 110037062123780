export const ANALYTICS_PREFIX = 'ag_';
export const EVENT_NAME = {
  PAGE_VIEW: ANALYTICS_PREFIX + 'page_view',
  NAV_ITEM_SELECTED: ANALYTICS_PREFIX + 'nav_item_selected',
  SEND_SOA_ITEM_EMAIL_INITIATED:
    ANALYTICS_PREFIX + 'send_soa_item_email_initiated',
  SEND_SOA_ITEM_EMAIL_COMPLETED:
    ANALYTICS_PREFIX + 'send_soa_item_email_completed',
  DOWNLOAD_SOA_ITEM_FILE_INITIATED:
    ANALYTICS_PREFIX + 'download_soa_item_email_initiated',
  DOWNLOAD_SOA_ITEM_FILE_COMPLETED:
    ANALYTICS_PREFIX + 'download_soa_item_email_completed',
  CREATE_LIMIT_REQUEST_INITIATED:
    ANALYTICS_PREFIX + 'create_limit_request_initiated',
  CREATE_LIMIT_REQUEST_COMPLETED:
    ANALYTICS_PREFIX + 'create_limit_request_completed',
  GET_AGENT_BALANCE_INITIATED: ANALYTICS_PREFIX + 'get_agent_balance_initiated',
  GET_AGENT_BALANCE_COMPLETED: ANALYTICS_PREFIX + 'get_agent_balance_completed',
  CREATE_USER_INITIATED: ANALYTICS_PREFIX + 'create_user_initiated',
  CREATE_USER_COMPLETED: ANALYTICS_PREFIX + 'create_user_completed',
  GET_PNR_DETAILS_INITIATED: ANALYTICS_PREFIX + 'get_pnr_details_initiated',
  GET_PNR_DETAILS_COMPLETED: ANALYTICS_PREFIX + 'get_pnr_details_completed',
  ISSUE_TICKET_INITIATED: ANALYTICS_PREFIX + 'issue_ticket_initiated',
  ISSUE_TICKET_COMPLETED: ANALYTICS_PREFIX + 'issue_ticket_completed',
  LOGIN: ANALYTICS_PREFIX + 'login',
  LOGOUT: ANALYTICS_PREFIX + 'logout',
  UPDATE_PROFILE_INITIATED: ANALYTICS_PREFIX + 'update_profile_initiated',
  UPDATE_PROFILE_COMPLETED: ANALYTICS_PREFIX + 'update_profile_completed',
  CHANGE_PASSWORD_INITIATED: ANALYTICS_PREFIX + 'change_password_initiated',
  CHANGE_PASSWORD_COMPLETED: ANALYTICS_PREFIX + 'change_password_completed',
  GENERATE_OTP_TOKEN: ANALYTICS_PREFIX + 'generate_otp_token',
  EXPIRE_OTP_TOKEN: ANALYTICS_PREFIX + 'expire_otp_token',
  GET_OTP_TOKENS: ANALYTICS_PREFIX + 'get_otp_tokens',
  AG_HOTELS_SEARCH_FAILED: ANALYTICS_PREFIX + 'hotels_search_failed',
  AG_HOTELS_SEARCH_COMPLETED: ANALYTICS_PREFIX + 'hotels_search_completed',
  AG_HOTELS_SEARCH_INITIATED: ANALYTICS_PREFIX + 'hotels_search_initiated',
  AG_HOTELS_SEARCH: ANALYTICS_PREFIX + 'hotels_search',
  AG_HOTEL_SELECT_INITIATED: ANALYTICS_PREFIX + 'hotel_select_initiated',
  AG_HOTEL_SELECT_COMPLETED: ANALYTICS_PREFIX + 'hotel_select_completed',
  AG_HOTEL_SELECT_FAILED: ANALYTICS_PREFIX + 'hotel_select_failed',
  AG_HOTEL_BOOKING_INITIATED: ANALYTICS_PREFIX + 'AG_HOTEL_BOOKING_INITIATED',
  AG_HOTEL_BOOKING_COMPLETED: ANALYTICS_PREFIX + 'AG_HOTEL_BOOKING_COMPLETED',
  AG_HOTEL_BOOKING_FAILED: ANALYTICS_PREFIX + 'AG_HOTEL_BOOKING_FAILED',

  AG_HOTEL_CONFIRM_BOOKING_INITIATED:
    ANALYTICS_PREFIX + 'AG_HOTEL_CONFIRM_BOOKING_INITIATED',
  AG_HOTEL_CONFIRM_BOOKING_COMPLETED:
    ANALYTICS_PREFIX + 'AG_HOTEL_CONFIRM_BOOKING_COMPLETED',
  AG_HOTEL_CONFIRM_BOOKING_FAILED:
    ANALYTICS_PREFIX + 'AG_HOTEL_CONFIRM_BOOKING_FAILED',

  AG_HOTEL_ISSUE_BOOKING_INITIATED:
    ANALYTICS_PREFIX + 'AG_HOTEL_ISSUE_BOOKING_INITIATED',
  AG_HOTEL_ISSUE_BOOKING_COMPLETED:
    ANALYTICS_PREFIX + 'AG_HOTEL_ISSUE_BOOKING_COMPLETED',
  AG_HOTEL_ISSUE_BOOKING_FAILED:
    ANALYTICS_PREFIX + 'AG_HOTEL_ISSUE_BOOKING_FAILED',

  AG_MY_BOOKINGS_DOWNLOAD_SINGLE_FLIGHT_BOOKING:
    ANALYTICS_PREFIX + 'my_bookings_download_single_flight_booking',
  AG_DASHBOARD_SEARCH_TAB_CHANGE:
    ANALYTICS_PREFIX + 'dashboard_search_tab_change',
  AG_DASHBOARD_SEARCH_FLIGHT: ANALYTICS_PREFIX + 'dashboard_search_flight',
  AG_DASHBOARD_FETCH_INSIGHTS: ANALYTICS_PREFIX + 'dashboard_fetch_insights',
  AG_DASHBOARD_SEARCH_HOTEL: ANALYTICS_PREFIX + 'dashboard_search_hotel',
  AG_FARE_CALCULATOR_APPLIED: ANALYTICS_PREFIX + 'fare_calculator_applied',
  AG_RESET_FARE_CALCULATOR_APPLIED:
    ANALYTICS_PREFIX + 'reset_fare_calculator_applied',
  AG_FLIGHT_SEARCH_APPLIED: ANALYTICS_PREFIX + 'flight_search_applied',
  AG_FLIGHT_SEARCH_RESULTS: ANALYTICS_PREFIX + 'flight_search_results',
  AG_FLIGHT_CALENDER_CHIP_SEARCH:
    ANALYTICS_PREFIX + 'flight_calender_chip_search',
  AG_FLIGHT_FILTER_APPLIED: ANALYTICS_PREFIX + 'flight_filter_applied',
  AG_FLIGHT_FARE_CALCULATOR_APPLIED:
    ANALYTICS_PREFIX + 'flight_fare_calculator_applied',
  AG_FLIGHT_DETAIL_CARD_OPENED: ANALYTICS_PREFIX + 'flight_detail_card_opened',
  AG_FLIGHT_RBD_BTN_CLICKED: ANALYTICS_PREFIX + 'flight_rbd_btn_clicked',
  AG_FLIGHT_RBD_SELECTED: ANALYTICS_PREFIX + 'flight_rbd_btn_selected',
  AG_FLIGHT_FARE_OPTION_CONFIRMED:
    ANALYTICS_PREFIX + 'flight_fare_option_confirmed',
  AG_FLIGHT_BOOKING_ISSUANCE: ANALYTICS_PREFIX + 'flight_booking_issuance',
  AG_AIRLINE_TICKET_DIALOG_OPENED:
    ANALYTICS_PREFIX + 'airline_ticket_dialog_open',
  AG_AIRLINE_TICKET_SENT_VIA_DIALOG:
    ANALYTICS_PREFIX + 'airline_ticket_sent_via_dialog',
  AG_FLIGHT_QUICK_SEARCH_CHIP_CLICK:
    ANALYTICS_PREFIX + 'flight_quick_search_chip_click',
  AG_FLIGHT_CHANGE_SEARCH_DIALOG_OPENED:
    ANALYTICS_PREFIX + 'flight_change_search_dialog_opened',
  AG_PAYMENTS_CREATE_PAYMENT: ANALYTICS_PREFIX + 'payments_create_payment',
  AG_PAYMENTS_CREATE_PAYMENT_SUCCESS:
    ANALYTICS_PREFIX + 'payments_create_payment_success',
  AG_PAYMENTS_CREATE_PAYMENT_FAILURE:
    ANALYTICS_PREFIX + 'payments_create_payment_failure',
  AG_PAYMENTS_PAYMENT_LIST_VIEW:
    ANALYTICS_PREFIX + 'payments_payment_list_view',
  AG_PAYMENTS_PAYMENT_DETAIL_VIEW:
    ANALYTICS_PREFIX + 'payments_payment_detail_view',
  AG_VIEW_FEATURED_NEWS: ANALYTICS_PREFIX + 'view_featured_news',
  AG_CLICK_FEATURED_NEWS: ANALYTICS_PREFIX + 'click_featured_news',
  AG_FIT_REVIEW_DIALOG_SUBMIT_REQUEST:
    ANALYTICS_PREFIX + 'fit_review_dialog_submit_request',
  AG_FARE_BREAKDOWN_VIEW: ANALYTICS_PREFIX + 'fare_breakdown_view',
  AG_CANCEL_FLIGHT_BOOKING: ANALYTICS_PREFIX + 'cancel_flight_booking',
};

export const EVENT_KEY_USER_ROLE: string = 'user_role';
export const EVENT_KEY_NAV_ITEM_NAME: string = 'nav_item_name';
// export const EVENT_PROPERTY_KEY = {
//   NAV_ITEM_NAME: 'nav_item_name',
// };

export const PAGE = {
  LOGIN: 'Login',
  DASHBOARD: 'Dashboard',
  SOA: 'SOA',
  MY_BOOKINGS: 'My Bookings',
  BOOKING_DETAILS: 'Booking Details',
  ISSUE_TICKET: 'Issue Ticket',
  AGENT_PRICING: 'Agent Pricing',
  AGENT_LIST: 'Agent List',
  CREATE_USER: 'Create User',
  UPDATE_PROFILE: 'Update Profile',
  CHANGE_PASSWORD: 'Change Password',
  AGENT_NEWS: 'Agent News',
  BUS: 'Bus',
  OTP_MANAGEMENT: 'OTP Management',
  HOTEL: 'Hotel',
  CREDIT_LIMIT_MANAGEMENT: 'Credit Limit Management',
  CREATE_CREDIT_LIMIT_REQUEST: 'Create Credit Limit Request',
  AGENT_CREDIT_LIMIT_REQUESTS: 'Agent Credit Limit Requests',
  CREDIT_LIMIT_REQUEST_APPROVAL: 'Credit Limit Request Approval',
};
