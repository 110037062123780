import {
  FETCH_TOURS,
  FETCH_TOURS_SUCCESS,
  FETCH_TOURS_FAILED,
  FETCH_TOUR,
  FETCH_TOUR_SUCCESS,
  FETCH_TOUR_FAILURE,
} from '../constants/tours';
import { Action, ToursReducer } from '../types';

const toursInitialState: ToursReducer = {
  tourDetailUiState: {
    showLoading: false,
    tour: {
      booking_options: [],
      start_date: new Date(),
      end_date: new Date(),
      inclusions: [],
      tour_rules: [],
      images: [],
      main_image: '',
      short_description: '',
      items: [],
      title: '',
      tour_type: '',
      max_pax: 0,
      tour_id: '',
      max_pax_per_booking: 0,
      passenger_list: [],
      passengers: [],
      selected_option: {
        option_name: '',
        option_price: 0,
        option_rules: [],
        tour_option_id: '',
        option_currency: '',
        option_max_quantity: 0,
        option_short_description: '',
        option_remaining_quantity: 0,
      },
      additional_documents: [],
      passport_required: false,
      number_of_guest: 0,
      total_fare: '',
    },
  },
  tourListUiState: { showLoading: true, tours: [] },
};

const toursReducer = (
  state = toursInitialState,
  action: Action
): ToursReducer => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case FETCH_TOURS: {
      return {
        ...state,
        tourListUiState: {
          ...state.tourListUiState,
          showLoading: true,
        },
      };
    }
    case FETCH_TOURS_SUCCESS: {
      return {
        ...state,
        tourListUiState: {
          showLoading: false,
          tours: payload.data,
        },
      };
    }
    case FETCH_TOURS_FAILED: {
      return {
        ...state,
        tourListUiState: {
          tours: [],
          showLoading: false,
        },
      };
    }
    case FETCH_TOUR: {
      return {
        ...state,
        tourDetailUiState: {
          ...state.tourDetailUiState,
          showLoading: true,
        },
      };
    }
    case FETCH_TOUR_SUCCESS: {
      const tour = payload.data;
      tour.tour_rules = [
        'Above rates does not include taxes and YQ which shall be payable by the seat purchaser',
        'Above rates are non-commissionable',
        'For booking of each set of 10 seats, only 01 Child seat would be offered',
        'ROE for the date of issuance (as per BSP) would apply on the seats',
      ];
      return {
        ...state,
        tourDetailUiState: {
          showLoading: false,
          tour: tour,
        },
      };
    }
    case FETCH_TOUR_FAILURE: {
      return {
        ...state,
        tourDetailUiState: {
          showLoading: false,
          tour: toursInitialState.tourDetailUiState.tour,
        },
      };
    }
    default:
      return state;
  }
};

export default toursReducer;
