import { create, ApiResponse } from 'apisauce';
import { AGResponse } from 'src/types';
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setInLocalStorage,
} from 'src/utils/helpers';
import { PATHS } from 'src/routes/routesConfig';
import { ACCESS_TOKEN, REFRESH_TOKEN, USER } from 'src/constants';

const refreshTokenEndPoint = '/auth/token/refresh/';

type Request = {
  calledUrl: string;
  dataTobePost: Object | null;
  response: ApiResponse<any> | null;
  method: 'GET' | 'POST' | 'PATCH' | null;
  fetchGetUrl: string | null;
};

const apiService = {
  apiSauceInstance: create({
    baseURL: process.env.REACT_APP_AG_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 240000,
  }),
  handleReponse,
  get,
  post,
  patch,
  fetchGet,
  execute,
};

let request: Request = {
  calledUrl: '',
  dataTobePost: null,
  response: null,
  method: null,
  fetchGetUrl: null,
};

apiService.apiSauceInstance.addRequestTransform(request => {
  const token = getFromLocalStorage(ACCESS_TOKEN);
  if (token) {
    request.headers['Authorization'] = `Bearer ${token}`;
  }
});

async function get(url: string) {
  updateRequest(url, 'GET', null);
  const getResponse = await apiService.apiSauceInstance.get(url);
  request.response = getResponse;
  return apiService.handleReponse();
}

async function fetchGet(url) {
  const token = getFromLocalStorage(ACCESS_TOKEN);
  const headers = { Authorization: `Bearer ${token}` };
  request.fetchGetUrl = url;
  const response = await fetch(url, { headers });
  //@ts-ignore
  request.response = response;

  return apiService.handleReponse(true);
}

async function post(url: string, data: Object) {
  updateRequest(url, 'POST', data);
  const postResponse = await apiService.apiSauceInstance.post(url, data);
  request.response = postResponse;
  return apiService.handleReponse();
}

async function patch(url: string, data: Object) {
  updateRequest(url, 'PATCH', data);
  const patchResponse = await apiService.apiSauceInstance.patch(url, data);
  request.response = patchResponse;
  return apiService.handleReponse();
}

async function handleReponse(isFetchGet?): Promise<AGResponse> {
  const mutatedReponse = {
    ok: request?.response?.ok,
    statusCode: request?.response?.status,
  };

  if (request?.response?.status === 401) {
    //  If access token or refresh token does not exist then return to login screen
    const refreshToken = getFromLocalStorage(REFRESH_TOKEN);
    const accessToken = getFromLocalStorage(ACCESS_TOKEN);
    if (!refreshToken || !accessToken) {
      logoutAndRedirect();
    } else {
      // To check if access token is expired, if it is expired then get the refresh the tokens
      const data = {
        refresh: refreshToken,
      };

      const refreshTokenResponse: ApiResponse<any> =
        await apiService.apiSauceInstance.post(refreshTokenEndPoint, data);

      if (refreshTokenResponse.status === 401) {
        // while refreshing the tokens if refresh token is also expired then logout and redirect the user
        logoutAndRedirect();
      } else {
        // if successfully refreshes the tokens and update the tokens and call again the previous call
        setInLocalStorage(ACCESS_TOKEN, refreshTokenResponse.data.access);
        setInLocalStorage(REFRESH_TOKEN, refreshTokenResponse.data.refresh);
        if (isFetchGet) {
          await apiService.fetchGet(request.fetchGetUrl);
        } else {
          switch (request?.method) {
            case 'GET':
              const getResponse = await apiService.apiSauceInstance.get(
                request.calledUrl
              );
              request.response = getResponse;
              break;
            case 'POST':
              const postResponse = await apiService.apiSauceInstance.post(
                request.calledUrl,
                request.dataTobePost
              );
              request.response = postResponse;
              break;
          }
        }
      }
    }
  }

  if (request?.response?.ok) {
    if (isFetchGet) {
      //@ts-ignore
      return { ...mutatedReponse, data: request?.response };
    }
    return { ...mutatedReponse, data: request?.response.data };
  } else if (request.response?.status !== 500) {
    return {
      ...mutatedReponse,
      data: request?.response?.data,
    };
  } else {
    return {
      ...mutatedReponse,
      data: request?.response?.data
        ? {
            message:
              request?.response?.data?.message || 'Something went wrong!',
            success: false,
            status: 101,
          }
        : null,
    };
  }
}

async function execute(config: any) {
  updateRequest(config.url, config.method, config.data);
  const apiResponse = await apiService.apiSauceInstance.any(config);
  request.response = apiResponse;
  return apiService.handleReponse();
}

function logoutAndRedirect() {
  removeFromLocalStorage(USER);
  removeFromLocalStorage(ACCESS_TOKEN);
  removeFromLocalStorage(REFRESH_TOKEN);
  window.location.href =
    process.env.REACT_APP_BASE_URL +
    PATHS.login +
    '?redirect=' +
    window.location.pathname +
    window.location.search;
}

function updateRequest(
  url: string,
  method: 'GET' | 'POST' | 'PATCH',
  data: Object | null
) {
  request = { ...request, calledUrl: url, dataTobePost: data, method: method };
}

export default apiService;
