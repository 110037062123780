import React from 'react';

type ToolbarContextProps = {
  actions: Object;
  setActions: Function;
  setCurrentPathName: Function;
};

const initialValue = {
  actions: {},
  setActions: () => {},
  setCurrentPathName: pathName => {},
};

const ToolbarContext = React.createContext<ToolbarContextProps>(initialValue);

const ToolbarProvider = props => {
  const [actions, setActions] = React.useState<Object>(initialValue.actions);
  const [currentPathName, setCurrentPathName] = React.useState<string>('');
  const _setActions = actions => {
    const newValue = {};
    newValue[currentPathName] = actions;
    setActions({
      ...actions,
      ...newValue,
    });
  };

  const _setCurrentPathName = pathName => setCurrentPathName(pathName);

  return (
    <ToolbarContext.Provider
      value={{
        actions: actions,
        setActions: _setActions,
        setCurrentPathName: _setCurrentPathName,
      }}
    >
      {props.children}
    </ToolbarContext.Provider>
  );
};

const useToolbar = () => {
  const context = React.useContext(ToolbarContext);

  if (!context) {
    throw new Error('useLoading() must be used within LoadingProvider');
  }

  return context;
};

export default ToolbarProvider;

export { useToolbar };
