import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_DASHBOARD_DATA,
  FETCH_BOOKING_EXPIRY,
} from '../constants/dashboardData';
import {
  getDashoardDataService,
  getBookingExpiryService,
} from '../../services/dashboardDataService';
import {
  fetchDashboardDataFailed,
  fetchDashboardDataFullfilled,
  fetchBookingExpiryFailed,
  fetchBookingExpiryFullfilled,
} from '../actions/dashboardData';

function* fetchDashboardData(action) {
  try {
    const response = yield call(getDashoardDataService, action.payload);
    console.log({ response });
    if (response.ok && response.data?.success) {
      yield put(fetchDashboardDataFullfilled(response.data.data));
    } else {
      yield put(fetchDashboardDataFailed());
    }
  } catch (err) {
    yield put(fetchDashboardDataFailed());
  }
}

function* fetchBookingExpiry() {
  try {
    const response = yield call(getBookingExpiryService);
    if (response.ok && response.data?.success) {
      yield put(fetchBookingExpiryFullfilled(response.data.data));
    } else {
      yield put(fetchBookingExpiryFailed());
    }
  } catch (err) {
    yield put(fetchBookingExpiryFailed());
  }
}

export function* fetchDashboardDataWatcher() {
  yield takeLatest(FETCH_DASHBOARD_DATA, fetchDashboardData);
}

export function* fetchBookingExpiryWatcher() {
  yield takeLatest(FETCH_BOOKING_EXPIRY, fetchBookingExpiry);
}
