import {
  // RESERVE_BOOKING,
  // RESERVE_BOOKING_SUCCESS,
  // RESERVE_BOOKING_FAILED,
  INITIATE_BOOKING_FLIGHT,
  INITIATE_BOOKING_HOTEL,
  INITIATE_BOOKING_FAILED,
  INITIATE_BOOKING_SUCCESS,
  EMPTY_ACTIVE_BOOKINGS,
  FETCH_BOOKING_SUCCESS,
  FETCH_BOOKING_FAILED,
  FETCH_BOOKING,
  CONFIRM_BOOKING_HOTEL,
  CONFIRM_BOOKING_SUCCESS,
  CONFIRM_BOOKING_FAILED,
  ISSUE_BOOKING_SUCCESS,
  ISSUE_BOOKING_FAILED,
  ISSUE_BOOKING_HOTEL,
  FETCH_ACTIVE_BOOKING,
  FETCH_ACTIVE_BOOKING_SUCCESS,
  FETCH_ACTIVE_BOOKING_FAILED,
  INITIATE_BOOKING_TOUR,
  CONFIRM_BOOKING_TOUR,
  DOWNLOAD_BOOKING_COMPLETED,
  DOWNLOAD_BOOKING,
  GET_AIRLINE_TICKETS,
  GET_AIRLINE_TICKETS_SUCCESS,
  GET_AIRLINE_TICKETS_FAILED,
  CONFIRM_DUMMY_BOOKING_TOUR,
  CANCEL_FLIGHT_BOOKING,
  CANCEL_FLIGHT_BOOKING_FAILED,
  CANCEL_FLIGHT_BOOKING_SUCCESS,
} from './../constants/booking';

// export const reserveBooking = payload => ({
//   type: RESERVE_BOOKING,
//   payload,
// });

// export const reserveBookingFulfilled = payload => ({
//   type: RESERVE_BOOKING_SUCCESS,
//   payload,
// });
//
// export const reserveBookingFailed = () => ({
//   type: RESERVE_BOOKING_FAILED,
// });

export const initiateBooking = payload => ({
  type: INITIATE_BOOKING_FLIGHT,
  payload,
});

export const initiateBookingHotel = payload => ({
  type: INITIATE_BOOKING_HOTEL,
  payload,
});

export const initiateBookingTour = payload => ({
  type: INITIATE_BOOKING_TOUR,
  payload,
});

export const initiateBookingFulfilled = payload => ({
  type: INITIATE_BOOKING_SUCCESS,
  payload,
});

export const initiateBookingFailed = payload => ({
  type: INITIATE_BOOKING_FAILED,
  payload,
});

export const confirmBookingHotel = payload => ({
  type: CONFIRM_BOOKING_HOTEL,
  payload,
});

export const confirmBookingTour = payload => ({
  type: CONFIRM_BOOKING_TOUR,
  payload,
});

export const confirmDummyBookingTour = payload => ({
  type: CONFIRM_DUMMY_BOOKING_TOUR,
  payload,
});

export const confirmBookingFulfilled = payload => ({
  type: CONFIRM_BOOKING_SUCCESS,
  payload,
});

export const confirmBookingFailed = () => ({
  type: CONFIRM_BOOKING_FAILED,
});

export const issueBookingHotel = payload => ({
  type: ISSUE_BOOKING_HOTEL,
  payload,
});

export const issueBookingFulfilled = payload => ({
  type: ISSUE_BOOKING_SUCCESS,
  payload,
});

export const issueBookingFailed = () => ({
  type: ISSUE_BOOKING_FAILED,
});

export const emptyActiveBookings = () => ({
  type: EMPTY_ACTIVE_BOOKINGS,
});

export const fetchBookings = payload => ({
  type: FETCH_BOOKING,
  payload,
});

export const fetchBookingFulfilled = payload => ({
  type: FETCH_BOOKING_SUCCESS,
  payload,
});

export const fetchBookingFailed = () => ({
  type: FETCH_BOOKING_FAILED,
});

export const fetchActiveBooking = payload => ({
  type: FETCH_ACTIVE_BOOKING,
  payload,
});

export const fetchActiveBookingFulfilled = payload => ({
  type: FETCH_ACTIVE_BOOKING_SUCCESS,
  payload,
});

export const fetchActiveBookingFailed = () => ({
  type: FETCH_ACTIVE_BOOKING_FAILED,
});

export const downloadBooking = booking_id => ({
  type: DOWNLOAD_BOOKING,
  payload: booking_id,
});

export const downloadBookingCompleted = () => ({
  type: DOWNLOAD_BOOKING_COMPLETED,
});

export const getAirlineTickets = payload => ({
  type: GET_AIRLINE_TICKETS,
  payload,
});

export const getAirlineTicketsFailed = payload => ({
  type: GET_AIRLINE_TICKETS_FAILED,
  payload,
});

export const getAirlineTicketsCompleted = () => ({
  type: GET_AIRLINE_TICKETS_SUCCESS,
});

export const cancelFlightBooking = payload => ({
  type: CANCEL_FLIGHT_BOOKING,
  payload,
});

export const cancelFlightBookingCompleted = () => ({
  type: CANCEL_FLIGHT_BOOKING_SUCCESS,
});

export const cancelFlightBookingFailed = payload => ({
  type: CANCEL_FLIGHT_BOOKING_FAILED,
  payload,
});
