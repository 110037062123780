import { HTTP_METHODS } from 'src/enums/http-methods';
import apiService from './api';

export const getPaymentService = page => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: `/v1/payments/`,
    params: {
      page_no: page,
    },
  });
};

export const createPaymentService = payload => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: '/v1/payments/',
    data: payload?.data,
  });
};

export const getPaymentByIdService = (id: string) => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: `/v1/payments/${id}`,
  });
};

export const updatePaymentService = (payload: any) => {
  return apiService.execute({
    method: HTTP_METHODS.PATCH,
    url: `/v1/payments/${payload?.id}/`,
    data: payload?.data,
  });
};
