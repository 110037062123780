export const FETCH_ORGANIZATIONS = 'FETCH_ORGANIZATIONS';
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
export const FETCH_ORGANIZATIONS_FAILED = 'FETCH_ORGANIZATIONS_FAILED';

export const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION';
export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS';
export const FETCH_ORGANIZATION_FAILED = 'FETCH_ORGANIZATION_FAILED';

export const RESET_ORGANIZATION = 'RESET_ORGANIZATION';
export const HANDLE_ORGANIZATION = 'HANDLE_ORGANIZATION';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAILED = 'UPDATE_ORGANIZATION_FAILED';

export const CHANGE_AGENT_STATUS = 'CHANGE_AGENT_STATUS';
export const CHANGE_AGENT_STATUS_FAILED = 'CHANGE_AGENT_STATUS_FAILED';
export const CHANGE_AGENT_STATUS_SUCCESS = 'CHANGE_AGENT_STATUS_SUCCESS';

export const ADD_AGENT = 'ADD_AGENT';
export const ADD_AGENT_SUCCESS = 'ADD_AGENT_SUCCESS';
export const ADD_AGENT_FAILED = 'ADD_AGENT_FAILED';

export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAILED = 'CREATE_ORGANIZATION_FAILED';
