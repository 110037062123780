import { ToursUrls } from '../../routes/Routes';

export enum TourType {
  umrah = 'UMRAH',
  ziarat = 'ZIARAT',
  flights = 'FLIGHTS_ONLY',
}

export function parseAsRequestType(type: string): Array<TourType> {
  if (TourType[type] === TourType.umrah) {
    return [TourType.umrah, TourType.ziarat];
  }
  return [TourType.flights];
}

export function getTourDetailUrl(tourType: string, tourId: string) {
  if (tourType === TourType.flights) {
    return ToursUrls.FDGFlightsDetail(tourId);
  }
  return ToursUrls.FDGUmrahDetail(tourId);
}
