import React from 'react';

import { USER_ROLES } from 'src/constants';
import { PERMISSIONS_ENUM } from 'src/enums/permissions';
import MyBookingRoutes, {
  MyBookingPaths,
} from '../pages/MyBookings/routes/Routes';
import ToursRoutes, {
  ToursHeading,
  ToursPaths,
} from '../containers/Umrah/routes/Routes';
import IssueTicketRoutes, {
  IssueTicketHeading,
  IssueTicketPaths,
} from '../pages/NewIssueTicket/routes/Routes';
import FlightsV2Routes, {
  FlightsV2Heading,
  FlightsV2Paths,
} from '../pages/FlightsV2/routes/Routes';
import FITRoutes, { FITHeading, FITPaths } from '../pages/FIT/routes/Routes';
const MiniDrawer = React.lazy(
  () => import('src/containers/HomeLayout/HomeLayout')
);
const Search = React.lazy(() => import('src/pages/Search'));

const BookingConfirmation = React.lazy(
  () => import('src/pages/BookingConfirmation')
);

const MyBookings = React.lazy(() => import('src/pages/MyBookings'));

const UpdateOrganization = React.lazy(
  () => import('src/pages/Organizations/UpdateOrganization')
);
const CreateOrganization = React.lazy(
  () => import('src/pages/Organizations/CreateOrganization')
);
const Organizations = React.lazy(() => import('src/pages/Organizations'));
const Organization = React.lazy(() => import('src/components/Organization'));
const OtpManagement = React.lazy(() => import('../pages/OtpManagement'));
const TheLayout = React.lazy(
  () => import('../containers/HomeLayout/HomeLayout')
);
const Login = React.lazy(() => import('../pages/Auth/Login'));
const ForgotPassword = React.lazy(() => import('../pages/Auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../pages/Auth/ResetPassword'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const SOA = React.lazy(() => import('../pages/SOA'));
const AgentPricing = React.lazy(() => import('../pages/AgentPricing'));
const AgentList = React.lazy(() => import('../pages/AgentsList'));
const CreateUser = React.lazy(() => import('../pages/CreateUser'));
const UpdateProfile = React.lazy(() => import('../pages/UpdateProfile'));

const ChangePassword = React.lazy(
  () => import('../pages/UpdateProfile/ChangePassword')
);
const AgentNews = React.lazy(() => import('../pages/AgentNews'));

const bus = React.lazy(() => import('../pages/Bus'));
const Hotel = React.lazy(() => import('../pages/HotelNew'));
const agentLimitManagement = React.lazy(
  () => import('../pages/AgentLimitsManagement')
);
const createLimitRequest = React.lazy(
  () => import('../pages/AgentLimitsManagement/CreateLimitRequest')
);
const salesLimitManagement = React.lazy(
  () => import('../pages/SalesUserLimitManagement')
);

const approveCreditLimitReq = React.lazy(
  () => import('../pages/SalesUserLimitManagement/ApproveCreditLimitRequest')
);

const hotelRoomDetail = React.lazy(
  () => import('../pages/HotelNew/HotelRoomDetail')
);
export const HOTEL_MODULE_PREFIX = 'hotels';
export const TOUR_MODULE_PREFIX = 'tours';
const PaymentManagement = React.lazy(
  () => import('../pages/PaymentManagement')
);

const CreatePayment = React.lazy(
  () => import('../pages/PaymentManagement/CreatePayment')
);

const HotelInitiateBooking = React.lazy(
  () => import('../pages/HotelNew/HotelBooking')
);

const TourInitiateBooking = React.lazy(
  () => import('../pages/Tours/TourBooking')
);

const managementURL: string = 'https://management.agent.aeroglobe.pk';
const aeroglobeTutorialURL: string = 'https://aeroglobe.carrd.co/';

export const PATHS = {
  myBookings: '/my-bookings',
  search: '/:type/search',
  hotelsResult: `/${HOTEL_MODULE_PREFIX}/search/result`,
  searchFlights: '/flights/search',
  searchHotels: `/${HOTEL_MODULE_PREFIX}/search`,
  bookingConfirmation: '/booking-confirmation/:booking_id',
  login: '/login',
  home: '/',
  dashboard: '/dashboard',
  soa: '/soa',
  agentPricing: '/pricing-list',
  agentList: '/agent-list',
  createUser: '/add-user',
  updateProfile: '/update-profile',
  changePassword: '/update-profile/change-password',
  agentNews: '/news',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  bus: '/bus',
  otpManagement: '/otp-management',
  hotel: `/${HOTEL_MODULE_PREFIX}`,
  hotelRoomDetail: `/${HOTEL_MODULE_PREFIX}/property`,
  hotelInitiateBooking: `/${HOTEL_MODULE_PREFIX}/booking/:booking_id`,
  tourInitiateBooking: `/${TOUR_MODULE_PREFIX}/booking/:booking_id`,
  agentLimitRequestManagement: '/agent-credit-limit-management',
  agentLimitRequest:
    '/agent-credit-limit-management/create-credit-limit-request',
  salesLimitManagement: '/sales-credit-limit-management',
  approveCreditLimitReqs: '/sales-credit-limit-management/request-approval',
  paymentManagement: '/payments',
  paymentDetail: '/payments/detail',
  createPayment: '/payments/create',
  organizations: '/organizations',
  organization: '/organizations/:id',
  updateOrganization: '/organizations/update/:id',
  createOrganization: '/organizations/create',
  organizationManagement: `${managementURL}/organization/preference`,
  aeroglobeTutorials: aeroglobeTutorialURL,
  ...ToursPaths,
  ...MyBookingPaths,
  ...IssueTicketPaths,
  ...FlightsV2Paths,
  ...FITPaths,
};

export const PATH_HEADINGS = {
  [PATHS.search]: 'Search',
  [PATHS.searchFlights]: 'Flights',
  [PATHS.searchHotels]: 'Hotels',
  [PATHS.myBookings]: 'My Bookings',
  [PATHS.bookingConfirmation]: 'Booking Confirmation',
  [PATHS.login]: 'Login',
  [PATHS.dashboard]: 'Dashboard',
  [PATHS.soa]: 'SOA',
  [PATHS.agentPricing]: 'Agent Pricing',
  [PATHS.agentList]: 'Agent List',
  [PATHS.createUser]: 'Create User',
  [PATHS.updateProfile]: 'Update Profile',
  [PATHS.changePassword]: 'Change Password',
  [PATHS.agentNews]: 'Agent News',
  [PATHS.bus]: 'Bus',
  [PATHS.otpManagement]: 'OTP Management',
  [PATHS.organizations]: 'Organizations',
  [PATHS.updateOrganization]: 'Update Organization',
  [PATHS.createOrganization]: 'Create Organization',
  [PATHS.organization]: 'Organization',
  [PATHS.hotel]: 'Hotels',
  [PATHS.hotelsResult]: 'Hotels',
  [PATHS.hotelRoomDetail]: 'Hotels',
  [PATHS.hotelInitiateBooking]: 'Hotels',
  [PATHS.tourInitiateBooking]: 'Tours',
  [PATHS.agentLimitRequestManagement]: 'Credit Limit Management',
  [PATHS.agentLimitRequest]: 'Create Credit Limit Request',
  [PATHS.salesLimitManagement]: 'Agent Credit Limit Requests',
  [PATHS.approveCreditLimitReqs]: 'Credit Limit Request Approval',
  [PATHS.paymentManagement]: 'Payment Management',
  [PATHS.createPayment]: 'Payment Management',
  [PATHS.paymentDetail]: 'Payment Management',
  [PATHS.organizationManagement]: 'Organization Management',
  [PATHS.aeroglobeTutorials]: 'Aeroglobe Tutorials',
  ...ToursHeading,
  ...IssueTicketHeading,
  ...FlightsV2Heading,
  ...FITHeading,
};

export default {
  mainRoutes: [
    {
      path: PATHS.login,
      component: Login,
      private: false,
    },
    {
      path: PATHS.forgotPassword,
      component: ForgotPassword,
      private: false,
    },
    {
      path: PATHS.resetPassword,
      component: ResetPassword,
      private: false,
    },
    {
      path: '/demo',
      component: MiniDrawer,
      private: true,
    },
    {
      path: PATHS.home,
      component: TheLayout,
      private: true,
    },
  ],
  homeRoutes: [
    {
      path: PATHS.search,
      component: Search,
      private: true,
      permission: [PERMISSIONS_ENUM.CAN_VIEW_SEARCH_BOOK],
      exact: true,
      role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
    },
    {
      path: PATHS.searchFlights,
      component: Search,
      private: true,
      exact: true,
      role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
    },
    {
      path: PATHS.searchHotels,
      component: Search,
      private: true,
      exact: true,
      role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
    },
    {
      path: PATHS.hotelsResult,
      component: Hotel,
      private: true,
      exact: true,
      role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
    },
    {
      path: PATHS.bookingConfirmation,
      component: BookingConfirmation,
      private: true,
      exact: true,
      role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
    },
    {
      path: PATHS.myBookings,
      component: MyBookings,
      private: true,
      exact: true,
      role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
    },
    {
      path: PATHS.dashboard,
      component: Dashboard,
      private: true,
      exact: true,
      role: [
        USER_ROLES.superAdmin,
        USER_ROLES.finance,
        USER_ROLES.operations,
        USER_ROLES.agent,
        USER_ROLES.agSuperUser,
      ],
    },
    {
      path: PATHS.soa,
      component: SOA,
      private: true,
      exact: true,
      role: [
        USER_ROLES.finance,
        USER_ROLES.agent,
        USER_ROLES.agSuperUser,
        USER_ROLES.sales,
      ],
    },
    //TODO: removed path for all roles/users
    {
      path: PATHS.otpManagement,
      component: OtpManagement,
      private: true,
      exact: true,
      role: [],
    },
    {
      path: PATHS.organizations,
      component: Organizations,
      private: true,
      exact: true,
      role: [USER_ROLES.agSuperUser],
    },
    {
      path: PATHS.updateOrganization,
      component: UpdateOrganization,
      private: true,
      exact: true,
      role: [USER_ROLES.agSuperUser],
    },
    {
      path: PATHS.createOrganization,
      component: CreateOrganization,
      private: true,
      exact: true,
      role: [USER_ROLES.agSuperUser],
    },
    {
      path: PATHS.organization,
      component: Organization,
      private: true,
      exact: true,
      role: [USER_ROLES.agSuperUser],
    },
    {
      path: PATHS.agentPricing,
      component: AgentPricing,
      private: true,
      exact: true,
      role: [USER_ROLES.agent],
    },
    {
      path: PATHS.agentList,
      component: AgentList,
      private: true,
      exact: true,
      role: [
        USER_ROLES.agSuperUser,
        USER_ROLES.operations,
        USER_ROLES.finance,
        USER_ROLES.sales,
      ],
    },
    {
      path: PATHS.createUser,
      component: CreateUser,
      private: true,
      exact: true,
      role: [USER_ROLES.agSuperUser],
    },
    {
      path: PATHS.updateProfile,
      component: UpdateProfile,
      private: true,
      exact: true,
      role: [
        USER_ROLES.superAdmin,
        USER_ROLES.finance,
        USER_ROLES.operations,
        USER_ROLES.agent,
        USER_ROLES.agSuperUser,
        USER_ROLES.sales,
      ],
    },
    {
      path: PATHS.changePassword,
      component: ChangePassword,
      private: true,
      exact: true,
      role: [
        USER_ROLES.superAdmin,
        USER_ROLES.finance,
        USER_ROLES.operations,
        USER_ROLES.agent,
        USER_ROLES.agSuperUser,
        USER_ROLES.sales,
      ],
    },

    //TODO: removed path for all roles/users
    {
      path: PATHS.agentNews,
      component: AgentNews,
      private: true,
      exact: true,
      role: [],
    },
    {
      path: PATHS.bus,
      component: bus,
      private: true,
      exact: true,
      role: [USER_ROLES.agent],
    },
    {
      path: PATHS.agentLimitRequestManagement,
      component: agentLimitManagement,
      private: true,
      exact: true,
      role: [USER_ROLES.agent],
    },
    {
      path: PATHS.agentLimitRequest,
      component: createLimitRequest,
      private: true,
      exact: true,
      role: [USER_ROLES.agent],
    },
    {
      path: PATHS.salesLimitManagement,
      component: salesLimitManagement,
      private: true,
      exact: true,
      role: [USER_ROLES.sales, USER_ROLES.agSuperUser],
    },
    {
      path: `${PATHS.approveCreditLimitReqs}/:id`,
      component: approveCreditLimitReq,
      private: true,
      exact: true,
      role: [USER_ROLES.sales],
    },
    {
      path: `${PATHS.hotelRoomDetail}/:id`,
      component: hotelRoomDetail,
      private: true,
      exact: true,
      role: [USER_ROLES.agent],
    },
    {
      path: `${PATHS.hotelInitiateBooking}`,
      component: HotelInitiateBooking,
      private: true,
      exact: true,
      role: [USER_ROLES.agent],
    },
    {
      path: `${PATHS.tourInitiateBooking}`,
      component: TourInitiateBooking,
      private: true,
      exact: true,
      role: [USER_ROLES.agent],
    },
    {
      path: PATHS.paymentManagement,
      component: PaymentManagement,
      private: true,
      exact: true,
      role: [USER_ROLES.agent],
    },
    {
      path: PATHS.createPayment,
      component: CreatePayment,
      private: true,
      exact: true,
      role: [USER_ROLES.agent],
    },
    {
      path: `${PATHS.paymentDetail}/:id`,
      component: PaymentManagement,
      private: true,
      exact: true,
      role: [USER_ROLES.agent],
    },
    ...ToursRoutes,
    ...MyBookingRoutes,
    ...IssueTicketRoutes,
    ...FlightsV2Routes,
    ...FITRoutes,
  ],
};
