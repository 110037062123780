import {
  FETCH_SECTORS,
  FETCH_SECTORS_FAILED,
  FETCH_SECTORS_SUCCESS,
  RESET,
} from '../constants/sector';

export const resetSectors = () => {
  return {
    type: RESET,
  };
};

export const fetchSectors = () => ({
  type: FETCH_SECTORS,
});
export const fetchSectorsFullfilled = payload => ({
  type: FETCH_SECTORS_SUCCESS,
  payload,
});

export const fetchSectorsFailed = () => ({
  type: FETCH_SECTORS_FAILED,
});
