import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createOTPService,
  expireOTPService,
  getOTPService,
} from 'src/services/otpServices';
import {
  createOtpFailed,
  createOtpSuccess,
  expireOtpFailed,
  fetchOtpFailed,
  fetchOtpFullfilled,
} from '../actions/otp';
import store from '../configureStore';
import { CREATE_OTP, EXPIRE_OTP, FETCH_OTP } from '../constants/otp';

function* expireOTP(action) {
  try {
    const response = yield call(expireOTPService, action.payload);
    if (response.ok && response.data?.success) {
      const otp = store.getState().otp;
      yield fetchOTP({
        payload: otp.otpList.length <= 1 ? 1 : otp.current_page,
      });
    } else {
      yield put(expireOtpFailed());
    }
  } catch (err) {
    yield put(expireOtpFailed());
  }
}

function* fetchOTP(action) {
  try {
    const response = yield call(getOTPService, action.payload);
    if (response.ok && response.data?.success) {
      const { count, links, total_pages, results } =
        response?.data?.response || {};
      yield put(
        fetchOtpFullfilled({ count, links, total_pages, otpList: results })
      );
    } else {
      yield put(fetchOtpFailed());
    }
  } catch (err) {
    yield put(fetchOtpFailed());
  }
}

function* createOTP(action) {
  try {
    const response = yield call(createOTPService, action.payload);
    if (response.ok && response.data?.success) {
      yield put(createOtpSuccess(response.data?.response));
    } else {
      yield put(createOtpFailed());
    }
  } catch (err) {
    yield put(createOtpFailed());
  }
}

export function* expireOTPWatcher() {
  yield takeLatest(EXPIRE_OTP, expireOTP);
}

export function* fetchOTPWatcher() {
  yield takeEvery(FETCH_OTP, fetchOTP);
}

export function* createOTPWatcher() {
  yield takeLatest(CREATE_OTP, createOTP);
}
