import React from 'react';
import { USER_ROLES } from '../../../constants';

const FlightsV2 = React.lazy(() => import('../index'));

export const FlightsV2Paths = {
  Index: '/v2/flights/',
  SearchResults: '/v2/flights/search/result',
};

export const FlightsV2Heading = {
  [FlightsV2Paths.Index]: 'Flights',
  [FlightsV2Paths.SearchResults]: 'Flights Search Results',
};

const FlightsV2Routes = [
  {
    path: FlightsV2Paths.Index,
    component: FlightsV2,
    private: true,
    exact: true,
    role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
  },
  {
    path: FlightsV2Paths.SearchResults,
    component: FlightsV2,
    private: true,
    exact: true,
    role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
  },
];

export default FlightsV2Routes;
