import {
  Action,
  CombineReducerType,
  FlightReducer,
  FlightSearchQueryReducer,
} from './../types';
import * as _ from 'lodash';
import {
  fetchFlightsFullfilled,
  fetchFlightsFailed,
  updateFilteredData,
  updateFlightFilters,
} from './../actions/flight';
import { FETCH_FLIGHTS } from './../constants/flight';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getFlightsService } from 'src/services/flightServices';
import { FetchFlightsPayload, FlightOption } from '../../types';
import {
  calculatePriceRangeFilters,
  filterFlightsByAirlines,
  filterFlightsByPrice,
  filterFlightsByStops,
  getAirlineFilters,
  filterFlightsByDepartureTime,
  mapFlightAirport,
} from '../../containers/Flights/utils';
import { flightStopsFilters } from '../../containers/Flights/constants';
import { UPDATE_FLIGHT_SEARCH_QUERY_FILTERS } from '../constants/flightSearchQuery';
import { setCompleteSearchQuery } from '../actions/flightSearchQuery';
import { ROUTE_TYPE } from '../../enums/route-type';

function* fetchFlights(action: Action & { payload: FetchFlightsPayload }) {
  try {
    let formatedPayload = action.payload;
    if (action.payload.route_type === ROUTE_TYPE.MULTI_CITY) {
      formatedPayload = {
        ...formatedPayload,
        legs: action.payload.legs.map(x => ({
          ...x,
          origin: x.origin.iata_code,
          destination: x.destination.iata_code,
        })),
      };
    }

    const response = yield call(getFlightsService, formatedPayload);
    if (response.ok && response.data?.success) {
      const next_leg = response.data.data.journey_legs.length > 1 ? 1 : 0;

      yield put(
        setCompleteSearchQuery({
          ...action.payload,
          origin: mapFlightAirport(response.data.data.journey_legs[0].origin),
          destination: mapFlightAirport(
            response.data.data.journey_legs[0].destination
          ),
          departure_date: new Date(action.payload.departure_date),
          return_date: new Date(
            action.payload.return_date || action.payload.departure_date
          ),
          current_leg: 0,
          next_leg,
          total_legs: response.data.data.journey_legs.length,
          ...(action.payload.route_type === ROUTE_TYPE.MULTI_CITY && {
            multiCityLegs: action.payload.legs,
          }),
        })
      );
      yield put(fetchFlightsFullfilled(response.data?.data));
      yield put(
        updateFlightFilters({
          airlines: getAirlineFilters(
            response.data.data.journey_legs[0].flight_options
          ),
          stops: flightStopsFilters,
          priceRange: calculatePriceRangeFilters(
            response.data.data.journey_legs[0].flight_options
          ),
          departureTime: ['00-06', '06-12', '12-18', '18-24'],
        })
      );
    } else {
      yield put(fetchFlightsFailed());
    }
  } catch (err) {
    yield put(fetchFlightsFailed());
  }
}

function* filterFlights(action) {
  const flightSearchQueryState: FlightSearchQueryReducer = yield select(
    (state: CombineReducerType) => state.flightSearchQuery
  );
  let flightState: FlightReducer = yield select(
    (state: CombineReducerType) => state.flights
  );
  const filters = {
    ...flightSearchQueryState.filters,
    [action.payload.key]: action.payload.value,
  };
  let { journey_legs } = _.cloneDeep(flightState.data);
  let tempFilteredData: Array<FlightOption> = [];
  if (journey_legs.length) {
    let { flight_options } = journey_legs[flightSearchQueryState.current_leg];
    tempFilteredData = filterFlightsByPrice(flight_options, filters.priceRange);
    tempFilteredData = filterFlightsByAirlines(
      flight_options,
      filters.airlines
    );
    tempFilteredData = filterFlightsByStops(tempFilteredData, filters.stops);
    tempFilteredData = filterFlightsByDepartureTime(
      tempFilteredData,
      filters.departureTime
    );
  }
  yield put(updateFilteredData(tempFilteredData));
}

export function* fetchFlightsWatcher() {
  yield takeLatest(FETCH_FLIGHTS, fetchFlights);
}

export function* filterFLightsWatcher() {
  yield takeLatest(UPDATE_FLIGHT_SEARCH_QUERY_FILTERS, filterFlights);
}
