import {
  RESET,
  FETCH_APP_SETTINGS,
  FETCH_APP_SETTINGS_SUCCESS,
  FETCH_APP_SETTINGS_FAILED,
} from '../constants/appsettings';

export const resetAppSettings = () => ({
  type: RESET,
});

export const fetchAppSettings = () => ({
  type: FETCH_APP_SETTINGS,
});

export const fetchAppSettingsFullfilled = payload => ({
  type: FETCH_APP_SETTINGS_SUCCESS,
  payload,
});

export const fetchAppSettingsFailed = () => ({
  type: FETCH_APP_SETTINGS_FAILED,
});
