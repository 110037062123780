import { parseAsRequestType } from 'src/containers/Umrah/utils/enums/TourType';
import { HTTP_METHODS } from 'src/enums/http-methods';
import { TourEndPoints } from 'src/pages/HotelNew/constants';
import apiService from './api';

export const getToursService = (tourType: string) => {
  return apiService.execute({
    url: `${TourEndPoints.GET_ALL}?tour_type=${parseAsRequestType(tourType)}`,
    method: HTTP_METHODS.GET,
  });
};

export const getTourByIdService = (tour_id: string) => {
  return apiService.execute({
    url: `${TourEndPoints.GET_TOUR_DETAILS}${tour_id}`,
    method: HTTP_METHODS.GET,
  });
};
