import { PropertySelectedFilters } from 'src/redux/types';
import { Property } from 'src/pages/HotelNew/types';
import { HotelSearchSuggestion, HotelLocationType } from '../types';

export const formatErrorText = (err = '') => err.split('|')[1];

export const calculateMinMaxPricesOfProperties = (
  properties: Array<Property>
): Array<number> => {
  if (properties.length === 0) return [0, 0];
  const propertyPrices = properties.map(property => property.price);
  const minPrice = Math.floor(Math.min(...propertyPrices) / 1000) * 1000;
  const maxPrice = Math.ceil(Math.max(...propertyPrices) / 1000) * 1000;
  return [minPrice, maxPrice];
};

export const getPreHotelsSuggestions = (): HotelSearchSuggestion[] => {
  if (process.env.NODE_ENV === 'production') {
    return [
      {
        search_id: 'VW5V35IORWZ',
        display_name: 'Mecca',
        sub_display_name: 'Saudi Arabia',
        type: HotelLocationType.CITY,
        image:
          'https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcRcKsoWVDpgVvpMii-qap7G9ekBg8Tzo-YhNmA2uTHwU6c0V1MoKDcPljpoVp0-dDY8',
      },
      {
        search_id: 'VW5V366I4KF',
        display_name: 'Medina',
        sub_display_name: 'Saudi Arabia',
        type: HotelLocationType.CITY,
        image:
          'https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcTjYcVXBiM-9MuQnZK-QPLRQKozIBuKmTbolTbg0-D486S12vxYmYUwVkqtMjRJDo4W',
      },
      {
        search_id: 'VW5V35IORH8',
        display_name: 'Dubai',
        sub_display_name: 'United Arab Emirates',
        type: HotelLocationType.CITY,
        image:
          'https://t1.gstatic.com/licensed-image?q=tbn:ANd9GcTnavcE0R1cW9vbZSJU9A_92W41dYEqmnQRVz51osw9lq-dYLXMRz2uqIIL1LrWzvQN',
      },
      {
        search_id: 'VW5V350TQEL',
        display_name: 'Istanbul',
        sub_display_name: 'Turkey',
        type: HotelLocationType.CITY,
        image:
          'https://t3.gstatic.com/licensed-image?q=tbn:ANd9GcQMTvwtG7h0Qvf-Cb9yl9JomL5LGkuCdg9Y5Pu_K85MPufi-Mg9BWauCTjsTTey6qch',
      },
      {
        search_id: 'VW5V35IORM8',
        display_name: 'Baku',
        sub_display_name: 'Azerbaijan',
        type: HotelLocationType.CITY,
        image:
          'https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcQ0D_WKwN09neI6JL37-wxJEcqbhAPL8jaVi8OUrZlHEGGthPEYQZbm6OR2XVIwPhfz',
      },
      {
        search_id: 'VW5V34UVEH0',
        display_name: 'Kuala Lumpur',
        sub_display_name: 'Malaysia',
        type: HotelLocationType.CITY,
        image:
          'https://cdn.britannica.com/49/102749-050-B4874C95/Kuala-Lumpur-Malaysia.jpg',
      },
      {
        search_id: 'VW5V360H9SX',
        display_name: 'Phuket',
        sub_display_name: 'Thailand',
        type: HotelLocationType.CITY,
        image:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Phuket_Aerial.jpg/800px-Phuket_Aerial.jpg',
      },
      {
        search_id: 'VW5V35CQEJE',
        display_name: 'London',
        sub_display_name: 'United Kingdom',
        type: HotelLocationType.CITY,
        image:
          'https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcTF9OtLxnbxSpLFzyhzXERKiAbnHlG25WfRzxRTNcRiTk0lHvsOXNKH9KNAOvTWi_sS',
      },
      {
        search_id: 'VW5V35ON5MT',
        display_name: 'Denpasar',
        sub_display_name: 'Indonesia',
        type: HotelLocationType.CITY,
        image:
          'https://t2.gstatic.com/licensed-image?q=tbn:ANd9GcS1BWJs0qug7Cw0UQtD-4vLv_e_TzBdLftSukG8FSh_Vv_mFgh9irMws_LYErAuJvoX',
      },
      {
        search_id: 'VW5V35ULEKL',
        display_name: 'Tokyo',
        sub_display_name: 'Japan',
        type: HotelLocationType.CITY,
        image:
          'https://upload.wikimedia.org/wikipedia/commons/b/b2/Skyscrapers_of_Shinjuku_2009_January.jpg',
      },
    ];
  }
  return [
    {
      search_id: 'VWPHTJBEN2O',
      display_name: 'Mecca',
      sub_display_name: 'Saudi Arabia',
      type: HotelLocationType.CITY,
      image:
        'https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcRcKsoWVDpgVvpMii-qap7G9ekBg8Tzo-YhNmA2uTHwU6c0V1MoKDcPljpoVp0-dDY8',
    },
    {
      search_id: 'VWPHTITJN5S',
      display_name: 'Medina',
      sub_display_name: 'Saudi Arabia',
      type: HotelLocationType.CITY,
      image:
        'https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcTjYcVXBiM-9MuQnZK-QPLRQKozIBuKmTbolTbg0-D486S12vxYmYUwVkqtMjRJDo4W',
    },
    {
      search_id: 'VWPHTIZHYSK',
      display_name: 'Dubai',
      sub_display_name: 'United Arab Emirates',
      type: HotelLocationType.CITY,
      image:
        'https://t1.gstatic.com/licensed-image?q=tbn:ANd9GcTnavcE0R1cW9vbZSJU9A_92W41dYEqmnQRVz51osw9lq-dYLXMRz2uqIIL1LrWzvQN',
    },
    {
      search_id: 'VWPHTJBEMGF',
      display_name: 'Istanbul',
      sub_display_name: 'Turkey',
      type: HotelLocationType.CITY,
      image:
        'https://t3.gstatic.com/licensed-image?q=tbn:ANd9GcQMTvwtG7h0Qvf-Cb9yl9JomL5LGkuCdg9Y5Pu_K85MPufi-Mg9BWauCTjsTTey6qch',
    },
    {
      search_id: 'VWPHTJ5GAW4',
      display_name: 'Baku',
      sub_display_name: 'Azerbaijan',
      type: HotelLocationType.CITY,
      image:
        'https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcQ0D_WKwN09neI6JL37-wxJEcqbhAPL8jaVi8OUrZlHEGGthPEYQZbm6OR2XVIwPhfz',
    },
    {
      search_id: 'VWPHTITJMCR',
      display_name: 'Kuala Lumpur',
      sub_display_name: 'Malaysia',
      type: HotelLocationType.CITY,
      image:
        'https://cdn.britannica.com/49/102749-050-B4874C95/Kuala-Lumpur-Malaysia.jpg',
    },
    {
      search_id: 'VWPHTIBOUK4',
      display_name: 'Phuket',
      sub_display_name: 'Thailand',
      type: HotelLocationType.CITY,
      image:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Phuket_Aerial.jpg/800px-Phuket_Aerial.jpg',
    },
    {
      search_id: 'VWPHTJBEMH7',
      display_name: 'London',
      sub_display_name: 'United Kingdom',
      type: HotelLocationType.CITY,
      image:
        'https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcTF9OtLxnbxSpLFzyhzXERKiAbnHlG25WfRzxRTNcRiTk0lHvsOXNKH9KNAOvTWi_sS',
    },
    {
      search_id: 'VWPHTJHCZY8',
      display_name: 'Denpasar',
      sub_display_name: 'Indonesia',
      type: HotelLocationType.CITY,
      image:
        'https://t2.gstatic.com/licensed-image?q=tbn:ANd9GcS1BWJs0qug7Cw0UQtD-4vLv_e_TzBdLftSukG8FSh_Vv_mFgh9irMws_LYErAuJvoX',
    },
    {
      search_id: 'VWPHTIZHYBK',
      display_name: 'Tokyo',
      sub_display_name: 'Japan',
      type: HotelLocationType.CITY,
      image:
        'https://upload.wikimedia.org/wikipedia/commons/b/b2/Skyscrapers_of_Shinjuku_2009_January.jpg',
    },
  ];
};

export const doFilterProperties = (
  selectedFilters: PropertySelectedFilters,
  allProperties: Array<Property>
): Array<Property> => {
  if (allProperties.length === 0) return [];

  const [minPrice, maxPrice] = selectedFilters.priceRange;

  return allProperties.filter(property => {
    const isInFilters: Array<boolean> = [];

    isInFilters[0] = property.price > minPrice && property.price < maxPrice;

    isInFilters[1] = selectedFilters.propertyRating.includes(
      property.rating.toString()
    );

    isInFilters[2] = ((): boolean => {
      let showRefundables =
        selectedFilters.bookingPolicy.indexOf('refundable') >= 0;
      let showNonRefundables =
        selectedFilters.bookingPolicy.indexOf('non-refundable') >= 0;
      if (showRefundables && showNonRefundables) {
        return true;
      } else {
        if (showNonRefundables) {
          return property.non_refundable;
        } else if (showRefundables) {
          return !property.non_refundable;
        }
      }
      return false;
    })();

    return isInFilters.every(filter => filter);
  });
};
