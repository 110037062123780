import React from 'react';
import Loader from 'react-loader-spinner';

type SpinnerProps = {
  size?: number;
  heightClass?: string;
  height?: string;
  loaderHeight?: number;
  loaderWidth?: number;
  color?: string;
};

const Spinner: React.FC<SpinnerProps> = ({
  size,
  heightClass,
  height,
  loaderHeight = 100,
  loaderWidth = 100,
  color = '#10B981',
}) => {
  return (
    <div
      style={{ height }}
      className={`${heightClass} w-100 d-flex justify-content-center align-items-center`}
    >
      <Loader
        type="Oval"
        color={color}
        height={loaderHeight}
        width={loaderWidth}
        timeout={0}
      />
    </div>
  );
};

export default Spinner;
