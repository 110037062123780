import { useMemo } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useToast } from 'src/contexts/toasterContext';
import apiService from 'src/services/api';

function WithAxios({ children }) {
  const toast = useToast();

  useMemo(() => {
    apiService.apiSauceInstance.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: AxiosError) => {
        toast.showToast({
          type: 'error',
          title: error?.response?.data.message || 'Something went wrong',
        });
        return error;
      }
    );
  }, [toast]);

  return children;
}

export default WithAxios;
