import React from 'react';

import { User, Settings } from 'src/types';
import {
  parseLocalStorageData,
  stringEncrypt,
  setInLocalStorage,
  removeFromLocalStorage,
  getFromLocalStorage,
} from 'src/utils/helpers';
import {
  ACCESS_TOKEN,
  USER,
  REFRESH_TOKEN,
  SETTINGS,
  PERMISSIONS,
  ORGANIZATION,
} from 'src/constants';
import { PERMISSIONS_ENUM } from '../enums/permissions';
import { OrganizationV2 } from '../types/OrganizationV2';

type ContextProps = {
  user: User | null;
  permissions: string[] | null;
  settings: Settings | null;
  onLogin: Function;
  onLogout: Function;
  hasPermissions: Function;
  isUserSignedIn: boolean;
  organization: OrganizationV2 | null;
  isAuthenticating: boolean;
};

const initialValues: ContextProps = {
  user: null,
  settings: null,
  organization: null,
  permissions: null,
  isUserSignedIn: false,
  isAuthenticating: false,
  onLogin: () => {},
  onLogout: () => {},
  hasPermissions: () => {},
};

const userData: any = parseLocalStorageData('user');
const organizationData: any = parseLocalStorageData('organization');
const settingsData: any = parseLocalStorageData('settings');
const permissionsData: any = parseLocalStorageData('permissions');

initialValues.user = userData || null;
initialValues.organization = organizationData || null;
initialValues.settings = settingsData || null;
initialValues.permissions = permissionsData || null;
initialValues.isUserSignedIn = userData !== null;

const AuthContext = React.createContext<ContextProps>(initialValues);

const AuthProvider: React.FC = props => {
  const [user, setUser] = React.useState<User | null>(useAuth().user);
  const [organization, setOrganization] = React.useState<OrganizationV2 | null>(
    useAuth().organization
  );
  const [isUserSignedIn, setIsUserSignedIn] = React.useState<boolean>(
    Boolean(user)
  );
  const [permissions, setPermissions] = React.useState<string[] | null>(
    useAuth().permissions
  );
  const [settings, setSettings] = React.useState<Settings | null>(
    useAuth().settings
  );
  const [isAuthenticating, setIsAuthenticating] = React.useState(true);

  React.useEffect(() => {
    const localStorageChangeListener = (event: StorageEvent) => {
      if (event.key === 'user') {
        const storedUserData = parseLocalStorageData('user');
        storedUserData ? setUser(storedUserData) : onLogout();
        setIsAuthenticating(false);
      } else if (event.key === 'settings') {
        const storedSettingsData = parseLocalStorageData('settings');
        storedSettingsData ? setSettings(storedSettingsData) : onLogout();
        setIsAuthenticating(false);
      } else if (event.key === 'permissions') {
        const storedPermissionsData = parseLocalStorageData('permissions');
        storedPermissionsData
          ? setPermissions(storedPermissionsData)
          : onLogout();
        setIsAuthenticating(false);
      } else if (event.key === ORGANIZATION) {
        const storedOrganizationData = parseLocalStorageData(ORGANIZATION);
        storedOrganizationData
          ? setOrganization(storedOrganizationData)
          : onLogout();
        setIsAuthenticating(false);
      } else if (
        event.key === 'access-token' ||
        event.key === 'refresh-token'
      ) {
        const accessToken = getFromLocalStorage(ACCESS_TOKEN);
        const refreshToken = getFromLocalStorage(REFRESH_TOKEN);
        if (!accessToken || !refreshToken) {
          onLogout();
        }
      }
    };

    if (typeof window !== 'undefined') {
      // To detect local stroge changes on all the open tabs.
      window.addEventListener('storage', localStorageChangeListener, false);
    }

    setIsAuthenticating(false);
    return () => {
      window.removeEventListener('storage', localStorageChangeListener, false);
    };
  }, []);

  const onLogin = (
    userInfo: User,
    accessToken: string,
    refreshToken: string,
    settings: Settings,
    permissions: string[],
    organizationInfo: OrganizationV2
  ) => {
    setInLocalStorage(USER, stringEncrypt(JSON.stringify(userInfo)));
    setInLocalStorage(ACCESS_TOKEN, accessToken);
    setInLocalStorage(REFRESH_TOKEN, refreshToken);
    setInLocalStorage(SETTINGS, stringEncrypt(JSON.stringify(settings)));
    setInLocalStorage(PERMISSIONS, stringEncrypt(JSON.stringify(permissions)));
    setInLocalStorage(
      ORGANIZATION,
      stringEncrypt(JSON.stringify(organizationInfo))
    );
    setUser(userInfo);
    setOrganization(organizationInfo);
    setIsUserSignedIn(true);
    setSettings(settings);
    setPermissions(permissions);
  };

  const onLogout = () => {
    removeFromLocalStorage(USER);
    removeFromLocalStorage(ACCESS_TOKEN);
    removeFromLocalStorage(REFRESH_TOKEN);
    removeFromLocalStorage(SETTINGS);
    removeFromLocalStorage(PERMISSIONS);
    removeFromLocalStorage(ORGANIZATION);
    setIsUserSignedIn(false);
    setUser(null);
  };

  const hasPermissions = (...args: PERMISSIONS_ENUM[]): boolean => {
    if (permissions === null) return false;
    return args.every(menuItemPermission => {
      return permissions.includes(menuItemPermission);
    });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        organization,
        settings,
        permissions,
        onLogin,
        onLogout,
        isUserSignedIn,
        isAuthenticating,
        hasPermissions,
      }}
      {...props}
    />
  );
};

function useAuth() {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within AuthContext Provider');
  }

  return context;
}

export { AuthProvider, useAuth };
