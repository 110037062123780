import {
  FETCH_DASHBOARD_DATA,
  FETCH_DASHBOARD_DATA_SUCCESS,
  FETCH_DASHBOARD_DATA_FAILED,
  FETCH_BOOKING_EXPIRY,
  FETCH_BOOKING_EXPIRY_SUCCESS,
  FETCH_BOOKING_EXPIRY_FAILED,
  RESET,
} from '../constants/dashboardData';
import { Action, DashboardDataReducer } from '../types';

const initialState: DashboardDataReducer = {
  isFetching: false,
  data: {
    graph: {
      x_legends: [],
      y_legends: [],
      matrix: [],
    },
    booking_aggregate: [],
  },
  expiryBookings: null,
};

const dashboardDataReducer = (
  state = initialState,
  action: Action
): DashboardDataReducer => {
  switch (action.type) {
    case RESET: {
      return {
        ...initialState,
      };
    }

    case FETCH_DASHBOARD_DATA: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case FETCH_DASHBOARD_DATA_FAILED: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_DASHBOARD_DATA_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };
    }
    case FETCH_BOOKING_EXPIRY: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case FETCH_BOOKING_EXPIRY_FAILED: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_BOOKING_EXPIRY_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        expiryBookings: action.payload?.count,
      };
    }

    default:
      return state;
  }
};

export default dashboardDataReducer;
