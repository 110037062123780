import {
  FETCH_AGENTS_NEWS,
  FETCH_AGENTS_NEWS_SUCCESS,
  FETCH_AGENTS_NEWS_FAILED,
  FETCH_AGENT_DETAILS,
  FETCH_AGENT_DETAILS_SUCCESS,
  FETCH_AGENT_DETAILS_FAILED,
  FETCH_AGENT_PRICING_DETAILS,
  FETCH_AGENT_PRICING_DETAILS_FAILED,
  FETCH_AGENT_PRICING_DETAILS_SUCCESS,
  RESET,
  FETCH_AGENTS,
  FETCH_AGENTS_SUCCESS,
  FETCH_AGENTS_FAILED,
  FETCH_AGENTS_FEATURED_NEWS_SUCCESS,
  FETCH_AGENTS_FEATURED_NEWS,
  FETCH_AGENTS_FEATURED_NEWS_FAILED,
  DISABLE_AGENTS_FEATURED_NEWS,
} from '../constants/agent';
import { Action, AgentReducer } from '../types';

const initialState: AgentReducer = {
  enableAgentFeaturedNews: false,
  isFetchingAgentList: false,
  isFetchingAgentNewsList: false,
  isFetchingAgentDetails: false,
  isFetchingAgentPricingDetails: false,
  agentList: [],
  agentNewsList: [],
  agentFeaturedNews: null,
  isFetchingAgentFeaturedNews: false,
  agentDetails: {},
  agentPricingDetails: [],
};

const agentReducer = (state = initialState, action: Action): AgentReducer => {
  switch (action.type) {
    case RESET: {
      return {
        ...initialState,
      };
    }

    case FETCH_AGENTS: {
      return {
        ...state,
        isFetchingAgentList: true,
      };
    }

    case FETCH_AGENTS_SUCCESS: {
      return {
        ...state,
        isFetchingAgentList: false,
        agentList: action.payload,
      };
    }

    case FETCH_AGENTS_FAILED: {
      return {
        ...state,
        isFetchingAgentList: false,
      };
    }

    case FETCH_AGENTS_NEWS: {
      return {
        ...state,
        isFetchingAgentNewsList: true,
      };
    }

    case FETCH_AGENTS_NEWS_SUCCESS: {
      return {
        ...state,
        isFetchingAgentNewsList: false,
        agentNewsList: action.payload,
      };
    }

    case FETCH_AGENTS_NEWS_FAILED: {
      return {
        ...state,
        isFetchingAgentNewsList: false,
      };
    }

    case FETCH_AGENTS_FEATURED_NEWS: {
      return {
        ...state,
        agentFeaturedNews: null,
        isFetchingAgentFeaturedNews: true,
      };
    }

    case FETCH_AGENTS_FEATURED_NEWS_SUCCESS: {
      return {
        ...state,
        agentFeaturedNews: action.payload,
        enableAgentFeaturedNews: true,
        isFetchingAgentFeaturedNews: false,
      };
    }

    case DISABLE_AGENTS_FEATURED_NEWS: {
      return {
        ...state,
        enableAgentFeaturedNews: false,
      };
    }

    case FETCH_AGENTS_FEATURED_NEWS_FAILED: {
      return {
        ...state,
        isFetchingAgentFeaturedNews: false,
      };
    }

    case FETCH_AGENT_DETAILS: {
      return {
        ...state,
        isFetchingAgentDetails: true,
      };
    }

    case FETCH_AGENT_DETAILS_SUCCESS: {
      return {
        ...state,
        isFetchingAgentDetails: false,
        agentDetails: action.payload,
      };
    }
    case FETCH_AGENT_DETAILS_FAILED: {
      return {
        ...state,
        isFetchingAgentDetails: false,
      };
    }

    case FETCH_AGENT_PRICING_DETAILS: {
      return {
        ...state,
        isFetchingAgentPricingDetails: true,
      };
    }

    case FETCH_AGENT_PRICING_DETAILS_SUCCESS: {
      return {
        ...state,
        isFetchingAgentPricingDetails: false,
        agentPricingDetails: action.payload,
      };
    }

    case FETCH_AGENT_PRICING_DETAILS_FAILED: {
      return {
        ...state,
        isFetchingAgentPricingDetails: false,
      };
    }

    default:
      return state;
  }
};

export default agentReducer;
