import {
  CREATE_OTP,
  CREATE_OTP_FAILED,
  CREATE_OTP_SUCCESS,
  EXPIRE_OTP,
  EXPIRE_OTP_FAILED,
  FETCH_OTP,
  FETCH_OTP_FAILED,
  FETCH_OTP_SUCCESS,
  HANDLE_CURRENT_PAGE,
  RESET,
} from '../constants/otp';

export const fetchOtp = payload => ({
  type: FETCH_OTP,
  payload,
});

export const fetchOtpFullfilled = payload => {
  return {
    type: FETCH_OTP_SUCCESS,
    payload,
  };
};

export const fetchOtpFailed = () => ({
  type: FETCH_OTP_FAILED,
});

export const createOtp = payload => ({
  type: CREATE_OTP,
  payload,
});

export const createOtpSuccess = payload => ({
  type: CREATE_OTP_SUCCESS,
  payload,
});

export const createOtpFailed = () => ({
  type: CREATE_OTP_FAILED,
});

export const expireOtp = payload => ({
  type: EXPIRE_OTP,
  payload,
});

export const expireOtpFailed = () => ({
  type: EXPIRE_OTP_FAILED,
});

export const handleCurrentPage = payload => ({
  type: HANDLE_CURRENT_PAGE,
  payload,
});

export const resetOtp = () => {
  return {
    type: RESET,
  };
};
