import { call, put, takeLatest } from 'redux-saga/effects';
import Analytics from 'src/services/analytics';
import {
  getPropertiesService,
  getPropertyDetailsService,
} from 'src/services/hotelsServices';
import {
  fetchPropertiesFailed,
  fetchPropertiesFullfilled,
  fetchPropertyDetailsFailed,
  fetchPropertyDetailsFullfilled,
} from '../actions/hotels';
import { FETCH_PROPERTIES, FETCH_PROPERTY_DETAILS } from '../constants/hotels';
import { Action } from '../types';
import { EVENT_NAME } from '../../constants';
import { HotelLocationType } from '../../pages/HotelNew/types';

function* fetchProperties(action: Action) {
  try {
    Analytics.logActionEvent(
      EVENT_NAME.AG_HOTELS_SEARCH_INITIATED,
      action.payload
    );
    const response = yield call(getPropertiesService, action.payload);
    if (response.ok && response.data?.success) {
      let propertyNotFound = false;
      if (action.payload.type === HotelLocationType.PROPERTY) {
        propertyNotFound =
          response.data.data.hotels.filter(
            hotel => hotel.provider_property_id === action.payload.id
          ).length === 0;
      }
      yield put(
        fetchPropertiesFullfilled(
          response.data.data,
          action.payload.loadMoreProperties,
          propertyNotFound
        )
      );
      Analytics.logActionEvent(
        EVENT_NAME.AG_HOTELS_SEARCH_COMPLETED,
        action.payload
      );
    } else {
      yield put(
        fetchPropertiesFailed({
          error:
            response.data?.message ||
            'Something went wrong while fetching properties',
        })
      );
      Analytics.logActionEvent(EVENT_NAME.AG_HOTELS_SEARCH_FAILED, {
        payload: action.payload,
        error: response,
      });
    }
  } catch (err) {
    yield put(
      fetchPropertiesFailed({
        error: err || 'Something went wrong while fetching properties',
      })
    );
    Analytics.logActionEvent(EVENT_NAME.AG_HOTELS_SEARCH_FAILED, {
      payload: action.payload,
      error: err,
    });
  }
}

function* fetchPropertyDetails(action: Action) {
  try {
    Analytics.logActionEvent(
      EVENT_NAME.AG_HOTEL_SELECT_INITIATED,
      action.payload
    );
    const response = yield call(getPropertyDetailsService, action.payload);
    if (response.ok && response.data?.success) {
      yield put(fetchPropertyDetailsFullfilled(response.data.data));
      Analytics.logActionEvent(
        EVENT_NAME.AG_HOTEL_SELECT_COMPLETED,
        action.payload
      );
    } else {
      yield put(fetchPropertyDetailsFailed());
      Analytics.logActionEvent(EVENT_NAME.AG_HOTEL_SELECT_FAILED, {
        payload: action.payload,
        error: response,
      });
    }
  } catch (err) {
    yield put(fetchPropertyDetailsFailed());
    Analytics.logActionEvent(EVENT_NAME.AG_HOTEL_SELECT_FAILED, {
      payload: action.payload,
      error: err,
    });
  }
}

export function* fetchPropertiesWatcher() {
  yield takeLatest(FETCH_PROPERTIES, fetchProperties);
}

export function* fetchPropertyDetailsWatcher() {
  yield takeLatest(FETCH_PROPERTY_DETAILS, fetchPropertyDetails);
}
