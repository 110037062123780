import theme from 'src/styles/theme';

export enum HotelEndPoints {
  GET_ALL_LOCATIONS = 'v2/hotels/get_all_locations',
  GET_ALL_PROPERTIES_V2 = 'v2/hotels/properties/',
  GET_ALL_REGIONS = 'v1/hotels/get_all_regions',
  GET_ALL_PROPERTIES = 'v1/hotels/properties/',
  GET_PROPERTY_DETAIL = 'v1/hotels/detail/',
  GET_PROPERTY_ROOM_DETAILS = '/v1/hotels/rooms/',
  INITIATE_BOOKING = '/v1/hotels/booking/initiate/',
  VALIDATE_WEB_REFERENCE = 'v1/hotels/booking/validate_web_reference',
  BOOK = '/v1/hotels/booking/book/',
  ISSUE = '/v1/hotels/booking/issue/',
}
export enum TourEndPoints {
  GET_ALL = 'v1/tours/',
  GET_TOUR_DETAILS = 'v1/tours/',
  INITIATE_BOOKING = 'v1/tours/bookings/initiate/',
}

export enum TravelersType {
  ADULTS = 'Adults',
  CHILDREN = 'Children',
}

export const TravelersLimit = 6;

export const TravelersTypeLimit = {
  [TravelersType.ADULTS]: 1,
  [TravelersType.CHILDREN]: 0,
};

export const ChildrenAges = [
  {
    name: 'Under 1',
    value: 0,
  },
  {
    name: '2',
    value: 2,
  },
  {
    name: '3',
    value: 3,
  },
  {
    name: '4',
    value: 4,
  },
  {
    name: '5',
    value: 5,
  },
  {
    name: '6',
    value: 6,
  },
  {
    name: '7',
    value: 7,
  },
  {
    name: '8',
    value: 8,
  },
  {
    name: '9',
    value: 9,
  },
  {
    name: '10',
    value: 10,
  },
  {
    name: '11',
    value: 11,
  },
  {
    name: '12',
    value: 12,
  },
  {
    name: '13',
    value: 13,
  },
  {
    name: '14',
    value: 14,
  },
  {
    name: '15',
    value: 15,
  },
  {
    name: '16',
    value: 16,
  },
  {
    name: '17',
    value: 17,
  },
];

export const CHECK_IN_FORMAT = 'yyyy-MM-dd';
export const DATE_FORMAT = 'dd/MMM/yyyy';

export const DEFAULT_FILTERS = {
  amenities: [],
  booking_policy: [],
  payment_type: [],
  price: {
    max: 1000,
    min: 0,
  },
  property_class: [],
  property_type: [],
  region_id: [],
  sort: {
    name: 'featured',
    order: 'DESC',
  },
};

export const BOARDBASIS_CHIPS_CONFIG = {
  breakfast: {
    name: 'Breakfast Included',
    color: theme.colors.darkBlue,
  },
  freeairportTransfer: {
    name: 'Free Airport Transfer',
    color: theme.colors.darkBlue,
  },
  refundable: {
    name: 'Refundable',
    color: theme.colors.secondaryGreen,
  },
  freeCancellation: {
    name: 'Free Cancellation',
    color: theme.colors.secondaryGreen,
  },
};

export const HOTEL_CARD_CHIP = {
  BED_AND_BREAKFAST: {
    name: 'Bed and Breakfast',
    label: 'Breakfast Included',
  },
  BREAKFAST: {
    name: 'Breakfast',
    label: 'Breakfast Included',
  },
  FREE_AIRPORT_TRANSFER: {
    name: 'Free Airport Transfer',
    label: 'Free Airport Transfer',
  },
  FREE_CANCELLATION: {
    name: 'Free Cancellation',
    label: 'Free Cancellation',
  },
  NON_REFUNDABLE: {
    name: 'Non-Refundable',
    label: 'Non-Refundable',
  },
  REFUNDABLE: {
    name: 'Refundable',
    label: 'Refundable',
  },
};

export const HOTEL_INCLUSIVE_TAXES_NOTE = 'Inclusive of all taxes';

export enum HOTEL_EVENTS {
  SEARCH_BUTTON = 'hotel_search',
  SELECT_ROOM = 'hotel_search_initiated',
  FILTER_APPLIED = 'hotel_filter_applied',
  FILTER_REMOVED = 'hotel_filter_removed',
  HOTEL_LIST_SCREEN = 'hotel_list_screen',
  HOTEL_SEARCH_DESTINATION_CLICK = 'hotel_search_destination_click',
}

export enum BOOKING_POLICIES {
  INSTANT_CONFIRMATION = 'Instant Confirmation',
  ON_REQUEST = 'On Request',
}

export const ON_REQUEST_TEXT =
  'Please note that the selected property is currently running at high occupancy levels. However, we will make every effort to ensure that your booking is confirmed after receipt of payment. In the unlikely event that the booking is refused, your amount shall be fully refunded within 24 hours.';
