import React from 'react';
import { FareCalculatorType } from '../pages/FlightsV2/components/FlightFilters/FareCalculator/types/FareCalculatorType';
import { FareCalculatorAmountType } from '../pages/FlightsV2/components/FlightFilters/FareCalculator/types/FareCalculatorAmountType';
import { Price } from '../types';
import { FareCalculatorFareType } from '../pages/FlightsV2/components/FlightFilters/FareCalculator/types/FareCalculatorFareType';

interface FareCalculatorContextPropsType {
  onChange: (fareCalculator: FareCalculatorType) => void;
  doCalculation: (priceObject: Price) => number;
  resetCalculation: () => void;
  getPriceBreakDown: (priceObject: Price) => DoCalculationReturnType | null;
}

type DoCalculationReturnType = {
  fare: number;
  amountToAdd: number;
  tax: number;
};

const FareCalculatorContextInitialValue: FareCalculatorContextPropsType = {
  onChange: () => {},
  doCalculation: () => 0,
  resetCalculation: () => {},
  getPriceBreakDown: (priceObject: Price) => null,
};

const FareCalculatorContext =
  React.createContext<FareCalculatorContextPropsType>(
    FareCalculatorContextInitialValue
  );

const FareCalculatorProvider = ({ children }) => {
  const [fareCalculation, updateFareCalculation] =
    React.useState<FareCalculatorType | null>(null);

  const doCalculation = (priceObject: Price): number => {
    if (fareCalculation === null || fareCalculation.value === 0)
      return parseInt(priceObject.gross_fare);
    let fare = parseInt(priceObject.gross_fare);
    let amountToAdd = fareCalculation.value;
    let tax = 0;
    if (fareCalculation.fareType === FareCalculatorFareType.Base) {
      fare = parseInt(priceObject.base_fare);
      tax = parseInt(priceObject.tax);
    }
    if (fareCalculation.amountType === FareCalculatorAmountType.Percentage) {
      amountToAdd = (fare / 100) * fareCalculation.value;
    }
    return fare + amountToAdd + tax;
  };

  const getPriceBreakDown = (
    priceObject: Price
  ): DoCalculationReturnType | null => {
    let fare = parseInt(priceObject.gross_fare);
    let amountToAdd = fareCalculation?.value || 0;
    let tax = 0;
    if (fareCalculation?.fareType === FareCalculatorFareType.Base) {
      fare = parseInt(priceObject.base_fare);
      tax = parseInt(priceObject.tax);
    }
    if (fareCalculation?.amountType === FareCalculatorAmountType.Percentage) {
      amountToAdd = (fare / 100) * fareCalculation.value;
    }

    if (fareCalculation === null || fareCalculation.value === 0) {
      return null;
    }

    return {
      fare,
      amountToAdd,
      tax,
    };
  };

  const onChange = (calculation: FareCalculatorType) => {
    updateFareCalculation(calculation);
  };

  const resetCalculation = () => {
    updateFareCalculation(null);
  };

  return (
    <FareCalculatorContext.Provider
      value={{ onChange, doCalculation, resetCalculation, getPriceBreakDown }}
    >
      {children}
    </FareCalculatorContext.Provider>
  );
};

const useFareCalculator = () => {
  const context = React.useContext(FareCalculatorContext);

  if (!context) {
    throw new Error(
      'useFareCalculator() must be used within FareCalculatorProvider'
    );
  }

  return context;
};

export { FareCalculatorProvider, useFareCalculator };
