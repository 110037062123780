import { call, put, takeLatest } from 'redux-saga/effects';
import { PATHS } from 'src/routes/routesConfig';
import {
  createPaymentService,
  getPaymentByIdService,
  getPaymentService,
} from 'src/services/paymentService';
import {
  createPaymentFailed,
  createPaymentFullfilled,
  fetchPaymentFailed,
  fetchPaymentFullfilled,
  fetchPaymentsFailed,
  fetchPaymentsFullfilled,
  fetchPayments as fetchPaymentsAction,
} from '../actions/payments';
import {
  CREATE_PAYMENT,
  FETCH_PAYMENT,
  FETCH_PAYMENTS,
} from '../constants/payments';
import { PAYMENT_METHODS_TYPES } from '../../enums/payment_methods';
import Analytics from 'src/services/analytics';
import { EVENT_NAME, pay_guide_url } from 'src/constants';

function* fetchPayments(action) {
  try {
    const response = yield call(getPaymentService, action.payload.page);
    if (response.ok && response.data?.success) {
      yield put(fetchPaymentsFullfilled(response.data.response));
    } else {
      yield put(fetchPaymentsFailed());
    }
  } catch (err) {
    yield put(fetchPaymentsFailed());
  }
}

function* fetchPayment(action) {
  try {
    const response = yield call(getPaymentByIdService, action.payload);
    if (response.ok && response.data?.success) {
      yield put(fetchPaymentFullfilled(response.data.response));
    } else {
      yield put(fetchPaymentFailed());
    }
  } catch (err) {
    yield put(fetchPaymentFailed());
  }
}

function* createPayment(action) {
  try {
    const response = yield call(createPaymentService, action.payload);
    if (response.ok && response.data?.success) {
      yield put(createPaymentFullfilled(response.data.response));
      action.payload?.history.push(
        `${PATHS.paymentDetail}/${response.data.response.payment.payment_id}`
      );
      const toastPayload = {
        type: 'success',
        title: 'Payments',
      };

      if (
        response.data.response.payment.method ===
        PAYMENT_METHODS_TYPES.CREDIT_CARD
      ) {
        action.payload.showToast({
          ...toastPayload,
          message: `Payment created successfully. \n Charges ${response.data.response.payment.charges} will be deducted and net amount ${response.data.response.payment.net_amount}
           will be deposited in your account.`,
          confirmButtonText: 'Proceed To Pay',
          confirmCallback: () =>
            window.open(
              response.data.response.payment.credit_card_payment_detail
                .payment_url
            ),
        });
      } else {
        action.payload.showToast({
          ...toastPayload,
          message: `Payment created successfully. \n Charges ${response.data.response.payment.charges} will be deducted and net amount ${response.data.response.payment.net_amount}
          will be deposited in your account. Your ONE BILL ID is "${response.data.response.payment.one_bill_payment_detail.bill_code}", Please save it !!.`,
          confirmButtonText: 'How To Pay',
          confirmCallback: () => window.open(pay_guide_url),
        });
      }
      Analytics.logActionEvent(EVENT_NAME.AG_PAYMENTS_CREATE_PAYMENT_SUCCESS, {
        financial_profile_code: action.payload.data.financial_profile_code,
        payment_method: action.payload.data.method,
        description: action.payload.data.description,
        amount: action.payload.data.amount,
      });

      yield put(fetchPaymentsAction(1));
    } else {
      Analytics.logActionEvent(EVENT_NAME.AG_PAYMENTS_CREATE_PAYMENT_FAILURE, {
        financial_profile_code: action.payload.data.financial_profile_code,
        payment_method: action.payload.data.method,
        description: action.payload.data.description,
        amount: action.payload.data.amount,
      });
      yield put(createPaymentFailed());
    }
  } catch (err) {
    console.log({ err });
    Analytics.logActionEvent(EVENT_NAME.AG_PAYMENTS_CREATE_PAYMENT_FAILURE, {
      agent_traacs_code: action.payload.data.financial_profile_code,
      payment_method: action.payload.data.method,
      description: action.payload.data.description,
      amount: action.payload.data.amount,
    });
    yield put(createPaymentFailed());
  }
}

export function* fetchPaymentsWatcher() {
  yield takeLatest(FETCH_PAYMENTS, fetchPayments);
}

export function* fetchPaymentWatcher() {
  yield takeLatest(FETCH_PAYMENT, fetchPayment);
}

export function* createPaymentWatcher() {
  yield takeLatest(CREATE_PAYMENT, createPayment);
}
