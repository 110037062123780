import { ROUTE_TYPE } from 'src/enums/route-type';
import {
  FETCH_FLIGHTS,
  FETCH_FLIGHTS_SUCCESS,
  FETCH_FLIGHTS_FAILED,
  FILTER_FLIGHTS_IN_LEG_BY_FARE_OPTION_IDS,
  UPDATE_FILTERED_FLIGHTS_DATA,
  UPDATE_FLIGHTS_FILTERS,
  SET_SELECTED_FLIGHT,
  EMPTY_ACTIVE_FLIGHTS,
} from './../constants/flight';
import { Action, FlightReducer } from './../types';

const initialState: FlightReducer = {
  isFetching: false,
  isInitiallyFetched: false,
  data: {
    is_international: false,
    route_type: ROUTE_TYPE.ONEWAY,
    journey_legs: [],
  },
  filteredData: [],
  filters: {
    airlines: [],
    stops: [],
    priceRange: [],
    departureTime: [],
  },
  selectedFlights: {},
};

const flightReducer = (state = initialState, action: Action): FlightReducer => {
  switch (action.type) {
    case FETCH_FLIGHTS:
      return {
        ...state,
        filteredData: [],
        isFetching: true,
        data: initialState.data,
      };
    case FETCH_FLIGHTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        filteredData: action.payload.journey_legs[0].flight_options,
        isFetching: false,
        isInitiallyFetched: true,
      };

    case FETCH_FLIGHTS_FAILED:
      return {
        ...state,
        isFetching: false,
      };
    case UPDATE_FLIGHTS_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case FILTER_FLIGHTS_IN_LEG_BY_FARE_OPTION_IDS:
      const { payload } = action;
      let newState = { ...state };
      if (newState.data.journey_legs.length) {
        let { flight_options } = newState.data.journey_legs[payload.leg];
        newState.filteredData = flight_options.filter(item => {
          item.fare_options = item.fare_options.filter(fare_opt => {
            return payload.nextFareIds.includes(
              fare_opt.pre_booking_token.split('_')[0]
            );
          });

          return item.fare_options.length === 0 ? false : true;
        });
      }
      return newState;

    case UPDATE_FILTERED_FLIGHTS_DATA:
      return {
        ...state,
        filteredData: action.payload,
      };
    case SET_SELECTED_FLIGHT:
      return {
        ...state,
        selectedFlights: {
          ...state.selectedFlights,
          [action.payload.key]: action.payload.value,
        },
      };

    case EMPTY_ACTIVE_FLIGHTS:
      return {
        ...state,
        selectedFlights: {},
      };
    default:
      return state;
  }
};

export default flightReducer;
