export enum HotelLocationType {
  CITY = 'CITY',
  PROPERTY = 'PROPERTY',
}
export type HotelSearchSuggestion = {
  search_id: string;
  display_name: string;
  sub_display_name: string;
  type: HotelLocationType;
  image?: string;
};

export type RoomOccupancyChildrenParams = {
  age: number;
};

export type RoomOccupancy = {
  adults: number;
  children?: Array<RoomOccupancyChildrenParams>;
};

export type Amenity = {
  name: string;
  id: number;
  icon: string | null;
};

export type PropertyImage = {
  title: string;
  url: string;
};

export type CancellationPolicy = {
  id: number;
  startFrom: string;
  endAt: string;
  penalty: string;
  description: string;
};

export type Rate = {
  id: string;
  available_rooms: number;
  rate_class: string;
  deposit_required: number;
  cancellation_policy: Array<CancellationPolicy>;
  gross_rate: number;
  selling_rate: number;
  tax: number;
  fee: number;
  payment_type: string;
  meta: {};
};

export type StopSell = {
  msg: string;
  start: string;
  end: string;
};

export type Room = {
  room_id: string;
  room_name: string;
  room_description: string;
  board_basis: string;
  rates: Array<Rate>;
  price_per_person: boolean;
  adults: number;
  booking_policy: string;
  children: number;
  images: Array<PropertyImage>;
  infants: number;
  is_refundable: boolean;
  stop_sell: Array<StopSell>;
};

export type Property = {
  provider_property_id: string;
  name: string;
  description: string;
  accommodation_type: string;
  rating: number;
  city: string;
  country_name: string;
  country: string;
  address1: string;
  address2: string | null;
  advance_payment: boolean;
  featured: boolean;
  free_cancellation: boolean;
  non_refundable: boolean;
  instant_confirmation: boolean;
  images: Array<PropertyImage>;
  price: number;
  gross_price: number;
  net_price: number;
  provider: string;
  reserve_now_pay_later: boolean;
  main_image_url: string;

  // Discontinued fields
  // rooms: Array<Room>;
  // giata: number;
  // amenities: Array<Amenity>;
  // neighborhood: string;

  //New Fields
  important_information: string;
  thumbnail_image: any[];
  room_quotes: any[];
  facilities: any[];
  default_currency_code: string;
};

export type SearchQuery = {
  checkin: string;
  checkout: string;
  rooms_occupancy: Array<RoomOccupancy>;
  filters: any;
  page?: number;
  destination: HotelSearchSuggestion;
};

export type PropertyDetail = {
  id: number;
  name: string;
  description: string;
  policy: string;
  resort: string;
  accommodation_type: string;
  rating: number;
  city: string;
  country: string;
  address1: string;
  address2: string;
  free_cancellation: boolean;
  featured: boolean;
  instant_confirmation: boolean;
  advance_payment: boolean;
  reserve_now_pay_later: boolean;
  // Old Fields
  telephone: string;
  latitude: number;
  longitude: number;
  // New Fields
  price: number;
  main_image_url: string;
  images: string[];
  important_information: string;
  provider: string;
  thumbnail_image: string[];
  room_quotes: RoomQuote[];
  facilities: string[];
};

export type PropertyRoomDetails = {
  id: number;
  giata: number;
  name: string;
  accommodation_type: string;
  rating: string;
  city: string;
  neighborhood: string;
  country_name: string;
  address1: string;
  address2: string;
  amenities: Array<Amenity>;
  images: Array<PropertyImage>;
  rooms: Array<Room>;
  price: number;
  free_cancellation: boolean;
  instant_confirmation: boolean;
  advance_payment: boolean;
  featured: boolean;
  reserve_now_pay_later: boolean;
  provider: string;
  prop_key: string;

  // New Fields
};

export type GetPropertiesPayload = {
  checkin: string;
  checkout: string;
  rooms_occupancy: Array<RoomOccupancy>;
  filters: any;
  id: string;
  type: HotelLocationType;
  page?: number;
  loadMoreProperties?: boolean;
};

type BookingRoom = {
  adults: number;
  children: number;
  room_type: string;
  id: number;
};

export type ValidateWebReferenceResponse = {
  booking_id: null | number;
  booking_start_date: string;
  booking_type: string;
  cancellation_policies: Array<CancellationPolicy>;
  checkin: string;
  checkout: string;
  contact_details: null;
  gross_fare: number;
  hotel_type: string;
  id: number;
  lead_guest: number;
  meta: object;
  number_of_guest: number;
  number_of_rooms: number;
  order_new: null | string;
  prop: Property;
  provider: string;
  rooms: Array<BookingRoom>;
  rules_json: Array<string>;
  search_query: SearchQuery;
  tax: number;
  total_fare: number;
  web_reference: string;
};

type BookingLeadGuest = {
  id: number;
  title: string;
  first_name: string;
  last_name: string;
};

type BookingContactDetails = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  mobile_number: string;
};

type HotelBooking = {
  id: number;
  hotel_type: string;
  provider: string;
  booking_id: number | null;
  web_reference: string;
  number_of_guest: number;
  number_of_rooms: number;
  checkin: string;
  checkout: string;
  rules_json: Array<any>;
  gross_fare: number;
  total_fare: number;
  tax: number;
  rooms: Array<BookingRoom>;
  lead_guest: BookingLeadGuest;
  contact_details: BookingContactDetails;
  prop: Property;
  meta: object;
  search_query: SearchQuery;
  cancellation_policies: Array<CancellationPolicy>;
  booking_type: string;
  order_new: number;
  booking_start_date: string;
};

export type BookResponse = {
  order_id: number;
  uuid: string;
  status: string;
  discount: number;
  order_total: number;
  voucher_json: object;
  bookings: Array<HotelBooking>;
  agent: string | null;
  transactions: Array<any>;
  order_expiry: Date;
  parent_order_uuid: null | number;
  public_id: string;
  ag_booking_id: number;
};

export type RoomQuote = {
  room_qoute_id: string;
  room_type: string;
  currency_code: string;
  total_cost: number;
  non_refundable: boolean;
  supplier: string;
  meal_board_basis: string;
  cancellation_terms: CancellationTerm[];
  default_currency_code: string;
  default_currency_total_cost: number;
};

export type CancellationTerm = {
  start_date: string;
  end_date: string;
  cancellation_charge: number;
};
