import { Action, AgentBalanceReducer } from '../types';
import {
  FETCH_AGENT_BALANCE,
  FETCH_AGENT_BALANCE_FAILED,
  FETCH_AGENT_BALANCE_SUCCESS,
  RESET,
} from '../constants/agentBalance';

const initialState: AgentBalanceReducer = {
  isFetchingAgentBalance: false,
  agentBalance: {
    available_credit: 0,
    temporary_credit_limit: 0,
    total_balance_current: 0,
    current_balance: 0,
    total_of_not_invoiced_tickets: 0,
    credit_days: 0,
    credit_days_available: 0,
    customer_name: '',
    credit_limit: 0,
    credit_rule: '',
    currency_code: '',
    customer_code: '',
    customer_type: '',
    customer_status: '',
    status_remarks: '',
  },
};

const agentBalanceReducer = (
  state = initialState,
  action: Action
): AgentBalanceReducer => {
  switch (action.type) {
    case RESET: {
      return {
        ...initialState,
      };
    }

    case FETCH_AGENT_BALANCE: {
      return {
        ...state,
        isFetchingAgentBalance: true,
      };
    }

    case FETCH_AGENT_BALANCE_FAILED: {
      return {
        ...state,
        isFetchingAgentBalance: false,
      };
    }
    case FETCH_AGENT_BALANCE_SUCCESS: {
      return {
        ...state,
        isFetchingAgentBalance: false,
        agentBalance: action.payload,
      };
    }

    default:
      return state;
  }
};

export default agentBalanceReducer;
