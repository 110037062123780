import React from 'react';
import EnterOtpDialog from '../components/AgOtp/EnterOtpDialog';
import { OtpToCopyType } from '../components/AgOtp/types/OtpToCopyType';

interface ShowDialogArgsType {
  mainButtonText?: string;
  cancellable: boolean;
  readonly: boolean;
  otpToCopy?: OtpToCopyType;
  submitCallback?: (otp: string) => void;
}

const ShowDialogDefaultArguments: ShowDialogArgsType = {
  cancellable: false,
  readonly: false,
  submitCallback: async otp => {},
};

interface OtpDialogContextPropsType {
  showDialog: (props: ShowDialogArgsType) => void;
  showProgress: (loading: boolean, finishDialog?: boolean) => void;
}

const OtpDialogContextInitialValue: OtpDialogContextPropsType = {
  showDialog: () => {},
  showProgress: () => {},
};

const OtpDialogContext = React.createContext<OtpDialogContextPropsType>(
  OtpDialogContextInitialValue
);

const OtpDialogProvider = ({ children }) => {
  const [dialogVisible, toggleShowDialog] = React.useState<boolean>(false);
  const [dialogProps, setDialogProps] = React.useState<ShowDialogArgsType>(
    ShowDialogDefaultArguments
  );
  const [isSubmitLoading, setIsSubmitLoading] = React.useState<boolean>(false);

  const showDialog = (dialogArgs: ShowDialogArgsType) => {
    toggleShowDialog(true);
    setDialogProps(dialogArgs);
  };

  const showProgress = (loading, finishDialog: boolean | undefined) => {
    setIsSubmitLoading(loading);
    if (finishDialog) {
      closeDialog();
    }
  };

  const closeDialog = () => toggleShowDialog(false);

  return (
    <OtpDialogContext.Provider value={{ showDialog, showProgress }}>
      {children}
      {dialogVisible && (
        <EnterOtpDialog
          open={dialogVisible}
          closeDialog={closeDialog}
          showSubmitLoading={isSubmitLoading}
          {...dialogProps}
        />
      )}
    </OtpDialogContext.Provider>
  );
};

const useOtpDialog = () => {
  const context = React.useContext(OtpDialogContext);

  if (!context) {
    throw new Error('useOtpDialog() must be used within OtpDialogProvider');
  }

  return context;
};

export { OtpDialogProvider, useOtpDialog };
