import { call, put, takeLatest } from 'redux-saga/effects';
import { PATHS } from 'src/routes/routesConfig';
import {
  getAgentByStatusService,
  getLimitRequestByIdService,
  getLimitRequestService,
  getSalesUserTypesService,
  updateCreditLimitService,
  updateLimitRequestStatusService,
} from 'src/services/salesService';
import { getSerializerErrors } from 'src/utils/helpers';
import {
  fetchAgentLimitRequestsByIdFullfilled,
  fetchAgentLimitRequestsFullfilled,
  fetchAgentLimitRequestsTypeFullfilled,
  fetchAgentsLimitRequestsFailed,
  fetchSalesUserTypeFailed,
  fetchSalesUserTypeFullfilled,
  updateAgentCreditRequestLimitFailed,
  updateAgentCreditRequestLimitFullfilled,
} from '../actions/sales';
import {
  FETCH_AGENTS_LIMIT_REQUESTS,
  FETCH_SALES_USER_TYPES,
  FETCH_FILTERED_AGENTS_LIMIT_REQUESTS,
  FETCH_AGENTS_LIMIT_REQUESTS_BY_ID,
  UPDATE_AGENT_LIMIT_REQUEST,
  UPDATE_AGENT_LIMIT_REQUEST_STATUS,
} from '../constants/sales';

function* fetchSalesUserTypes() {
  try {
    const response = yield call(getSalesUserTypesService);
    if (response.ok && response.data?.success) {
      yield put(fetchSalesUserTypeFullfilled(response.data.response));
    } else {
      yield put(fetchSalesUserTypeFailed());
    }
  } catch (err) {
    yield put(fetchSalesUserTypeFailed());
  }
}

function* fetchAgentsLimitRequest(action) {
  try {
    const response = yield call(getLimitRequestService, action.payload);
    if (response.ok && response.data?.success) {
      yield put(
        fetchAgentLimitRequestsFullfilled(
          response.data.response,
          action.payload
        )
      );
    } else {
      yield put(fetchAgentsLimitRequestsFailed());
    }
  } catch (err) {
    yield put(fetchAgentsLimitRequestsFailed());
  }
}

function* fetchFilteredAgentsLimitRequest(action) {
  try {
    const { status, page } = action.payload || {};
    const response = yield call(getAgentByStatusService, status, page);
    if (response.ok && response.data?.success) {
      yield put(
        fetchAgentLimitRequestsTypeFullfilled(
          response.data.response,
          status,
          page
        )
      );
    } else {
      yield put(fetchAgentsLimitRequestsFailed());
    }
  } catch (err) {
    yield put(fetchAgentsLimitRequestsFailed());
  }
}

function* fetchAgentLimitRequestsById(action) {
  try {
    const response = yield call(getLimitRequestByIdService, action.payload);
    if (response.ok && response.data?.success) {
      yield put(fetchAgentLimitRequestsByIdFullfilled(response.data.response));
    } else {
      yield put(fetchAgentsLimitRequestsFailed());
    }
  } catch (err) {
    yield put(fetchAgentsLimitRequestsFailed());
  }
}

function* updateAgentLimitRequest(action) {
  try {
    const response = yield call(updateCreditLimitService, action.payload);
    if (response?.ok && response.data?.success) {
      action.payload.showToast({
        title: 'Updated Agent Limit Request',
        message: response.data.message,
        type: 'success',
      });
      yield put(
        updateAgentCreditRequestLimitFullfilled(response.data.response)
      );
      action.payload.history.push(PATHS.salesLimitManagement);
    } else {
      if (Object.keys(response?.data?.error).length) {
        const errorList = getSerializerErrors(response?.data?.error);
        errorList.forEach(e =>
          action.payload.showToast({
            title: 'Limit Request Updation Failed',
            message: e,
            type: 'error',
          })
        );
      } else {
        action.payload.showToast({
          title: 'Unable to Update Agent credit limit request',
          message: response.data?.message,
          type: 'error',
        });
      }
      yield put(updateAgentCreditRequestLimitFailed());
    }
  } catch (err) {
    yield put(updateAgentCreditRequestLimitFailed());
  }
}

function* updateAgentLimitRequestStatus(action) {
  try {
    yield put({ type: UPDATE_AGENT_LIMIT_REQUEST });
    const response = yield call(
      updateLimitRequestStatusService,
      action.payload
    );
    if (response?.ok && response.data?.success) {
      yield put(
        updateAgentCreditRequestLimitFullfilled(response.data.response)
      );
    } else {
      yield put(updateAgentCreditRequestLimitFailed());
    }
  } catch (err) {
    yield put(updateAgentCreditRequestLimitFailed());
  }
}

export function* fetchSalesUserTypesWatcher() {
  yield takeLatest(FETCH_SALES_USER_TYPES, fetchSalesUserTypes);
}

export function* fetchAgentsLimitRequestWatcher() {
  yield takeLatest(FETCH_AGENTS_LIMIT_REQUESTS, fetchAgentsLimitRequest);
}

export function* fetchFilteredAgentsLimitRequestWatcher() {
  yield takeLatest(
    FETCH_FILTERED_AGENTS_LIMIT_REQUESTS,
    fetchFilteredAgentsLimitRequest
  );
}

export function* fetchAgentLimitRequestsByIdWatcher() {
  yield takeLatest(
    FETCH_AGENTS_LIMIT_REQUESTS_BY_ID,
    fetchAgentLimitRequestsById
  );
}

export function* updateAgentLimitRequestWatcher() {
  yield takeLatest(UPDATE_AGENT_LIMIT_REQUEST, updateAgentLimitRequest);
}

export function* updateAgentLimitRequestStatusWatcher() {
  yield takeLatest(
    UPDATE_AGENT_LIMIT_REQUEST_STATUS,
    updateAgentLimitRequestStatus
  );
}
