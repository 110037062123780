import { addDays } from 'date-fns';
import { Action, FITReducer } from '../types';
import {
  FIT_ADD_ITINERARY,
  FIT_GET_SEARCH_RESULT,
  FIT_GET_SEARCH_RESULT_FAIL,
  FIT_GET_SEARCH_RESULT_SUCCESS,
  FIT_REMOVE_ITINERARY,
  FIT_RESET,
  FIT_SET_SEARCH_QUERY,
  FIT_SUBMIT_REQUEST,
  FIT_SUBMIT_REQUEST_FAIL,
  FIT_SUBMIT_REQUEST_SUCCESS,
  FIT_TOGGLE_SUBMIT_REQUEST_DIALOG,
} from '../constants/fitConstant';
import { FIT_CITIES } from '../../pages/FIT/utils/constants';
import { FITTypes } from '../../pages/FIT/utils/enums/FITTypes';

const initialState: FITReducer = {
  search: {
    cityId: FIT_CITIES[FITTypes.UMRAH][0].city_id,
    checkin: new Date(),
    checkout: addDays(new Date(), 1),
    rooms_occupancy: [
      {
        adults: 1,
      },
    ],
    itinerary: [],
    showSubmitRequestDialog: false,
  },
};

const fitReducer = (state = initialState, action: Action): FITReducer => {
  switch (action.type) {
    case FIT_RESET:
      return initialState;
    case FIT_SET_SEARCH_QUERY:
      return {
        ...state,
        search: {
          ...state.search,
          [action.payload.key]: action.payload.value,
        },
      };
    case FIT_TOGGLE_SUBMIT_REQUEST_DIALOG:
      return {
        ...state,
        search: {
          ...state.search,
          showSubmitRequestDialog: action.payload.show,
        },
      };
    case FIT_ADD_ITINERARY:
      const nextCheckIn = action.payload.itinerary.checkout;
      return {
        ...state,
        search: {
          ...state.search,

          checkin: nextCheckIn,
          checkout: addDays(nextCheckIn, 1),

          itinerary: [...state.search.itinerary, action.payload.itinerary],
          showSubmitRequestDialog: true,
        },
        searchResult: undefined,
      };
    case FIT_REMOVE_ITINERARY:
      const filteredItinerary = state.search.itinerary.filter(
        itinerary =>
          itinerary.room.roomId !== action.payload.itinerary.room.roomId
      );
      return {
        ...state,
        search: {
          ...state.search,
          itinerary: [...filteredItinerary],
        },
      };
    case FIT_GET_SEARCH_RESULT:
      return {
        ...state,
        searchResult: {
          searchedDates: {
            checkin: state.search.checkin,
            checkout: state.search.checkout,
          },
          inProgress: true,
          error: undefined,
          hotels: undefined,
        },
      };
    case FIT_GET_SEARCH_RESULT_SUCCESS:
      try {
        const sortedHotelRooms = action.payload.hotels.map(hotel => {
          hotel.rooms = hotel.rooms.sort((firstRoom, secondRoom) => {
            if (
              firstRoom.totalRatesOfTotalRooms <
              secondRoom.totalRatesOfTotalRooms
            ) {
              return -1;
            }
            if (
              firstRoom.totalRatesOfTotalRooms >
              secondRoom.totalRatesOfTotalRooms
            ) {
              return 1;
            }
            return 0;
          });
          return hotel;
        });
        const sortedHotels = sortedHotelRooms.sort(
          (firstHotel, secondHotel) => {
            if (
              firstHotel.rooms[0].totalRatesOfTotalRooms <
              secondHotel.rooms[0].totalRatesOfTotalRooms
            ) {
              return -1;
            }
            if (
              firstHotel.rooms[0].totalRatesOfTotalRooms >
              secondHotel.rooms[0].totalRatesOfTotalRooms
            ) {
              return 1;
            }
            return 0;
          }
        );

        return {
          ...state,
          searchResult: {
            ...state.searchResult,
            inProgress: false,
            error: undefined,
            hotels: sortedHotels,
          },
        };
      } catch (e) {
        return {
          ...state,
          searchResult: {
            ...state.searchResult,
            inProgress: false,
            error: undefined,
            hotels: action.payload.hotels,
          },
        };
      }
    case FIT_GET_SEARCH_RESULT_FAIL:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          inProgress: false,
          hotels: undefined,
          error: action.payload.error,
        },
      };
    case FIT_SUBMIT_REQUEST:
      return {
        ...state,
        request: {
          inProgress: true,
          error: undefined,
          success: undefined,
        },
      };
    case FIT_SUBMIT_REQUEST_SUCCESS:
      return {
        ...state,
        request: {
          inProgress: false,
          error: undefined,
          success: true,
        },
      };
    case FIT_SUBMIT_REQUEST_FAIL:
      return {
        ...state,
        request: {
          inProgress: false,
          error: action.payload.error,
          success: undefined,
        },
      };
    default:
      return state;
  }
};

export default fitReducer;
