import React from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

import { useAuth } from 'src/contexts/authContext';
import { PATHS } from './routesConfig';

interface ProtectedRouteProps extends Omit<RouteProps, 'component'> {
  component: any;
  permission: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  permission = [],
  ...rest
}) => {
  const { isUserSignedIn, permissions } = useAuth();
  const location = useLocation();

  let isAllowed = false;
  if (!permission.length) {
    isAllowed = true;
  } else {
    for (let userPerm in permissions) {
      if (permission.includes(userPerm)) {
        isAllowed = true;
        break;
      }
    }
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (isUserSignedIn && isAllowed) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              from={`${rest.path}`}
              to={`${PATHS.login}?redirect=${
                location.pathname + location.search
              }`}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
