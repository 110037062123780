import {
  FETCH_SECTORS,
  FETCH_SECTORS_FAILED,
  FETCH_SECTORS_SUCCESS,
  RESET,
} from '../constants/sector';
import { Action, SectorReducer } from '../types';

const initialState: SectorReducer = {
  isFetchingSectorList: false,
  sectors: [],
};

const sectorReducer = (state = initialState, action: Action): SectorReducer => {
  switch (action.type) {
    case RESET: {
      return {
        ...initialState,
      };
    }

    case FETCH_SECTORS: {
      return {
        ...state,
        isFetchingSectorList: true,
      };
    }

    case FETCH_SECTORS_FAILED: {
      return {
        ...state,
        isFetchingSectorList: false,
      };
    }
    case FETCH_SECTORS_SUCCESS: {
      return {
        ...state,
        isFetchingSectorList: false,
        sectors: action.payload,
      };
    }

    default:
      return state;
  }
};

export default sectorReducer;
