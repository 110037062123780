import { HTTP_METHODS } from 'src/enums/http-methods';
import apiService from './api';
import { FITSubmitRequestDto } from '../pages/FIT/utils/models';

export const getFitHotelsList = payload => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: `v1/tours/fit/search?city_id=${payload.cityId}&check_in=${payload.checkin}&check_out=${payload.checkout}&travelers=${payload.travelers}`,
  });
};

export const submitFitRequest = (payload: { request: FITSubmitRequestDto }) => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: `v1/tours/fit/initiate`,
    data: payload.request,
  });
};
