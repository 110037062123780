import { all } from 'redux-saga/effects';
import { fetchAirlineStatusWatcher } from './airlineStatusSagas';
import {
  fetchAgentDetailsWatcher,
  fetchAgentFeaturedNewsWatcher,
  fetchAgentNewsWatcher,
  fetchAgentPricingDetailsWatcher,
  fetchAgentsWatcher,
} from './agentsSagas';
import { fetchAppSettingsWatcher } from './appSettingsSagas';
import {
  addAgentWatcher,
  changeAgentStatusWatcher,
  createOrganizationWatcher,
  fetchOrganizationsWatcher,
  fetchOrganizationWatcher,
  updateOrganizationWatcher,
} from './organizationSagas';
import {
  createOTPWatcher,
  expireOTPWatcher,
  fetchOTPWatcher,
} from './otpSagas';
import {
  createPaymentWatcher,
  fetchPaymentsWatcher,
  fetchPaymentWatcher,
} from './paymentsSagas';
import {
  fetchAgentLimitRequestsByIdWatcher,
  fetchAgentsLimitRequestWatcher,
  fetchFilteredAgentsLimitRequestWatcher,
  fetchSalesUserTypesWatcher,
  updateAgentLimitRequestStatusWatcher,
  updateAgentLimitRequestWatcher,
} from './salesSagas';
import { fetchSectorsWatcher } from './sectorsSagas';
import { fetchAirportsWatcher } from './airportsSagas';
import { fetchFlightsWatcher, filterFLightsWatcher } from './flightsSagas';
import {
  fetchBookingWatcher,
  initiateBookingHotelWatcher,
  confirmBookingHotelWatcher,
  issueBookingHotelWatcher,
  initiateBookingWatcher,
  fetchActiveBookingWatcher,
  initiateBookingTourWatcher,
  confirmBookingTourWatcher,
  confirmDummyBookingTourWatcher,
  downloadBookingWatcher,
  getAirlineTicketsWatcher,
  cancelBookingWatcher,
} from './bookingSagas';
import {
  fetchPropertiesWatcher,
  fetchPropertyDetailsWatcher,
} from './hotelsSagas';
import { fetchToursWatcher, fetchTourWatcher } from './toursSagas';
import {
  fetchDashboardDataWatcher,
  fetchBookingExpiryWatcher,
} from './dashboardDataSagas';
import {
  fetchFitSearchResultWatcher,
  fetchFitSubmitRequestWatcher,
} from './fitSaga';
import { fetchAgentBalanceWatcher } from './agentBalanceSagas';

export default function* rootSaga() {
  yield all([
    fetchAppSettingsWatcher(),
    fetchAgentsWatcher(),
    fetchAgentNewsWatcher(),
    fetchAgentDetailsWatcher(),
    fetchAgentPricingDetailsWatcher(),
    changeAgentStatusWatcher(),
    fetchOrganizationWatcher(),
    fetchOrganizationsWatcher(),
    updateOrganizationWatcher(),
    addAgentWatcher(),
    createOrganizationWatcher(),
    expireOTPWatcher(),
    fetchOTPWatcher(),
    createOTPWatcher(),
    fetchSectorsWatcher(),
    fetchPaymentsWatcher(),
    fetchPaymentWatcher(),
    createPaymentWatcher(),
    fetchSalesUserTypesWatcher(),
    fetchAgentsLimitRequestWatcher(),
    fetchFilteredAgentsLimitRequestWatcher(),
    fetchAgentLimitRequestsByIdWatcher(),
    fetchAgentLimitRequestsByIdWatcher(),
    updateAgentLimitRequestStatusWatcher(),
    updateAgentLimitRequestWatcher(),
    fetchAirlineStatusWatcher(),
    fetchAirportsWatcher(),
    fetchFlightsWatcher(),
    filterFLightsWatcher(),
    initiateBookingWatcher(),
    fetchPropertiesWatcher(),
    fetchPropertyDetailsWatcher(),
    fetchBookingWatcher(),
    initiateBookingHotelWatcher(),
    confirmBookingHotelWatcher(),
    issueBookingHotelWatcher(),
    fetchActiveBookingWatcher(),
    fetchToursWatcher(),
    fetchTourWatcher(),
    initiateBookingTourWatcher(),
    confirmBookingTourWatcher(),
    confirmDummyBookingTourWatcher(),
    downloadBookingWatcher(),
    getAirlineTicketsWatcher(),
    fetchDashboardDataWatcher(),
    fetchAgentFeaturedNewsWatcher(),
    fetchBookingExpiryWatcher(),
    fetchFitSearchResultWatcher(),
    fetchFitSubmitRequestWatcher(),
    fetchAgentBalanceWatcher(),
    cancelBookingWatcher(),
  ]);
}
