import { HTTP_METHODS } from 'src/enums/http-methods';
import apiService from './api';

export const getSalesUserTypesService = () => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: 'salesuser/sales-user-types',
  });
};

export const getLimitRequestService = (page: number) => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: `limit-request/list?page=${[page]}`,
  });
};

export const getAgentByStatusService = (status: string, page: number) => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: `limit-request/list?status=${status}&page=${page}`,
  });
};

export const getLimitRequestByIdService = (id: string) => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: `limit-request/list/${id}`,
  });
};

export const updateCreditLimitService = payload => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: 'limit-request/process',
    data: payload,
  });
};

export const updateLimitRequestStatusService = payload => {
  return apiService.execute({
    method: HTTP_METHODS.PATCH,
    url: `limit-request/update/${payload.public_id}`,
    data: {
      is_active: !payload.is_active,
    },
  });
};
