import {
  INITIATE_BOOKING_FLIGHT,
  INITIATE_BOOKING_SUCCESS,
  INITIATE_BOOKING_FAILED,
  EMPTY_ACTIVE_BOOKINGS,
  FETCH_BOOKING,
  FETCH_BOOKING_FAILED,
  FETCH_BOOKING_SUCCESS,
  INITIATE_BOOKING_HOTEL,
  CONFIRM_BOOKING_SUCCESS,
  CONFIRM_BOOKING_HOTEL,
  CONFIRM_BOOKING_FAILED,
  ISSUE_BOOKING_HOTEL,
  ISSUE_BOOKING_SUCCESS,
  ISSUE_BOOKING_FAILED,
  FETCH_ACTIVE_BOOKING,
  FETCH_ACTIVE_BOOKING_SUCCESS,
  FETCH_ACTIVE_BOOKING_FAILED,
  INITIATE_BOOKING_TOUR,
  DOWNLOAD_BOOKING_COMPLETED,
  DOWNLOAD_BOOKING,
  GET_AIRLINE_TICKETS,
  GET_AIRLINE_TICKETS_FAILED,
  GET_AIRLINE_TICKETS_SUCCESS,
  CANCEL_FLIGHT_BOOKING,
  CANCEL_FLIGHT_BOOKING_FAILED,
  CANCEL_FLIGHT_BOOKING_SUCCESS,
} from '../constants/booking';
import { Action, BookingReducer } from '../types';

const initialState: BookingReducer = {
  isLoading: false,
  isSendEmailLoading: false,
  cancelBookingLoading: false,
  error: '',
  activeBookings: {},
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
};

const bookingReducer = (
  state = initialState,
  action: Action
): BookingReducer => {
  switch (action.type) {
    case INITIATE_BOOKING_FLIGHT:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case INITIATE_BOOKING_HOTEL:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case INITIATE_BOOKING_TOUR:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case INITIATE_BOOKING_SUCCESS:
      return {
        ...state,
        activeBookings: {
          ...state.activeBookings,
          [action.payload.key]: action.payload.value,
        },
        isLoading: false,
        error: null,
      };
    case INITIATE_BOOKING_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case CONFIRM_BOOKING_HOTEL:
      return {
        ...state,
        isLoading: true,
      };
    case CONFIRM_BOOKING_SUCCESS:
      return {
        ...state,
        activeBookings: {
          ...state.activeBookings,
          [action.payload.key]: action.payload.value,
        },
        isLoading: false,
      };
    case CONFIRM_BOOKING_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ISSUE_BOOKING_HOTEL:
      return {
        ...state,
        isLoading: true,
      };
    case ISSUE_BOOKING_SUCCESS:
      return {
        ...state,
        activeBookings: {
          ...state.activeBookings,
          [action.payload.key]: action.payload.value,
        },
        isLoading: false,
      };
    case ISSUE_BOOKING_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case EMPTY_ACTIVE_BOOKINGS:
      return {
        ...state,
        activeBookings: {},
      };

    case FETCH_BOOKING:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_BOOKING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case FETCH_BOOKING_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case FETCH_ACTIVE_BOOKING:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_ACTIVE_BOOKING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activeBookings: {
          ...state.activeBookings,
          [action.payload.booking_id]: action.payload.data,
        },
      };
    case FETCH_ACTIVE_BOOKING_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case DOWNLOAD_BOOKING:
      return {
        ...state,
        isLoading: true,
      };
    case DOWNLOAD_BOOKING_COMPLETED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_AIRLINE_TICKETS:
      return {
        ...state,
        error: '',
        isSendEmailLoading: true,
      };
    case GET_AIRLINE_TICKETS_SUCCESS:
      return {
        ...state,
        isSendEmailLoading: false,
      };
    case GET_AIRLINE_TICKETS_FAILED:
      return {
        ...state,
        error: action.payload,
        isSendEmailLoading: false,
      };
    case CANCEL_FLIGHT_BOOKING:
      return {
        ...state,
        error: '',
        cancelBookingLoading: true,
      };
    case CANCEL_FLIGHT_BOOKING_SUCCESS:
      return {
        ...state,
        cancelBookingLoading: false,
      };
    case CANCEL_FLIGHT_BOOKING_FAILED:
      return {
        ...state,
        error: action.payload,
        cancelBookingLoading: false,
      };
    default:
      return state;
  }
};

export default bookingReducer;
