import {
  CREATE_OTP,
  CREATE_OTP_FAILED,
  CREATE_OTP_SUCCESS,
  EXPIRE_OTP,
  EXPIRE_OTP_FAILED,
  FETCH_OTP,
  FETCH_OTP_FAILED,
  FETCH_OTP_SUCCESS,
  HANDLE_CURRENT_PAGE,
  RESET,
} from '../constants/otp';
import { Action, OtpReducer } from '../types';

const initialState: OtpReducer = {
  otpList: [],
  isFetching: false,
  count: 0,
  links: { next: null, previous: null },
  total_pages: 0,
  isCreating: false,
  isExpiring: false,
  current_page: 1,
  createOtp: {
    isLoading: false,
  },
};

const otpReducer = (state = initialState, action: Action): OtpReducer => {
  switch (action.type) {
    case RESET:
      return initialState;

    case FETCH_OTP: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case FETCH_OTP_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        isCreating: false,
        isExpiring: false,
      };
    }

    case FETCH_OTP_FAILED: {
      return {
        ...state,
        isFetching: false,
        isCreating: false,
        isExpiring: false,
      };
    }

    case CREATE_OTP: {
      return {
        ...state,
        isCreating: true,
        createOtp: {
          ...state.createOtp,
          isLoading: true,
        },
      };
    }

    case CREATE_OTP_SUCCESS: {
      return {
        ...state,
        isCreating: false,
        createOtp: {
          ...state.createOtp,
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case CREATE_OTP_FAILED: {
      return {
        ...state,
        isCreating: false,
        createOtp: {
          ...state.createOtp,
          isLoading: false,
          error: action.payload,
        },
      };
    }

    case EXPIRE_OTP: {
      return {
        ...state,
        isExpiring: true,
      };
    }

    case EXPIRE_OTP_FAILED: {
      return {
        ...state,
        isExpiring: false,
      };
    }

    case HANDLE_CURRENT_PAGE: {
      return {
        ...state,
        current_page: action.payload,
      };
    }

    default:
      return state;
  }
};

export default otpReducer;
