import React from 'react';
import Toast from 'src/components/Toast';
import SweetAlert from 'react-bootstrap-sweetalert';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';
import { formatDateTime } from '../services/utils';

type Toast = {
  message: string;
  title: string;
  type: SweetAlertType;
  confirmButtonText?: string;
  confirmCallback?: () => void;
};

type ToasterContextProps = {
  toast: Toast | undefined;
  showToast: Function;
};

const initialValue = {
  toast: undefined,
  showToast: () => {},
  type: 'info',
};

const ToasterContext = React.createContext<ToasterContextProps>(initialValue);

const ToasterProvider = props => {
  const [toast, setToast] = React.useState<Toast | undefined>();

  const showToast = ({
    message,
    title,
    type,
    confirmButtonText,
    confirmCallback,
  }: Toast) => {
    setToast({ message, title, type, confirmButtonText, confirmCallback });
  };

  return (
    <ToasterContext.Provider value={{ toast, showToast }}>
      {props.children}
      {toast && (
        <SweetAlert
          type={toast?.type}
          customClass={toast.type}
          title={toast?.title}
          confirmBtnText={toast?.confirmButtonText || 'Ok'}
          onCancel={() => {
            setToast(undefined);
          }}
          onConfirm={() => {
            if (toast.confirmCallback) {
              toast.confirmCallback();
            }
            setToast(undefined);
          }}
          confirmBtnCssClass={'sa-close-btn'}
        >
          {toast?.message}
          <br />
          <br />
          <span>{`${formatDateTime(new Date())}`}</span>
        </SweetAlert>
      )}
    </ToasterContext.Provider>
  );
};

const useToast = () => {
  const context = React.useContext(ToasterContext);

  if (!context) {
    throw new Error('useToast() must be used within ToasterProvider');
  }

  return context;
};

export { ToasterProvider, useToast };
