export const RESERVE_BOOKING = 'RESERVE_BOOKING';
export const RESERVE_BOOKING_SUCCESS = 'RESERVE_BOOKING_SUCCESS';
export const RESERVE_BOOKING_FAILED = 'RESERVE_BOOKING_FAILED';

export const INITIATE_BOOKING_FLIGHT = 'INITIATE_BOOKING_FLIGHT';
export const INITIATE_BOOKING_HOTEL = 'INITIATE_BOOKING_HOTEL';
export const INITIATE_BOOKING_TOUR = 'INITIATE_BOOKING_TOUR';

export const CONFIRM_BOOKING_HOTEL = 'CONFIRM_BOOKING_HOTEL';
export const ISSUE_BOOKING_HOTEL = 'ISSUE_BOOKING_HOTEL';

export const CONFIRM_BOOKING_TOUR = 'CONFIRM_BOOKING_TOUR';
export const CONFIRM_DUMMY_BOOKING_TOUR = 'CONFIRM_DUMMY_BOOKING_TOUR';

export const INITIATE_BOOKING_SUCCESS = 'INITIATE_BOOKING_SUCCESS';
export const INITIATE_BOOKING_FAILED = 'INITIATE_BOOKING_FAILED';

export const CONFIRM_BOOKING_SUCCESS = 'CONFIRM_BOOKING_SUCCESS';
export const CONFIRM_BOOKING_FAILED = 'CONFIRM_BOOKING_FAILED';

export const ISSUE_BOOKING_SUCCESS = 'ISSUE_BOOKING_SUCCESS';
export const ISSUE_BOOKING_FAILED = 'ISSUE_BOOKING_FAILED';

export const FETCH_BOOKING = 'FETCH_BOOKING';
export const FETCH_BOOKING_SUCCESS = 'FETCH_BOOKING_SUCCESS';
export const FETCH_BOOKING_FAILED = 'FETCH_BOOKING_FAILED';

export const FETCH_ACTIVE_BOOKING = 'FETCH_ACTIVE_BOOKING';
export const FETCH_ACTIVE_BOOKING_SUCCESS = 'FETCH_ACTIVE_BOOKING_SUCCESS';
export const FETCH_ACTIVE_BOOKING_FAILED = 'FETCH_ACTIVE_BOOKING_FAILED';

export const GET_AIRLINE_TICKETS = 'GET_AIRLINE_TICKETS';
export const GET_AIRLINE_TICKETS_SUCCESS = 'GET_AIRLINE_TICKETS_SUCCESS';
export const GET_AIRLINE_TICKETS_FAILED = 'GET_AIRLINE_TICKETS_FAILED';

export const DOWNLOAD_BOOKING = `DOWNLOAD_BOOKING`;
export const DOWNLOAD_BOOKING_COMPLETED = `DOWNLOAD_BOOKING_COMPLETED`;

export const CANCEL_FLIGHT_BOOKING = 'CANCEL_FLIGHT_BOOKING';
export const CANCEL_FLIGHT_BOOKING_SUCCESS = 'CANCEL_FLIGHT_BOOKING_SUCCESS';
export const CANCEL_FLIGHT_BOOKING_FAILED = 'CANCEL_FLIGHT_BOOKING_FAILED';

export const EMPTY_ACTIVE_BOOKINGS = 'EMPTY_ACTIVE_BOOKINGS';
