import {
  FETCH_AGENTS_LIMIT_REQUESTS,
  FETCH_AGENTS_LIMIT_REQUESTS_SUCCESS,
  FETCH_AGENTS_LIMIT_REQUESTS_FAILED,
  UPDATE_AGENT_LIMIT_REQUEST,
  RESET,
  FETCH_AGENTS_LIMIT_REQUESTS_NEW_SUCCESS,
  FETCH_AGENTS_LIMIT_REQUESTS_PENDING_SUCCESS,
  FETCH_AGENTS_LIMIT_REQUESTS_CANCELLED_SUCCESS,
  FETCH_AGENTS_LIMIT_REQUESTS_APPROVED_SUCCESS,
  FETCH_AGENTS_LIMIT_REQUESTS_BY_ID_SUCCESS,
  FETCH_AGENTS_LIMIT_REQUESTS_BY_ID,
  FETCH_SALES_USER_TYPES,
  FETCH_SALES_USER_TYPES_SUCCESS,
  FETCH_SALES_USER_TYPES_FAILED,
  FETCH_FILTERED_AGENTS_LIMIT_REQUESTS,
  UPDATE_AGENT_LIMIT_REQUEST_SUCCESS,
  UPDATE_AGENT_LIMIT_REQUEST_FAILED,
  UPDATE_AGENT_LIMIT_REQUEST_STATUS,
} from '../constants/sales';
import { CREDIT_LIMIT_REQ_STATUS } from 'src/constants';

export const resetSales = () => {
  return {
    type: RESET,
  };
};

export const fetchSalesUserType = () => ({
  type: FETCH_SALES_USER_TYPES,
});

export const fetchSalesUserTypeFullfilled = payload => {
  return {
    type: FETCH_SALES_USER_TYPES_SUCCESS,
    payload,
  };
};

export const fetchSalesUserTypeFailed = () => ({
  type: FETCH_SALES_USER_TYPES_FAILED,
});

export const fetchAgentsLimitRequests = payload => ({
  type: FETCH_AGENTS_LIMIT_REQUESTS,
  payload,
});

export const fetchAgentLimitRequestsFullfilled = (payload, page) => ({
  type: FETCH_AGENTS_LIMIT_REQUESTS_SUCCESS,
  payload: { ...payload, page },
});

export const fetchAgentsLimitRequestsFailed = () => ({
  type: FETCH_AGENTS_LIMIT_REQUESTS_FAILED,
});

export const fetchFilteredAgentsLimitRequest = (status, page) => ({
  type: FETCH_FILTERED_AGENTS_LIMIT_REQUESTS,
  payload: { status, page },
});

export const fetchAgentLimitRequestsById = payload => ({
  type: FETCH_AGENTS_LIMIT_REQUESTS_BY_ID,
  payload,
});

export const fetchAgentLimitRequestsByIdFullfilled = payload => ({
  type: FETCH_AGENTS_LIMIT_REQUESTS_BY_ID_SUCCESS,
  payload: { ...payload },
});

export const fetchAgentLimitRequestsTypeFullfilled = (
  payload,
  status = null,
  page
) => {
  if (status === CREDIT_LIMIT_REQ_STATUS.new) {
    return {
      type: FETCH_AGENTS_LIMIT_REQUESTS_NEW_SUCCESS,
      payload: { ...payload, page },
    };
  } else if (status === CREDIT_LIMIT_REQ_STATUS.partiallyApproved) {
    return {
      type: FETCH_AGENTS_LIMIT_REQUESTS_PENDING_SUCCESS,
      payload: { ...payload, page },
    };
  } else if (status === CREDIT_LIMIT_REQ_STATUS.cancelled) {
    return {
      type: FETCH_AGENTS_LIMIT_REQUESTS_CANCELLED_SUCCESS,
      payload: { ...payload, page },
    };
  } else if (status === CREDIT_LIMIT_REQ_STATUS.approved) {
    return {
      type: FETCH_AGENTS_LIMIT_REQUESTS_APPROVED_SUCCESS,
      payload: { ...payload, page },
    };
  } else {
    return {
      type: FETCH_AGENTS_LIMIT_REQUESTS_SUCCESS,
      payload: { ...payload, page },
    };
  }
};

export const updateAgentCreditRequestLimit = payload => {
  return {
    type: UPDATE_AGENT_LIMIT_REQUEST,
    payload,
  };
};

export const updateAgentCreditRequestLimitFullfilled = payload => {
  return {
    type: UPDATE_AGENT_LIMIT_REQUEST_SUCCESS,
    payload,
  };
};

export const updateAgentCreditRequestLimitFailed = () => {
  return {
    type: UPDATE_AGENT_LIMIT_REQUEST_FAILED,
  };
};

export const updateAgentRequestLimitStatus = payload => {
  return {
    type: UPDATE_AGENT_LIMIT_REQUEST_STATUS,
    payload,
  };
};
