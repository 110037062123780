export const FETCH_PROPERTIES = 'FETCH_PROPERTIES';
export const FETCH_PROPERTIES_FULFILLED = 'FETCH_PROPERTIES_FULFILLED';
export const FETCH_PROPERTIES_FAILED = 'FETCH_PROPERTIES_FAILED';

export const FETCH_PROPERTY_DETAILS = 'FETCH_PROPERTY_DETAILS';
export const FETCH_PROPERTY_DETAILS_FAILED = 'FETCH_PROPERTY_DETAILS_FAILED';
export const FETCH_PROPERTY_DETAILS_FULLFILLED =
  'FETCH_PROPERTY_DETAILS_FULLFILLED';

export const RESET = 'RESET';
export const RESET_FETCHED_PROPERTIES = 'RESET_FETCHED_PROPERTIES';

export const UPDATE_HOTEL_FILTERS = 'UPDATE_HOTEL_FILTERS';
