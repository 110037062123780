import {
  ADD_AGENT,
  ADD_AGENT_FAILED,
  ADD_AGENT_SUCCESS,
  CHANGE_AGENT_STATUS,
  CHANGE_AGENT_STATUS_FAILED,
  CHANGE_AGENT_STATUS_SUCCESS,
  CREATE_ORGANIZATION,
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_FAILED,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATION_SUCCESS,
  HANDLE_ORGANIZATION,
  RESET_ORGANIZATION,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_FAILED,
  UPDATE_ORGANIZATION_FAILED,
  CREATE_ORGANIZATION_FAILED,
} from '../constants/organizations';

export const fetchOrganizations = () => ({
  type: FETCH_ORGANIZATIONS,
});

export const fetchOrganizationsFailed = () => ({
  type: FETCH_ORGANIZATIONS_FAILED,
});

export const fetchOrganizationsFullfilled = payload => ({
  type: FETCH_ORGANIZATIONS_SUCCESS,
  payload,
});

export const addAgent = payload => ({ type: ADD_AGENT, payload });

export const addAgentSuccess = payload => {
  return { type: ADD_AGENT_SUCCESS, payload };
};

export const addAgentFailed = () => {
  return { type: ADD_AGENT_FAILED };
};

export const fetchOrganization = payload => ({
  type: FETCH_ORGANIZATION,
  payload,
});

export const fetchOrganizationSuccess = payload => ({
  type: FETCH_ORGANIZATION_SUCCESS,
  payload,
});

export const fetchOrganizationFailed = () => ({
  type: FETCH_ORGANIZATION_FAILED,
});

export const createOrganization = payload => ({
  type: CREATE_ORGANIZATION,
  payload,
});

export const createOrganizationSuccess = payload => ({
  type: CREATE_ORGANIZATION_SUCCESS,
  payload,
});

export const createOrganizationFailed = () => ({
  type: CREATE_ORGANIZATION_FAILED,
});

export const updateOrganization = payload => ({
  type: UPDATE_ORGANIZATION,
  payload,
});

export const updateOrganizationFailed = () => ({
  type: UPDATE_ORGANIZATION_FAILED,
});

export const updateOrganizationSuccess = payload => ({
  type: UPDATE_ORGANIZATION_SUCCESS,
  payload,
});

export const handleOrganization = payload => ({
  type: HANDLE_ORGANIZATION,
  payload,
});

export const resetOrganization = () => ({
  type: RESET_ORGANIZATION,
});

export const changeAgentStatus = payload => ({
  type: CHANGE_AGENT_STATUS,
  payload,
});

export const changeAgentStatusSuccess = payload => ({
  type: CHANGE_AGENT_STATUS_SUCCESS,
  payload,
});

export const changeAgentStatusFailed = () => ({
  type: CHANGE_AGENT_STATUS_FAILED,
});
