import React from 'react';
import { USER_ROLES } from '../../../constants';

const IssueTicket = React.lazy(() => import('../index'));

export const IssueTicketPaths = {
  Index: '/v2/issue-ticket/',
};

export const IssueTicketHeading = {
  [IssueTicketPaths.Index]: 'Issue Ticket',
};

const IssueTicketRoutes = [
  {
    path: IssueTicketPaths.Index,
    component: IssueTicket,
    private: true,
    exact: true,
    role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
  },
];

export default IssueTicketRoutes;
