import { AgentDetailsLimitRequest, Organization } from 'src/types';
import {
  ADD_AGENT,
  ADD_AGENT_FAILED,
  ADD_AGENT_SUCCESS,
  CHANGE_AGENT_STATUS,
  CHANGE_AGENT_STATUS_FAILED,
  CHANGE_AGENT_STATUS_SUCCESS,
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_FAILED,
  FETCH_ORGANIZATION_SUCCESS,
  HANDLE_ORGANIZATION,
  RESET_ORGANIZATION,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILED,
  CREATE_ORGANIZATION,
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_FAILED,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAILED,
} from '../constants/organizations';
import { Action, OrganizationsReducer } from '../types';

const initialState: OrganizationsReducer = {
  organization: null,
  isFetchingOrganization: false,
  isFetchingOrganizations: false,
  isUpdating: false,
  isChangingStatus: false,
  isAddingAgent: false,
  isCreating: false,
  organizations: [],
};

const organizationReducer = (
  state = initialState,
  action: Action
): OrganizationsReducer => {
  switch (action.type) {
    case FETCH_ORGANIZATIONS:
      return { ...state, isFetchingOrganizations: true };
    case FETCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        isFetchingOrganizations: false,
        organizations: action.payload,
      };
    case FETCH_ORGANIZATIONS_FAILED:
      return { ...state, isFetchingOrganizations: false };
    case ADD_AGENT:
      return { ...state, isAddingAgent: true };
    case ADD_AGENT_FAILED:
      return { ...state, isAddingAgent: false };
    case ADD_AGENT_SUCCESS:
      return {
        ...state,
        isAddingAgent: false,
        organization: {
          ...state?.organization,
          agents: [
            action.payload,
            ...(state?.organization?.agents as AgentDetailsLimitRequest[]),
          ],
        } as Organization,
      };
    case FETCH_ORGANIZATION:
      return { ...state, isFetchingOrganization: true };
    case FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isFetchingOrganization: false,
        organization: action.payload,
      };
    case FETCH_ORGANIZATION_FAILED:
      return { ...state, isFetchingOrganization: false };
    case HANDLE_ORGANIZATION:
      return {
        ...state,
        organization: { ...state?.organization, ...action.payload },
      };
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organization: { ...state?.organization, ...action.payload },
        isUpdating: false,
      };
    case UPDATE_ORGANIZATION_FAILED:
      return {
        ...state,
        isUpdating: false,
      };
    case CHANGE_AGENT_STATUS:
      return {
        ...state,
        isChangingStatus: true,
      };
    case CHANGE_AGENT_STATUS_SUCCESS:
      return {
        ...state,
        isChangingStatus: false,
        organization: {
          ...state?.organization,
          agents: state?.organization?.agents.map(agent =>
            agent?.id === action?.payload?.id ? action.payload : agent
          ) as AgentDetailsLimitRequest[],
        } as Organization,
      };
    case CHANGE_AGENT_STATUS_FAILED:
      return {
        ...state,
        isChangingStatus: false,
      };

    case CREATE_ORGANIZATION:
      return {
        ...state,
        isCreating: true,
      };

    case CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isCreating: false,
        organizations: [...state.organizations, action.payload],
      };

    case CREATE_ORGANIZATION_FAILED:
      return {
        ...state,
        isCreating: false,
      };

    case RESET_ORGANIZATION:
      return { ...state, isFetchingOrganization: false, organization: null };
    default:
      return state;
  }
};

export default organizationReducer;
