import {
  FETCH_AIRLINE_STATUS,
  FETCH_AIRLINE_STATUS_FAILED,
  FETCH_AIRLINE_STATUS_SUCCESS,
  RESET,
} from '../constants/airline';
import { Action, AirlineReducer } from '../types';

const initialState: AirlineReducer = {
  isFetchingAirlineStatusList: false,
  airlineStatusList: [],
};

const airlineReducer = (
  state = initialState,
  action: Action
): AirlineReducer => {
  switch (action.type) {
    case RESET: {
      return {
        ...initialState,
      };
    }

    case FETCH_AIRLINE_STATUS: {
      return {
        ...state,
        isFetchingAirlineStatusList: true,
      };
    }

    case FETCH_AIRLINE_STATUS_SUCCESS: {
      return {
        ...state,
        isFetchingAirlineStatusList: false,
        airlineStatusList: action.payload,
      };
    }

    case FETCH_AIRLINE_STATUS_FAILED: {
      return {
        ...state,
        isFetchingAirlineStatusList: false,
      };
    }

    default:
      return state;
  }
};

export default airlineReducer;
