import { call, put, takeLatest } from 'redux-saga/effects';
import { getAirlineStatusesService } from 'src/services/airlineServices';
import {
  fetchAirlineStatusFailed,
  fetchAirlineStatusFullfilled,
} from '../actions/airline';
import { FETCH_AIRLINE_STATUS } from '../constants/airline';

function* fetchAirlineStatus() {
  try {
    const response = yield call(getAirlineStatusesService);
    if (response.ok && response.data?.success) {
      yield put(fetchAirlineStatusFullfilled(response.data.response));
    } else {
      yield put(fetchAirlineStatusFailed());
    }
  } catch (err) {
    yield put(fetchAirlineStatusFailed());
  }
}

export function* fetchAirlineStatusWatcher() {
  yield takeLatest(FETCH_AIRLINE_STATUS, fetchAirlineStatus);
}
