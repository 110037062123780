import {
  CREATE_PAYMENT,
  CREATE_PAYMENT_FAILED,
  CREATE_PAYMENT_SUCCESS,
  FETCH_PAYMENT,
  FETCH_PAYMENTS,
  FETCH_PAYMENTS_FAILED,
  FETCH_PAYMENTS_SUCCESS,
  FETCH_PAYMENT_FAILED,
  FETCH_PAYMENT_SUCCESS,
  RESET_PAYMENT,
} from '../constants/payments';

export const fetchPayments = (page = 1) => ({
  type: FETCH_PAYMENTS,
  payload: {
    page,
  },
});

export const fetchPaymentsFullfilled = payload => ({
  type: FETCH_PAYMENTS_SUCCESS,
  payload,
});

export const fetchPaymentsFailed = () => ({
  type: FETCH_PAYMENTS_FAILED,
});

export const createPayment = payload => ({
  type: CREATE_PAYMENT,
  payload,
});

export const createPaymentFullfilled = payload => ({
  type: CREATE_PAYMENT_SUCCESS,
  payload,
});

export const createPaymentFailed = () => ({
  type: CREATE_PAYMENT_FAILED,
});

export const fetchPayment = payload => ({
  type: FETCH_PAYMENT,
  payload,
});

export const fetchPaymentFullfilled = payload => ({
  type: FETCH_PAYMENT_SUCCESS,
  payload,
});

export const fetchPaymentFailed = () => ({
  type: FETCH_PAYMENT_FAILED,
});

export const resetPayment = () => ({
  type: RESET_PAYMENT,
});
