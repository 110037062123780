import { HTTP_METHODS } from 'src/enums/http-methods';
import apiService from './api';

export const initiateBookingService = data => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: 'v1/flights/bookings/initiate/',
    data,
  });
};

export const initiateBookingHotelService = data => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: 'v1/hotels/bookings/initiate/',
    data,
  });
};

export const initiateBookingTourService = data => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: 'v1/tours/bookings/initiate/',
    data,
  });
};

export const confirmBookingService = data => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: 'v1/flights/bookings/confirm/',
    data,
  });
};

export const confirmBookingHotelService = data => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: 'v1/hotels/bookings/confirm/',
    data,
  });
};

export const confirmBookingTourService = data => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: 'v1/tours/bookings/confirm/',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const confirmDummyBookingTourService = data => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: 'v1/tours/bookings/democonfirm/',
    data,
  });
};

export const issueBookingHotelService = data => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: 'v1/hotels/bookings/issue/',
    data,
  });
};

export const getBookingDetails = booking_id => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: `v2/flights/bookings/${booking_id}`,
  });
};

export const bookingsDetailService = booking_id => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: `v1/flights/bookings/${booking_id}`,
  });
};

export const bookingsListServiceV2 = params => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: 'v2/bookings/',
    params,
  });
};

export const bookingsDetailServiceV2 = booking_id => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: `v2/bookings/${booking_id}`,
  });
};

export const downloadBookingTicketService = booking_id => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: `/download-ticket/${booking_id}`,
    responseType: 'blob',
  });
};

export const getAirlineTicketsService = data => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: `/download-airline-ticket`,
    data,
  });
};

export const cancelBookingService = data => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: `/v2/flights/bookings/cancel/`,
    data,
  });
};
