import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import OtpInput from 'react-otp-input';
import './agOtp.scss';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CombineReducerType } from '../../redux/types';
import { createOtp } from '../../redux/actions/otp';
import { EnterOtpDialogPropsType } from './types/EnterOtpDialogPropsType';
import { OTP_TIME_LIMITS } from '../../constants';
import { genTestId } from '../../services/utils';
import { ELEMENT_TYPES } from '../../enums/element_types';

export default function EnterOtpDialog(props: EnterOtpDialogPropsType) {
  const {
    mainButtonText,
    open,
    cancellable,
    readonly,
    otpToCopy,
    closeDialog,
    submitCallback = () => {},
  } = props;
  const dispatch = useDispatch();
  const [otp, updateOtp] = useState(readonly ? otpToCopy!.otp : '');
  const [disableEverything, toggleDisableEverything] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resendOtpSubmitting, setResendOtpSubmitting] =
    useState<boolean>(false);
  const [copyToClipboard, updateCopyToClipboard] = useState<boolean>(false);
  const { isCreating: resendingOtp } = useSelector(
    (state: CombineReducerType) => state.otp
  );

  useEffect(() => {
    const disable = [isSubmitting, resendOtpSubmitting].some(v => v);
    toggleDisableEverything(disable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, resendOtpSubmitting]);

  useEffect(() => {
    setResendOtpSubmitting(resendingOtp);
  }, [resendingOtp]);

  useEffect(() => {
    setIsSubmitting(props.showSubmitLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showSubmitLoading]);

  const onSubmit = async () => {
    if (disableEverything) return;
    if (otp.length < 6) return;
    submitCallback(otp);
  };

  const resendOtp = () => {
    if (disableEverything) return;
    dispatch(createOtp({ total_mins: 30 }));
  };

  const copyOtpToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(otp);
      updateCopyToClipboard(true);
      await new Promise(() => setTimeout(() => closeDialog(), 1000));
    } catch (e) {
      updateCopyToClipboard(false);
    }
  };

  const ReadOnlyActions = () => (
    <Button
      id={genTestId(ELEMENT_TYPES.BUTTON, 'copy-OTP', 'otp-management')}
      fullWidth
      className="otp-action-button-copy"
      onClick={copyOtpToClipBoard}
      disabled={copyToClipboard}
    >
      {copyToClipboard ? 'Copied!' : 'Copy Code'}
    </Button>
  );

  const EnterOtpActions = () => (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <LoadingButton
          id={genTestId(ELEMENT_TYPES.BUTTON, 'submit-OTP', 'otp-management')}
          className="otp-action-button"
          type="submit"
          fullWidth
          loadingPosition="end"
          disableElevation
          variant="contained"
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={() => onSubmit()}
        >
          {mainButtonText ? mainButtonText : 'Submit'}
        </LoadingButton>
      </Grid>
      <Grid item xs={3}>
        <LoadingButton
          id={genTestId(ELEMENT_TYPES.BUTTON, 'resend-OTP', 'otp-management')}
          fullWidth
          className="otp-action-button-resend"
          loading={resendOtpSubmitting}
          variant={resendOtpSubmitting ? 'outlined' : 'text'}
          disabled={resendOtpSubmitting}
          onClick={() => resendOtp()}
        >
          {resendOtpSubmitting ? 'Sending' : 'Resend Otp'}
        </LoadingButton>
      </Grid>
    </Grid>
  );

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => {
        if (disableEverything) return;
        if (!cancellable) return;
        closeDialog();
      }}
    >
      <DialogTitle>
        <p className="otp-dialog-title theme h5">
          {readonly ? 'OTP Code' : 'Enter OTP'}
        </p>
      </DialogTitle>
      <DialogContent>
        {readonly ? (
          <p className="otp-dialog-title">
            Please find the <strong>OTP code</strong> below, This otp will
            expire in <strong>{OTP_TIME_LIMITS[otpToCopy!.expiry]}</strong>
          </p>
        ) : (
          <p className="otp-dialog-title">
            Please enter <strong>OTP</strong> sent on your registered email
            address
          </p>
        )}

        <OtpInput
          value={otp}
          onChange={updateOtp}
          numInputs={6}
          isDisabled={disableEverything || readonly}
          className="otp-input"
          inputStyle="otp-input-field"
          errorStyle="otp-input-field-error"
          shouldAutoFocus={true}
        />
      </DialogContent>
      <DialogActions sx={{ padding: '1em' }}>
        {readonly ? <ReadOnlyActions /> : <EnterOtpActions />}
      </DialogActions>
    </Dialog>
  );
}
