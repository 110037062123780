import React from 'react';
import { USER_ROLES } from '../../../constants';
const BookingDetail = React.lazy(
  () => import('../components/BookingDetail/BookingDetail')
);

export const MyBookingUrls = {
  BOOKING_DETAIL: bookingId => `/booking-detail/${bookingId}`,
};

export const MyBookingPaths = {
  BOOKING_DETAIL: '/booking-detail/:booking_id',
};

const MyBookingRoutes = [
  {
    path: MyBookingPaths.BOOKING_DETAIL,
    component: BookingDetail,
    private: true,
    exact: true,
    role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
  },
];

export default MyBookingRoutes;
