import { HTTP_METHODS } from 'src/enums/http-methods';
import apiService from './api';

export const getDashoardDataService = payload => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: 'v1/insights/booking/aggregate',
    params: payload,
  });
};

export const getBookingExpiryService = () => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: 'v1/insights/booking/expiry/soon',
  });
};
