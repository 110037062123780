import {
  FETCH_AGENT_BALANCE,
  FETCH_AGENT_BALANCE_FAILED,
  FETCH_AGENT_BALANCE_SUCCESS,
  RESET,
} from '../constants/agentBalance';

export const resetAgentBalance = () => {
  return {
    type: RESET,
  };
};

export const fetchAgentBalance = payload => ({
  type: FETCH_AGENT_BALANCE,
  payload,
});
export const fetchAgentBalanceFullfilled = payload => ({
  type: FETCH_AGENT_BALANCE_SUCCESS,
  payload,
});

export const fetchAgentBalanceFailed = () => ({
  type: FETCH_AGENT_BALANCE_FAILED,
});
