import {
  FETCH_AIRLINE_STATUS,
  FETCH_AIRLINE_STATUS_SUCCESS,
  FETCH_AIRLINE_STATUS_FAILED,
  RESET,
} from '../constants/airline';

export const resetAirline = () => {
  return {
    type: RESET,
  };
};

export const fetchAirlineStatus = () => ({
  type: FETCH_AIRLINE_STATUS,
});

export const fetchAirlineStatusFullfilled = payload => ({
  type: FETCH_AIRLINE_STATUS_SUCCESS,
  payload,
});

export const fetchAirlineStatusFailed = () => ({
  type: FETCH_AIRLINE_STATUS_FAILED,
});
