import {
  CANCEL_FLIGHT_BOOKING,
  CONFIRM_BOOKING_TOUR,
  CONFIRM_DUMMY_BOOKING_TOUR,
  DOWNLOAD_BOOKING,
  INITIATE_BOOKING_TOUR,
} from './../constants/booking';
import { Action } from '../types';
import download from 'downloadjs';
import {
  initiateBookingFulfilled,
  initiateBookingFailed,
  fetchBookingFulfilled,
  fetchBookingFailed,
  confirmBookingFulfilled,
  confirmBookingFailed,
  issueBookingFailed,
  issueBookingFulfilled,
  fetchActiveBookingFulfilled,
  fetchActiveBookingFailed,
  downloadBookingCompleted,
  getAirlineTicketsCompleted,
  getAirlineTicketsFailed,
  cancelFlightBookingCompleted,
  cancelFlightBookingFailed,
} from '../actions/booking';
import {
  CONFIRM_BOOKING_HOTEL,
  FETCH_ACTIVE_BOOKING,
  FETCH_BOOKING,
  INITIATE_BOOKING_FLIGHT,
  INITIATE_BOOKING_HOTEL,
  ISSUE_BOOKING_HOTEL,
  GET_AIRLINE_TICKETS,
} from '../constants/booking';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  bookingsDetailServiceV2,
  bookingsListServiceV2,
  confirmBookingHotelService,
  confirmBookingTourService,
  confirmDummyBookingTourService,
  downloadBookingTicketService,
  getAirlineTicketsService,
  initiateBookingHotelService,
  initiateBookingService,
  initiateBookingTourService,
  issueBookingHotelService,
  cancelBookingService,
} from 'src/services/bookingServices';
import Analytics, { logActionEvent } from '../../services/analytics';
import { EVENT_NAME } from '../../constants';
import { TOURS_ANALAYTICS } from 'src/containers/Umrah/utils/analaytics/ToursAnalayticsEvents';

function* initiateBooking(action: Action) {
  let { loadingContext, apiPayload, onSuccessHandler } = action.payload;
  try {
    loadingContext.start();
    const response = yield call(initiateBookingService, apiPayload);
    loadingContext.stop();
    if (response.ok && response.data?.success) {
      let { booking_id } = response.data?.data;
      onSuccessHandler(booking_id);

      const booking = response.data?.data;
      yield put(
        initiateBookingFulfilled({
          key: action.payload.booking_key,
          value: booking,
        })
      );
    } else {
      yield put(
        initiateBookingFailed({
          error:
            response.data?.message ||
            'Something went wrong while initiating booking.',
        })
      );
    }
  } catch (err) {
    loadingContext.stop();
    yield put(
      initiateBookingFailed({
        error: err,
      })
    );
  }
}

function* initiateBookingHotel(action: Action) {
  let { apiPayload, onSuccessHandler, onFailureHandler } = action.payload;
  try {
    Analytics.logActionEvent(EVENT_NAME.AG_HOTEL_BOOKING_INITIATED, apiPayload);
    const response = yield call(initiateBookingHotelService, apiPayload);
    if (response.ok && response.data?.success) {
      let { booking_id } = response.data?.data;
      const booking = response.data?.data;
      onSuccessHandler(booking_id);
      yield put(
        initiateBookingFulfilled({
          key: booking_id,
          value: booking,
        })
      );
      Analytics.logActionEvent(
        EVENT_NAME.AG_HOTEL_BOOKING_COMPLETED,
        apiPayload
      );
    } else {
      onFailureHandler(response.data?.message);
      yield put(
        initiateBookingFailed({
          error:
            response.data?.message ||
            'Something went wrong while initiating booking.',
        })
      );
      Analytics.logActionEvent(EVENT_NAME.AG_HOTEL_BOOKING_FAILED, {
        payload: apiPayload,
        error: response,
      });
    }
  } catch (err) {
    onFailureHandler(err);
    yield put(
      initiateBookingFailed({
        error: err,
      })
    );
    Analytics.logActionEvent(EVENT_NAME.AG_HOTEL_BOOKING_FAILED, {
      payload: apiPayload,
      error: err,
    });
  }
}

function* initiateBookingTour(action: Action) {
  let { apiPayload, onSuccessHandler, onFailureHandler } = action.payload;
  try {
    const response = yield call(initiateBookingTourService, apiPayload);
    if (response.ok && response.data?.success) {
      let { booking_id } = response.data?.data;
      const booking = response.data?.data;
      onSuccessHandler(booking_id);
      yield put(
        initiateBookingFulfilled({
          key: booking_id,
          value: booking,
        })
      );
    } else {
      onFailureHandler(response.data?.message);
      yield put(
        initiateBookingFailed({
          error:
            response.data?.message ||
            'Something went wrong while initiating booking.',
        })
      );
    }
  } catch (err) {
    onFailureHandler(err);
    yield put(
      initiateBookingFailed({
        error: err,
      })
    );
  }
}

function* confirmBookingHotel(action: Action) {
  let { apiPayload, onSuccessHandler } = action.payload;
  try {
    Analytics.logActionEvent(
      EVENT_NAME.AG_HOTEL_CONFIRM_BOOKING_INITIATED,
      apiPayload
    );
    const response = yield call(confirmBookingHotelService, apiPayload);
    if (response.ok && response.data?.success) {
      let { booking_id } = response.data?.data;
      const booking = response.data?.data;
      onSuccessHandler(booking_id);
      yield put(
        confirmBookingFulfilled({
          key: booking_id,
          value: booking,
        })
      );
      Analytics.logActionEvent(
        EVENT_NAME.AG_HOTEL_CONFIRM_BOOKING_COMPLETED,
        apiPayload
      );
    } else {
      yield put(confirmBookingFailed());
      Analytics.logActionEvent(EVENT_NAME.AG_HOTEL_CONFIRM_BOOKING_FAILED, {
        payload: apiPayload,
        error: response,
      });
    }
  } catch (err) {
    yield put(confirmBookingFailed());
    Analytics.logActionEvent(EVENT_NAME.AG_HOTEL_CONFIRM_BOOKING_FAILED, {
      payload: apiPayload,
      error: err,
    });
  }
}

function* confirmDummyBookingTour(action: Action) {
  let { apiPayload, onSuccessHandler, loadingContext } = action.payload;
  loadingContext.start();
  try {
    logActionEvent(
      TOURS_ANALAYTICS.ACTION_DUMMY_TOUR_BOOKING_CONFIRM_INITIALIZED
    );
    const response = yield call(confirmDummyBookingTourService, apiPayload);
    if (response.ok && response.data?.success) {
      let { booking_id } = response.data?.data;
      const booking = response.data?.data;
      loadingContext.stop();
      logActionEvent(
        TOURS_ANALAYTICS.ACTION_DUMMY_TOUR_BOOKING_CONFIRM_SUCCESS
      );
      onSuccessHandler(booking_id);
      yield put(
        confirmBookingFulfilled({
          key: booking_id,
          value: booking,
        })
      );
    } else {
      logActionEvent(TOURS_ANALAYTICS.ACTION_DUMMY_TOUR_BOOKING_CONFIRM_ERROR);
      loadingContext.stop();
      yield put(confirmBookingFailed());
    }
  } catch (err) {
    logActionEvent(TOURS_ANALAYTICS.ACTION_DUMMY_TOUR_BOOKING_CONFIRM_ERROR);
    loadingContext.stop();
    yield put(confirmBookingFailed());
  }
}

function* confirmBookingTour(action: Action) {
  let { apiPayload, onSuccessHandler, loadingContext } = action.payload;
  loadingContext.start();
  try {
    logActionEvent(TOURS_ANALAYTICS.ACTION_TOUR_BOOKING_CONFIRM_INITIALIZED);
    const response = yield call(confirmBookingTourService, apiPayload);
    if (response.ok && response.data?.success) {
      let { booking_id } = response.data?.data;
      const booking = response.data?.data;
      loadingContext.stop();
      logActionEvent(TOURS_ANALAYTICS.ACTION_TOUR_BOOKING_CONFIRM_SUCCESS);
      onSuccessHandler(booking_id);
      yield put(
        confirmBookingFulfilled({
          key: booking_id,
          value: booking,
        })
      );
    } else {
      logActionEvent(TOURS_ANALAYTICS.ACTION_TOUR_BOOKING_CONFIRM_ERROR);
      loadingContext.stop();
      yield put(confirmBookingFailed());
    }
  } catch (err) {
    logActionEvent(TOURS_ANALAYTICS.ACTION_TOUR_BOOKING_CONFIRM_ERROR);
    loadingContext.stop();
    yield put(confirmBookingFailed());
  }
}

function* issueBookingHotel(action: Action) {
  let { apiPayload, onSuccessHandler, onFailureHandler } = action.payload;
  try {
    Analytics.logActionEvent(
      EVENT_NAME.AG_HOTEL_ISSUE_BOOKING_INITIATED,
      apiPayload
    );
    const response = yield call(issueBookingHotelService, apiPayload);
    if (response.ok && response.data?.success) {
      let { booking_id } = response.data?.data;
      const booking = response.data?.data;
      onSuccessHandler(booking_id);
      yield put(
        issueBookingFulfilled({
          key: booking_id,
          value: booking,
        })
      );
      Analytics.logActionEvent(
        EVENT_NAME.AG_HOTEL_ISSUE_BOOKING_COMPLETED,
        apiPayload
      );
    } else {
      onFailureHandler(response.data?.message);
      yield put(issueBookingFailed());
      Analytics.logActionEvent(EVENT_NAME.AG_HOTEL_ISSUE_BOOKING_FAILED, {
        payload: apiPayload,
        error: response,
      });
    }
  } catch (err) {
    onFailureHandler();
    yield put(issueBookingFailed());
    Analytics.logActionEvent(EVENT_NAME.AG_HOTEL_ISSUE_BOOKING_FAILED, {
      payload: apiPayload,
      error: err,
    });
  }
}

function* fetchBookings(action: Action) {
  try {
    const response = yield call(bookingsListServiceV2, action.payload);
    if (response.ok && response.data?.results) {
      yield put(fetchBookingFulfilled(response.data));
    } else {
      yield put(fetchBookingFailed());
    }
  } catch (err) {
    yield put(fetchBookingFailed());
  }
}

function* fetchActiveBooking(action: Action) {
  try {
    const response = yield call(bookingsDetailServiceV2, action.payload);
    if (response.ok && response.data?.data) {
      yield put(
        fetchActiveBookingFulfilled({
          booking_id: action.payload,
          data: response.data.data,
        })
      );
    } else {
      yield put(fetchActiveBookingFailed());
    }
  } catch (err) {
    console.log('err:::', err);
    yield put(fetchActiveBookingFailed());
  }
}

function* downloadBooking(action: Action) {
  try {
    const response = yield call(downloadBookingTicketService, action.payload);
    console.log({ response });
    if (response.ok) {
      yield put(downloadBookingCompleted());
      download(response.data, 'ticket.pdf');
    } else {
      yield put(downloadBookingCompleted());
    }
  } catch (err) {
    yield put(downloadBookingCompleted());
  }
}

function* sendAirlineTickets(action: Action) {
  const { data, onSuccessHandler } = action.payload;
  try {
    const response = yield call(getAirlineTicketsService, data);
    if (response.ok) {
      onSuccessHandler();
      yield put(getAirlineTicketsCompleted());
    } else {
      const emailError = response?.data?.message || '';
      yield put(getAirlineTicketsFailed(emailError));
    }
  } catch (err: any) {
    yield put(getAirlineTicketsFailed('Somthing went wrong'));
  }
}

function* cancelBooking(action: Action) {
  try {
    const { data, onSuccessHandler } = action.payload;
    const response = yield call(cancelBookingService, data);
    if (response.ok) {
      onSuccessHandler();
      yield put(cancelFlightBookingCompleted());
    } else {
      const error = response?.data?.message || '';
      yield put(cancelFlightBookingFailed(error));
    }
  } catch (err) {
    yield put(cancelFlightBookingFailed('Somthing went wrong'));
  }
}

export function* initiateBookingWatcher() {
  yield takeLatest(INITIATE_BOOKING_FLIGHT, initiateBooking);
}

export function* initiateBookingHotelWatcher() {
  yield takeLatest(INITIATE_BOOKING_HOTEL, initiateBookingHotel);
}

export function* initiateBookingTourWatcher() {
  yield takeLatest(INITIATE_BOOKING_TOUR, initiateBookingTour);
}

export function* confirmBookingHotelWatcher() {
  yield takeLatest(CONFIRM_BOOKING_HOTEL, confirmBookingHotel);
}

export function* confirmBookingTourWatcher() {
  yield takeLatest(CONFIRM_BOOKING_TOUR, confirmBookingTour);
}

export function* confirmDummyBookingTourWatcher() {
  yield takeLatest(CONFIRM_DUMMY_BOOKING_TOUR, confirmDummyBookingTour);
}

export function* issueBookingHotelWatcher() {
  yield takeLatest(ISSUE_BOOKING_HOTEL, issueBookingHotel);
}

export function* fetchBookingWatcher() {
  yield takeLatest(FETCH_BOOKING, fetchBookings);
}

export function* fetchActiveBookingWatcher() {
  yield takeLatest(FETCH_ACTIVE_BOOKING, fetchActiveBooking);
}

export function* downloadBookingWatcher() {
  yield takeLatest(DOWNLOAD_BOOKING, downloadBooking);
}

export function* getAirlineTicketsWatcher() {
  yield takeLatest(GET_AIRLINE_TICKETS, sendAirlineTickets);
}

export function* cancelBookingWatcher() {
  yield takeLatest(CANCEL_FLIGHT_BOOKING, cancelBooking);
}
