import { HTTP_METHODS } from 'src/enums/http-methods';
import apiService from './api';

export const getAirportsService = (query: string) => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    // baseURL:`https://cors-anywhere.herokuapp.com/${process.env.REACT_APP_AG_API_URL}`,
    url: 'v1/flights/locations',
    params: { query },
  });
};
