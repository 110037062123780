export const FETCH_OTP = 'FETCH_OTP';
export const FETCH_OTP_SUCCESS = 'FETCH_OTP_SUCCESS';
export const FETCH_OTP_FAILED = 'FETCH_OTP_FAILED';
export const CREATE_OTP = 'CREATE_OTP';
export const CREATE_OTP_SUCCESS = 'CREATE_OTP_SUCCESS';
export const CREATE_OTP_FAILED = 'CREATE_OTP_FAILED';
export const EXPIRE_OTP = 'EXPIRE_OTP';
export const EXPIRE_OTP_FAILED = 'EXPIRE_OTP_FAILED';
export const HANDLE_CURRENT_PAGE = 'HANDLE_CURRENT_PAGE';
export const RESET = 'RESET';
