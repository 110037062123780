import React from 'react';
import { USER_ROLES } from '../../../constants';

const FIT = React.lazy(() => import('../index'));

export const FITUrls = {
  HotelsList: fitId => `/fit/${fitId}`,
};

export const FITPaths = {
  Index: '/fit/',
  HotelsList: '/fit/:fit_id',
};

export const FITHeading = {
  [FITPaths.Index]: 'FIT',
  [FITPaths.HotelsList]: 'FIT',
};

const FITRoutes = [
  {
    path: FITPaths.Index,
    component: FIT,
    private: true,
    exact: true,
    role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
  },
  {
    path: FITPaths.HotelsList,
    component: FIT,
    private: true,
    exact: true,
    role: [USER_ROLES.agent, USER_ROLES.agSuperUser],
  },
];

export default FITRoutes;
