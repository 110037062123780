const colors = {
  placeholderColor: '#768192',
  sidebar: '#3C4B64',
  error: 'indianred',
  darkBlue: '#0054A0',
  secondaryGreen: '#5FB95D',
};

export default {
  colors,
};
