export const FETCH_SALES_USER_TYPES = 'FETCH_SALES_USER_TYPES';
export const FETCH_SALES_USER_TYPES_SUCCESS = 'FETCH_SALES_USER_TYPES_SUCCESS';
export const FETCH_SALES_USER_TYPES_FAILED = 'FETCH_SALES_USER_TYPES_FAILED';

export const FETCH_FILTERED_AGENTS_LIMIT_REQUESTS =
  'FETCH_FILTERED_AGENTS_LIMIT_REQUESTS';

export const FETCH_AGENTS_LIMIT_REQUESTS = 'FETCH_AGENTS_LIMIT_REQUESTS';
export const FETCH_AGENTS_LIMIT_REQUESTS_SUCCESS =
  'FETCH_AGENTS_LIMIT_REQUESTS_SUCCESS';
export const FETCH_AGENTS_LIMIT_REQUESTS_FAILED =
  'FETCH_AGENTS_LIMIT_REQUESTS_FAILED';

export const FETCH_AGENTS_LIMIT_REQUESTS_NEW_SUCCESS =
  'FETCH_AGENTS_LIMIT_REQUESTS_NEW_SUCCESS';
export const FETCH_AGENTS_LIMIT_REQUESTS_APPROVED_SUCCESS =
  'FETCH_AGENTS_LIMIT_REQUESTS_APPROVED_SUCCESS';
export const FETCH_AGENTS_LIMIT_REQUESTS_CANCELLED_SUCCESS =
  'FETCH_AGENTS_LIMIT_REQUESTS_CANCELLED_SUCCESS';
export const FETCH_AGENTS_LIMIT_REQUESTS_PENDING_SUCCESS =
  'FETCH_AGENTS_LIMIT_REQUESTS_PENDING_SUCCESS';

export const FETCH_AGENTS_LIMIT_REQUESTS_BY_ID_SUCCESS =
  'FETCH_AGENTS_LIMIT_REQUESTS_BY_ID_SUCCESS';
export const FETCH_AGENTS_LIMIT_REQUESTS_BY_ID =
  'FETCH_AGENTS_LIMIT_REQUESTS_BY_ID';

export const UPDATE_AGENT_LIMIT_REQUEST = 'UPDATE_AGENT_LIMIT_REQUEST';
export const UPDATE_AGENT_LIMIT_REQUEST_SUCCESS =
  'UPDATE_AGENT_LIMIT_REQUEST_SUCCESS';
export const UPDATE_AGENT_LIMIT_REQUEST_FAILED =
  'UPDATE_AGENT_LIMIT_REQUEST_FAILED';

export const UPDATE_AGENT_LIMIT_REQUEST_STATUS =
  'UPDATE_AGENT_LIMIT_REQUEST_STATUS';

export const RESET = 'RESET';
