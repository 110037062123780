import { capitalize } from 'src/utils/helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createAgentEmailService,
  createOrgService,
  getOrganizationsService,
  getProfileByIdService,
  toggleAgentProfileStatusService,
  updateOrganizationByIdService,
} from 'src/services/organizationServices';
import {
  changeAgentStatusFailed,
  changeAgentStatusSuccess,
  fetchOrganizationSuccess,
  fetchOrganizationFailed,
  updateOrganizationSuccess,
  updateOrganizationFailed,
  addAgentSuccess,
  addAgentFailed,
  createOrganizationSuccess,
  createOrganizationFailed,
  fetchOrganizationsFullfilled,
  fetchOrganizationsFailed,
} from '../actions/organizations';
import {
  ADD_AGENT,
  CHANGE_AGENT_STATUS,
  CREATE_ORGANIZATION,
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATIONS,
  UPDATE_ORGANIZATION,
} from '../constants/organizations';

function* changeAgentStatus(action) {
  try {
    const response = yield call(
      toggleAgentProfileStatusService,
      action.payload
    );
    if (response?.ok && response?.statusCode === 200) {
        yield put(changeAgentStatusSuccess(response.data.response));
    } else {
      yield put(changeAgentStatusFailed());
    }
  } catch (err) {
    yield put(changeAgentStatusFailed());
  }
}

function* fetchOrganization(action) {
  try {
    const response = yield call(getProfileByIdService, action.payload);
    if (response?.ok && response?.statusCode === 200) {
      yield put(fetchOrganizationSuccess(response?.data?.response));
    } else {
      yield put(fetchOrganizationFailed());
    }
  } catch (err) {
    yield put(fetchOrganizationFailed());
  }
}

function* updateOrganization(action) {
  const toastObj = { title: '', message: '', type: '' };
  try {
    const response = yield call(updateOrganizationByIdService, action.payload);
    if (response?.ok) {
      yield put(updateOrganizationSuccess(response?.data));
      toastObj.title = 'Organization Updated';
      toastObj.message = 'Organization updated successfully.';
      toastObj.type = 'success';
    } else {
      yield put(updateOrganizationFailed());
      throw Error;
    }
  } catch (err) {
    yield put(updateOrganizationFailed());
    toastObj.title = 'Failed';
    toastObj.message = 'Could not upate organization.';
    toastObj.type = 'error';
  }

  action.payload.showToast(toastObj);
}

function* addAgent(action) {
  try {
    const response = yield call(createAgentEmailService, action.payload);
    if (response.ok && response.data?.success) {
      yield put(addAgentSuccess(response.data.response));
    } else {
      yield put(addAgentFailed());
    }
  } catch (err) {
    yield put(addAgentFailed());
  }
  action.payload?.setIsAgentModal(false);
}

function* createOrganization(action) {
  const toastObj = { title: '', message: '', type: '' };
  try {
    const response = yield call(createOrgService, action.payload);
    if (response.ok) {
      yield put(createOrganizationSuccess(response.data.response));
    } else {
      yield put(createOrganizationFailed());
      Object.keys(response?.data!)?.forEach(key => {
        action.payload?.showToast({
          title: capitalize(key),
          message: response?.data![key][0],
          type: 'failure',
        });
      });
      throw Error;
    }
  } catch (err) {
    yield put(createOrganizationFailed());
  }

  action.payload?.showToast(toastObj);
  action.payload?.setIsAgentModal(false);
}

function* fetchOrganizations() {
  try {
    const response = yield call(getOrganizationsService);
    if (response.ok && response?.statusCode === 200) {
      yield put(fetchOrganizationsFullfilled(response?.data?.response));
    } else {
      yield put(fetchOrganizationsFailed());
    }
  } catch (err) {
    yield put(fetchOrganizationsFailed());
  }
}

export function* changeAgentStatusWatcher() {
  yield takeLatest(CHANGE_AGENT_STATUS, changeAgentStatus);
}

export function* fetchOrganizationWatcher() {
  yield takeLatest(FETCH_ORGANIZATION, fetchOrganization);
}

export function* updateOrganizationWatcher() {
  yield takeLatest(UPDATE_ORGANIZATION, updateOrganization);
}

export function* addAgentWatcher() {
  yield takeLatest(ADD_AGENT, addAgent);
}

export function* createOrganizationWatcher() {
  yield takeLatest(CREATE_ORGANIZATION, createOrganization);
}

export function* fetchOrganizationsWatcher() {
  yield takeLatest(FETCH_ORGANIZATIONS, fetchOrganizations);
}
