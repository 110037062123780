import { call, put, takeLatest } from 'redux-saga/effects';
import { getSettingsService } from 'src/services/appSettingsServices';
import {
  fetchAppSettingsFailed,
  fetchAppSettingsFullfilled,
} from '../actions/appsettings';
import { FETCH_APP_SETTINGS } from '../constants/appsettings';

function* fetchAppSettings() {
  try {
    const response = yield call(getSettingsService);
    if (response.ok && response.data?.success) {
      yield put(fetchAppSettingsFullfilled(response.data.response));
    } else {
      yield put(fetchAppSettingsFailed());
    }
  } catch (err) {
    yield put(fetchAppSettingsFailed());
  }
}

export function* fetchAppSettingsWatcher() {
  yield takeLatest(FETCH_APP_SETTINGS, fetchAppSettings);
}
