import React, { Suspense } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import { Route as RouteType } from 'src/types';
import routes from './routesConfig';
import ProtectedRoute from './ProtectedRoute';
import { useAuth } from 'src/contexts/authContext';
import Spinner from 'src/components/Spinner';
import { useToolbar } from '../contexts/toolbarContext';

const Loader = () => {
  return (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
};

const AppRoutes = () => {
  const { isAuthenticating } = useAuth();
  let location = useLocation();
  let toolbar = useToolbar();

  React.useEffect(() => {
    toolbar.setCurrentPathName(location.pathname);
  }, [location, toolbar]);

  if (isAuthenticating) {
    return <Spinner heightClass="vh-100" />;
  }
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routes.mainRoutes.map((route: RouteType) => {
          const RouteComponent = route.private ? ProtectedRoute : Route;
          return <RouteComponent key={route.path} {...route} permission={[]} />;
        })}
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
