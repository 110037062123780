import {
  FETCH_APP_SETTINGS,
  FETCH_APP_SETTINGS_SUCCESS,
  FETCH_APP_SETTINGS_FAILED,
  RESET,
} from '../constants/appsettings';
import { Action, AppSettingsReducer } from '../types';

const initialState: AppSettingsReducer = {
  isFetching: false,
  settings: [],
};

const appsettingsReducer = (
  state = initialState,
  action: Action
): AppSettingsReducer => {
  switch (action.type) {
    case RESET: {
      return {
        ...initialState,
      };
    }

    case FETCH_APP_SETTINGS: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case FETCH_APP_SETTINGS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        settings: action.payload,
      };
    }

    case FETCH_APP_SETTINGS_FAILED: {
      return {
        ...state,
        isFetching: false,
      };
    }

    default:
      return state;
  }
};

export default appsettingsReducer;
