import {
  FETCH_AGENTS_LIMIT_REQUESTS_NEW_SUCCESS,
  FETCH_AGENTS_LIMIT_REQUESTS_PENDING_SUCCESS,
  FETCH_AGENTS_LIMIT_REQUESTS_CANCELLED_SUCCESS,
  FETCH_AGENTS_LIMIT_REQUESTS_APPROVED_SUCCESS,
  FETCH_AGENTS_LIMIT_REQUESTS_BY_ID_SUCCESS,
  FETCH_FILTERED_AGENTS_LIMIT_REQUESTS,
  FETCH_AGENTS_LIMIT_REQUESTS_BY_ID,
  UPDATE_AGENT_LIMIT_REQUEST,
  RESET,
  FETCH_SALES_USER_TYPES,
  FETCH_SALES_USER_TYPES_SUCCESS,
  FETCH_SALES_USER_TYPES_FAILED,
  FETCH_AGENTS_LIMIT_REQUESTS,
  FETCH_AGENTS_LIMIT_REQUESTS_SUCCESS,
  FETCH_AGENTS_LIMIT_REQUESTS_FAILED,
  UPDATE_AGENT_LIMIT_REQUEST_SUCCESS,
  UPDATE_AGENT_LIMIT_REQUEST_FAILED,
} from '../constants/sales';
import { Action, SalesReducer } from '../types';

const initialState: SalesReducer = {
  isFetching: false,
  isUpdating: false,
  salesUserTypesList: [],
  agentsLimtRequests: [],
  salesAgentsLimtRequests: { 1: [], count: 1 },
  agentsLimtRequestsNew: { 1: [], count: 1 },
  agentsLimtRequestsPending: { 1: [], count: 1 },
  agentsLimtRequestsCancelled: { 1: [], count: 1 },
  agentsLimtRequestsApproved: { 1: [], count: 1 },
};

const sectorReducer = (state = initialState, action: Action): SalesReducer => {
  switch (action.type) {
    case RESET: {
      return {
        ...initialState,
      };
    }

    case FETCH_SALES_USER_TYPES: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_SALES_USER_TYPES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        salesUserTypesList: action.payload,
      };
    }
    case FETCH_SALES_USER_TYPES_FAILED: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_AGENTS_LIMIT_REQUESTS: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case FETCH_FILTERED_AGENTS_LIMIT_REQUESTS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_AGENTS_LIMIT_REQUESTS_BY_ID: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_AGENTS_LIMIT_REQUESTS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        salesAgentsLimtRequests: {
          ...state.salesAgentsLimtRequests,
          [action.payload.page]: action.payload.results,
          count: action.payload.total_pages,
        },
      };
    }
    case FETCH_AGENTS_LIMIT_REQUESTS_FAILED: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_AGENTS_LIMIT_REQUESTS_NEW_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        agentsLimtRequestsNew: {
          ...state.agentsLimtRequestsNew,
          [action.payload.page]: action.payload.results,
          count: action.payload.total_pages,
        },
        agentsLimtRequests: [
          ...state.agentsLimtRequests,
          ...action.payload.results,
        ],
      };
    }
    case FETCH_AGENTS_LIMIT_REQUESTS_PENDING_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        agentsLimtRequestsPending: {
          ...state.agentsLimtRequestsPending,
          [action.payload.page]: action.payload.results,
          count: action.payload.total_pages,
        },
        agentsLimtRequests: [
          ...state.agentsLimtRequests,
          ...action.payload.results,
        ],
      };
    }
    case FETCH_AGENTS_LIMIT_REQUESTS_CANCELLED_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        agentsLimtRequestsCancelled: {
          ...state.agentsLimtRequestsCancelled,
          [action.payload.page]: action.payload.results,
          count: action.payload.total_pages,
        },
        agentsLimtRequests: [
          ...state.agentsLimtRequests,
          ...action.payload.results,
        ],
      };
    }
    case FETCH_AGENTS_LIMIT_REQUESTS_APPROVED_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        agentsLimtRequestsApproved: {
          ...state.agentsLimtRequestsApproved,
          [action.payload.page]: action.payload.results,
          count: action.payload.total_pages,
        },
        agentsLimtRequests: [
          ...state.agentsLimtRequests,
          ...action.payload.results,
        ],
      };
    }

    case UPDATE_AGENT_LIMIT_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };

    case UPDATE_AGENT_LIMIT_REQUEST_SUCCESS: {
      const newAgentLimitRequests = [...state.agentsLimtRequests];
      const index = state.agentsLimtRequests.findIndex(
        req => req.id === action.payload.id
      );
      newAgentLimitRequests[index] = action.payload;
      return {
        ...state,
        isUpdating: false,
        agentsLimtRequests: newAgentLimitRequests,
      };
    }

    case UPDATE_AGENT_LIMIT_REQUEST_FAILED:
      return {
        ...state,
        isUpdating: false,
      };

    case FETCH_AGENTS_LIMIT_REQUESTS_BY_ID_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        agentsLimtRequests: [...state.agentsLimtRequests, action.payload],
      };
    }

    default:
      return state;
  }
};

export default sectorReducer;
