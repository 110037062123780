import { call, put, takeLatest } from 'redux-saga/effects';
import { getCustomerBalanceService } from 'src/services/agentServices';
import {
  fetchAgentBalanceFailed,
  fetchAgentBalanceFullfilled,
} from '../actions/agentBalance';
import { FETCH_AGENT_BALANCE } from '../constants/agentBalance';

function* fetchAgentBalance(action) {
  try {
    const response = yield call(getCustomerBalanceService, action.payload);
    if (response.ok && response.data?.success) {
      let payload = response.data.response.financial_data;
      payload.status_remarks = response.data.response.status_remarks;
      yield put(fetchAgentBalanceFullfilled(payload));
    } else {
      yield put(fetchAgentBalanceFailed());
    }
  } catch (err) {
    yield put(fetchAgentBalanceFailed());
  }
}

export function* fetchAgentBalanceWatcher() {
  yield takeLatest(FETCH_AGENT_BALANCE, fetchAgentBalance);
}
