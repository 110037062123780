import { FITCity } from '../models';
import { FITTypes } from '../enums/FITTypes';

const FIT_EVENTS_PREFIX = 'fit_';

export const FIT_EVENTS = {
  MainList: FIT_EVENTS_PREFIX + 'list',
  HotelList: FIT_EVENTS_PREFIX + 'hotels_list',
  Search: FIT_EVENTS_PREFIX + 'search',
  SearchFail: FIT_EVENTS_PREFIX + 'search_fail',
  ReviewDialog: FIT_EVENTS_PREFIX + 'review_dialog',
  ReviewDialogCancel: FIT_EVENTS_PREFIX + 'review_dialog_cancel',
  ReviewDialogAddNextItinerary:
    FIT_EVENTS_PREFIX + 'review_dialog_add_next_itinerary',
};

export const FIT_CITIES: { [key: string]: Array<FITCity> } = {
  [FITTypes.UMRAH]: [
    { city_id: '2', city_name: 'Macca' },
    { city_id: '1', city_name: 'Madinah' },
  ],
};
