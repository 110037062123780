import { analytics as firebase_analytics } from 'src/firebase';
import mixpanel from 'mixpanel-browser';
import * as amplitude from '@amplitude/analytics-browser';
import { getFromLocalStorage, stringDecrypt } from 'src/utils/helpers';
import { USER } from '../constants';

type EVENT_DATA = {
  [key: string]: string | number;
};

const shouldLogEvents = process.env.REACT_APP_EVENT_LOGGING_ENABLED as
  | undefined
  | boolean;

export const logEvent = (eventName: string, data: EVENT_DATA = {}) => {
  const user = getFromLocalStorage(USER);
  const parsedUser = JSON.parse(stringDecrypt(user) || '');
  data = {
    ...data,
    customer_fid: parsedUser?.agent?.agent_traacs_code,
    customer_sector: parsedUser?.agent?.sector?.sector_name,
  };

  if (shouldLogEvents) {
    firebase_analytics.logEvent(eventName, data);
    mixpanel.track(eventName, data);
    amplitude.logEvent(eventName, data);
  }
};

export const logPageViewEvent = (
  screen_name: string,
  page_name: string,
  data?: any
) => {
  logEvent(page_name, {
    ...data,
    screen_name,
  });
};

export const logActionEvent = (event_name: string, data?: any) => {
  logEvent(event_name, data);
};

export const PageViewAnalytics = (screenName: string, data?: any) => {
  firebase_analytics.logEvent('screen_view', {
    screen_name: screenName,
    ...data,
  });
};

export const ClickEventAnalytics = (
  screenName: string,
  clickedElement: string,
  data?: any,
  eventName: string = 'item_click'
) => {
  firebase_analytics.logEvent('item_click', {
    screen_name: screenName,
    clicked_element: clickedElement,
    ...data,
  });
};

const Analytics = {
  PageViewAnalytics,
  ClickEventAnalytics,
  logEvent,
  logActionEvent,
  logPageViewEvent,
};

export default Analytics;
