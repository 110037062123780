import { Action } from './../types';
import { getAirportsService } from './../../services/airportsServices';
import {
  fetchAirportsFailed,
  fetchAirportsFullfilled,
} from './../actions/airport';
import { FETCH_AIRPORTS } from './../constants/airport';
import { call, put, takeLatest } from 'redux-saga/effects';

function* fetchAirports(action: Action) {
  try {
    const response = yield call(getAirportsService, action.payload);
    if (response.ok && response.data?.success) {
      yield put(fetchAirportsFullfilled(response.data?.data));
    } else {
      yield put(fetchAirportsFailed());
    }
  } catch (err) {
    yield put(fetchAirportsFailed());
  }
}

export function* fetchAirportsWatcher() {
  yield takeLatest(FETCH_AIRPORTS, fetchAirports);
}
