import { Airport, FlightOption } from '../../types';
import _ from 'lodash';
import moment from 'moment';

export function calculatePriceRangeFilters(
  flightOptions: FlightOption[]
): Array<number> {
  let fareOptions: Array<number> = [];
  flightOptions.forEach(flightOption =>
    flightOption.fare_options.forEach(fareOption =>
      fareOptions.push(parseInt(fareOption.price.gross_fare))
    )
  );
  fareOptions = fareOptions.sort((a, b) => a - b);
  return [fareOptions[0], fareOptions[fareOptions.length - 1]];
}

export function getAirlineFilters(
  flightOptions: FlightOption[]
): Array<{ label: string; value: string }> {
  const airlinesCount: any = {};
  flightOptions.forEach(flightOption => {
    if (airlinesCount[flightOption.airline.code]) {
      airlinesCount[flightOption.airline.code].count++;
    } else {
      airlinesCount[flightOption.airline.code] = {
        name: flightOption.airline.name,
        code: flightOption.airline.code,
        count: 1,
      };
    }
  });
  return Object.values(airlinesCount).map((item: any) => ({
    label: `${item.name} (${item.count})`,
    value: item.code || '',
  }));
}

export function filterFlightsByPrice(
  flightOptions: FlightOption[],
  priceRange: Array<number>
) {
  let tempFilteredData: Array<FlightOption> = [];
  tempFilteredData = flightOptions.filter(item => {
    item.fare_options = item.fare_options.filter(fare_opt => {
      return (
        parseInt(fare_opt.price.gross_fare) >= priceRange[0] &&
        parseInt(fare_opt.price.gross_fare) <= priceRange[1]
      );
    });
    return item.fare_options.length === 0 ? false : true;
  });
  return tempFilteredData;
}

export function filterFlightsByAirlines(
  flightOptions: FlightOption[],
  airlines: Array<string>
) {
  let tempFilteredData: Array<FlightOption> = [];
  tempFilteredData = flightOptions.filter(item =>
    airlines.includes(item.airline.code)
  );
  return tempFilteredData;
}

export function filterFlightsByStops(
  flightOptions: FlightOption[],
  stops: Array<string>
) {
  let tempFilteredData: Array<FlightOption> = [];
  tempFilteredData = flightOptions.filter(item => {
    item.fare_options = item.fare_options.filter(fare_opt => {
      if (stops.includes(fare_opt.segments.length.toString())) {
        return true;
      } else if (stops.indexOf('3') >= 0 && fare_opt.segments.length > 2) {
        return true;
      }
      return false;
    });
    return item.fare_options.length === 0 ? false : true;
  });
  return tempFilteredData;
}

export const mapFlightAirport = (airport: Airport) => ({
  ...airport,
  label: `${airport.city} ${airport.iata_code}`,
});

export const filterFlightsByDepartureTime = (
  flightOptions: FlightOption[],
  departureTime: Array<any>
) => {
  let _departureTime: Array<string> = [];
  departureTime.forEach(a => {
    const splitedTime = a.split('-');
    const temp: any =
      _.range(Number(splitedTime[0]), Number(splitedTime[1]) + 1).map(x =>
        x < 10 ? `0${x}` : `${x}`
      ) || [];
    _departureTime.push(...temp);
  });

  let tempFilteredData: Array<FlightOption> = [];
  tempFilteredData = flightOptions.filter(item => {
    const flightDepartureHour = moment(item.departure_time)
      .parseZone()
      .format('HH');

    return _departureTime.includes(flightDepartureHour);
  });
  return tempFilteredData;
};
