import { call, put, takeLatest } from 'redux-saga/effects';
import { getSectorsService } from 'src/services/sectorServices';
import { fetchSectorsFailed, fetchSectorsFullfilled } from '../actions/sector';
import { FETCH_SECTORS } from '../constants/sector';

function* fetchSectors() {
  try {
    const response = yield call(getSectorsService);
    if (response.ok && response.data?.success) {
      yield put(fetchSectorsFullfilled(response.data.response));
    } else {
      yield put(fetchSectorsFailed());
    }
  } catch (err) {
    yield put(fetchSectorsFailed());
  }
}

export function* fetchSectorsWatcher() {
  yield takeLatest(FETCH_SECTORS, fetchSectors);
}
