import { HTTP_METHODS } from 'src/enums/http-methods';
import apiService from './api';

export const toggleAgentProfileStatusService = (payload: any) => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: `/agentuser/profile/${payload?.id}/${
      payload?.is_active ? 'inactivate' : 'activate'
    }/`,
    data: {},
  });
};

export const getProfileByIdService = (id: string) => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: `/agentuser/organizations/${id}/`,
  });
};

export const updateOrganizationByIdService = (payload: any) => {
  return apiService.execute({
    method: HTTP_METHODS.PATCH,
    url: `/agentuser/organizations/${payload?.id}/`,
    data: payload?.data,
  });
};

export const createAgentEmailService = (payload: any) => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: 'agentuser/profile/create_allowed_email/',
    data: payload?.data,
  });
};

export const createOrgService = (payload: any) => {
  return apiService.execute({
    method: HTTP_METHODS.POST,
    url: 'agentuser/organizations/',
    data: payload?.data,
  });
};

export const getOrganizationsService = () => {
  return apiService.execute({
    method: HTTP_METHODS.GET,
    url: `/agentuser/organizations/`,
  });
};
